import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStore } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  Label,
  Input,
  FormGroup,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import ImageUploader from "react-images-upload";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import classnames from "classnames";
import avatar from "../../assets/img/avatars/user.png";

const dataService = require("../../services/dataServices.js");

const contentOptions = [
  { value: "", label: "All" },
  { value: "Post", label: "Posts" },
  { value: "Support", label: "Supports" },
  { value: "Comment", label: "Comments" },
  { value: "Reaction", label: "Reactions" },
];

const EditExperience = () => {
  let { beat_id } = useParams();
  const navigate = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [permissionTypeID, setPermissionTypeID] = useState(1);
  const [realPermissionTypeID, setRealPermissionTypeID] = useState(1);
  const [badgeID, setBadgeID] = useState(0);
  const [filterID, setFilterID] = useState(0);
  const [ownerName, setOwnerName] = useState("");
  const [ownerID, setOwnerID] = useState(0);
  const [ownerStatusID, setOwnerStatusID] = useState(1);
  const [statusID, setStatusID] = useState(1);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [contentFilter, setContentFilter] = useState(contentOptions[0]);

  const [avatarImage, setAvatarImage] = useState([]);
  const [defaultAvatar, setDefaultAvatar] = useState([]);
  const [removeAvatar, setRemoveAvatar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);

  const tableActions = (cell, row) => {
    function deleteRow() {
      if (statusID === 1) {
        setSelectedRow(row);
        setShowDeleteModal(true);
      }
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={deleteRow}>Remove</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const tableActions2 = (cell, row) => {
    function deleteRow() {
      if (statusID === 1) {
        setSelectedRow(row);
        setShowUnhideModal(true);
      }
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={deleteRow}>Unhide</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const postDisplay = (cell, row) => {
    function showPost(e) {
      e.preventDefault();

      setSelectedRow(row);
      togglePost();
    }

    var txt = row.post.text;
    if (txt.length > 140) {
      txt = txt.substring(0, 140) + " ... ";
    }

    if (row.post.post_id !== "") {
      if (row.post.attachments.length > 0) {
        return (
          <React.Fragment>
            {row.post.attachments[0].type_id === 2 && (
              <video width="160" height="120" controls="controls">
                <source src={row.post.attachments[0].url} type="video/mp4" />
              </video>
            )}
            {row.post.attachments[0].type_id !== 1 &&
              row.post.attachments[0].type_id !== 2 && (
                <div>
                  <a
                    href={row.post.attachments[0].url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.post.attachments[0].url}
                  </a>
                </div>
              )}
            <a href="/" onClick={(e) => showPost(e)}>
              {row.post.attachments[0].type_id === 1 && (
                <img
                  src={row.post.attachments[0].url}
                  style={{ height: 100 }}
                  alt=""
                />
              )}
              <div>{txt}</div>
            </a>
          </React.Fragment>
        );
      } else {
        return (
          <a href="/" onClick={(e) => showPost(e)}>
            {txt}
          </a>
        );
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const commentDisplay = (cell, row) => {
    function showComment(e) {
      e.preventDefault();

      setSelectedRow(row);
      toggleComment();
    }

    if (row.comment.comment_id !== "") {
      var txt = row.comment.text;
      if (txt.length > 140) {
        txt = txt.substring(0, 140) + " ... ";
      }

      return (
        <a href="/" onClick={(e) => showComment(e)}>
          {txt}
        </a>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const actionDisplay = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteContentModal(true);
    }

    return (
      <Button color="warning" size="sm" onClick={deleteRow}>
        Delete
      </Button>
    );
  };

  const authorDisplay = (cell, row) => {
    var userAvatar =
      row.creator_avatar_url === "" ? null : row.creator_avatar_url;
    var img = userAvatar ?? avatar;
    var nm =
      row.creator_display_name === ""
        ? row.creator_name
        : row.creator_display_name;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1"
          style={{ width: 20, height: 20 }}
          alt={nm}
        />
        <Link to={"/members/edit/" + row.creator_id}>{nm}</Link>
      </React.Fragment>
    );
  };

  const contentTableColumns = [
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "creator_name",
      text: "User",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "creator_follower_type",
      text: "User Type",
      sort: true,
    },
    {
      dataField: "created_date",
      text: "Date",
      sort: true,
      formatter: dateDisplay,
    },
    {
      isDummyField: true,
      dataField: "post_id",
      text: "Post",
      sort: true,
      formatter: postDisplay,
    },
    {
      isDummyField: true,
      dataField: "comment_id",
      text: "Comment",
      sort: true,
      formatter: commentDisplay,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  const postTableColumns = [
    {
      dataField: "text",
      text: "Text",
      sort: true,
      formatter: postDisplay,
      editable: false,
    },
    {
      dataField: "creator_name",
      text: "User",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "creator_follower_type",
      text: "User Type",
      sort: true,
    },
    {
      dataField: "created_date",
      text: "Created",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const supportTableColumns = [
    {
      dataField: "creator_name",
      text: "User",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "creator_follower_type",
      text: "User Type",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "post_id",
      text: "Post",
      sort: true,
      formatter: postDisplay,
    },
    {
      dataField: "created_date",
      text: "Created",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const commentTableColumns = [
    {
      dataField: "text",
      text: "Text",
      sort: true,
      formatter: commentDisplay,
      editable: false,
    },
    {
      dataField: "creator_name",
      text: "User",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "creator_follower_type",
      text: "User Type",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "post_id",
      text: "Post",
      sort: true,
      formatter: postDisplay,
    },
    {
      dataField: "created_date",
      text: "Created",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const reactionTableColumns = [
    {
      dataField: "text",
      text: "Agree / Disagree",
      sort: true,
      editable: false,
    },
    {
      dataField: "creator_name",
      text: "User",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "creator_follower_type",
      text: "User Type",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "comment_id",
      text: "Comment",
      sort: true,
      formatter: commentDisplay,
    },
    {
      isDummyField: true,
      dataField: "post_id",
      text: "Post",
      sort: true,
      formatter: postDisplay,
    },
    {
      dataField: "created_date",
      text: "Created",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const followerTableColumns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "display_name",
      text: "Display Name",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions,
      headerStyle: () => {
        return { width: "70px" };
      },
      editable: false,
    },
  ];

  const hiddenTableColumns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "display_name",
      text: "Display Name",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions2,
      headerStyle: () => {
        return { width: "70px" };
      },
      editable: false,
    },
  ];

  const [contentCurrentPage, setContentCurrentPage] = useState(1);
  const [contentCurrentData, setContentCurrentData] = useState([]);
  const [showContentLoading, setShowContentLoading] = useState(true);
  const [contentTotalRecords, setContentTotalRecords] = useState(0);
  const [contentSortField, setContentSortField] = useState("created_date");
  const [contentSortOrder, setContentSortOrder] = useState("desc");
  const [contentSizePerPage, setContentSizePerPage] = useState(10);
  const [contentColumns, setContentColumns] = useState(contentTableColumns);

  const [followerCurrentPage, setFollowerCurrentPage] = useState(1);
  const [followerCurrentData, setFollowerCurrentData] = useState([]);
  const [showFollowerLoading, setShowFollowerLoading] = useState(true);
  const [followerTotalRecords, setFollowerTotalRecords] = useState(0);
  const [followerSortField, setFollowerSortField] = useState("name");
  const [followerSortOrder, setFollowerSortOrder] = useState("asc");
  const [followerSizePerPage, setFollowerSizePerPage] = useState(10);
  const [followerSearchFilter, setFollowerSearchFilter] = useState("");

  const [hiddenCurrentPage, setHiddenCurrentPage] = useState(1);
  const [hiddenCurrentData, setHiddenCurrentData] = useState([]);
  const [showHiddenLoading, setShowHiddenLoading] = useState(true);
  const [hiddenTotalRecords, setHiddenTotalRecords] = useState(0);
  const [hiddenSortField, setHiddenSortField] = useState("name");
  const [hiddenSortOrder, setHiddenSortOrder] = useState("asc");
  const [hiddenSizePerPage, setHiddenSizePerPage] = useState(10);

  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);
  const [showUnhideModal, setShowUnhideModal] = useState(false);

  const [showPostModal, setShowPostModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  function togglePost() {
    setShowPostModal(!showPostModal);
  }

  function toggleComment() {
    setShowCommentModal(!showCommentModal);
  }

  async function loadFollowerData(filter, page, pageSize, sort, order) {
    setShowFollowerLoading(true);
    setFollowerCurrentData([]);

    var info = await dataService.experienceFollowerList(
      beat_id,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setFollowerTotalRecords(info.record_count);
    setShowFollowerLoading(false);
    setFollowerCurrentData(info.list);
  }

  async function loadHiddenData(page, pageSize, sort, order) {
    setShowHiddenLoading(true);
    setHiddenCurrentData([]);

    var info = await dataService.experienceHiddenList(
      beat_id,
      page,
      pageSize,
      sort,
      order
    );

    setHiddenTotalRecords(info.record_count);
    setShowHiddenLoading(false);
    setHiddenCurrentData(info.list);
  }

  async function loadContentData(filter, page, pageSize, sort, order) {
    setShowContentLoading(true);
    setContentCurrentData([]);

    var info = await dataService.experienceContentList(
      beat_id,
      filter,
      page,
      pageSize,
      sort,
      order
    );
    setContentTotalRecords(info.record_count);
    setShowContentLoading(false);
    setContentCurrentData(info.list);
  }

  function performKey(e) {
    if (e.charCode === 13) {
      loadFollowerData(
        followerSearchFilter,
        followerCurrentPage,
        followerSizePerPage,
        followerSortField,
        followerSortOrder
      );
    }
  }

  function performSearch() {
    loadFollowerData(
      followerSearchFilter,
      followerCurrentPage,
      followerSizePerPage,
      followerSortField,
      followerSortOrder
    );
  }

  function followerPageChanged(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== followerSizePerPage) {
        // normally, we will need to make a call out
        setFollowerCurrentPage(1);
        setFollowerSizePerPage(newState.sizePerPage);
        loadFollowerData(
          followerSearchFilter,
          1,
          newState.sizePerPage,
          followerSortField,
          followerSortOrder
        );
      } else if (newState.page !== followerCurrentPage) {
        // normally, we will need to make a call out to get the passed page of data
        setFollowerCurrentPage(newState.page);
        loadFollowerData(
          followerSearchFilter,
          newState.page,
          followerSizePerPage,
          followerSortField,
          followerSortOrder
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== followerSortField) {
        setFollowerSortField(newState.sortField);
      } else if (newState.sortOrder !== followerSortOrder) {
        setFollowerSortOrder(newState.sortOrder);
      }
      setFollowerCurrentPage(1);

      loadFollowerData(
        followerSearchFilter,
        1,
        followerSizePerPage,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  function hiddenPageChanged(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== hiddenSizePerPage) {
        // normally, we will need to make a call out
        setHiddenCurrentPage(1);
        setHiddenSizePerPage(newState.sizePerPage);
        loadHiddenData(
          1,
          newState.sizePerPage,
          hiddenSortField,
          hiddenSortOrder
        );
      } else if (newState.page !== hiddenCurrentPage) {
        // normally, we will need to make a call out to get the passed page of data
        setHiddenCurrentPage(newState.page);
        loadHiddenData(
          newState.page,
          hiddenSizePerPage,
          hiddenSortField,
          hiddenSortOrder
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== hiddenSortField) {
        setHiddenSortField(newState.sortField);
      } else if (newState.sortOrder !== hiddenSortOrder) {
        setHiddenSortOrder(newState.sortOrder);
      }

      setHiddenCurrentPage(1);
      loadHiddenData(
        1,
        hiddenSizePerPage,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  function contentPageChanged(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== contentSizePerPage) {
        // normally, we will need to make a call out
        setContentCurrentPage(1);
        setContentSizePerPage(newState.sizePerPage);
        loadContentData(
          contentFilter.value,
          1,
          newState.sizePerPage,
          contentSortField,
          contentSortOrder
        );
      } else if (newState.page !== contentCurrentPage) {
        // normally, we will need to make a call out to get the passed page of data
        setContentCurrentPage(newState.page);
        loadContentData(
          contentFilter.value,
          newState.page,
          contentSizePerPage,
          contentSortField,
          contentSortOrder
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== contentSortField) {
        setContentSortField(newState.sortField);
      } else if (newState.sortOrder !== contentSortOrder) {
        setContentSortOrder(newState.sortOrder);
      }

      setContentCurrentPage(1);
      loadContentData(
        contentFilter.value,
        1,
        contentSizePerPage,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  async function constructObject() {
    var aString = avatarImage.length > 0 ? await toBase64(avatarImage[0]) : "";

    var obj = {
      beat_id: beat_id,
      name: name,
      description: description,
      permission_type_id: realPermissionTypeID,
      badge_id: badgeID,
      filter_id: filterID,
      banner: aString,
      remove_banner_flag: removeAvatar ? 1 : 0,
      categories: [],
      notes: notes,
    };

    selectedCategoryList.forEach((category) => {
      obj.categories.push({
        beat_id: beat_id,
        category_id: category.category_id,
      });
    });

    return obj;
  }

  async function save() {
    setIsLoading(true);
    setIsDisabled(true);

    var obj = await constructObject();
    await dataService.updateExperience(obj);

    showToastr();
  }

  function performCancel() {
    // navigate.push("/content/experiences");
    navigate.goBack();
  }

  useEffect(() => {
    async function loadData() {
      // load the role
      var data = await dataService.experience(beat_id);

      // load the category list
      var info = await dataService.categoryList(1, "", 1, 1000, "name", "asc");
      if (info && info.list) {
        setCategoryList(info.list);
      } else {
        setCategoryList([]);
      }

      // grab the list of selected categories
      if (data.categories) {
        data.categories.forEach((category) => {
          var match = info.list.find((row) => {
            return row.category_id === category.category_id;
          });

          if (match) {
            setSelectedCategoryList((selectedCategoryList) =>
              selectedCategoryList.concat(match)
            );
          }
        });
      }

      setName(data.name);
      setDescription(data.description);
      setNotes(data.notes);

      if (data.permission_type_id === 1) {
        if (data.anonymous_avatar_id === 0) {
          setPermissionTypeID("" + data.permission_type_id);
        } else {
          setPermissionTypeID("3");
        }
      } else {
        setPermissionTypeID("" + data.permission_type_id);
      }
      setRealPermissionTypeID("" + data.permission_type_id);
      setBadgeID("" + data.badge_id);
      setFilterID("" + data.filter_id);
      setStatusID(data.status_id);
      setOwnerName(data.creator_display_name);
      setOwnerID(data.creator_id);
      setOwnerStatusID(data.creator_status_id);
      if (data.banner_url !== "" && data.banner_url !== null) {
        setDefaultAvatar([data.banner_url]);
      }

      // load the follower list
      // loadFollowerData(followerSearchFilter, followerCurrentPage, followerSizePerPage, followerSortField, followerSortOrder);

      setIsLoading(false);
      setIsDisabled(!hasWritePermission());
    }

    function hasWritePermission() {
      var result = false;

      if (currentUser.permissions) {
        const matchIndex = currentUser.permissions.findIndex(function (row) {
          return row === "experiences_write";
        });
        if (matchIndex > -1) {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    }

    loadData();
  }, [beat_id, currentUser.permissions]);

  function toggleSelectedCategory(e, categoryID) {
    if (e.target.checked === true) {
      var match = categoryList.find((row) => {
        return row.category_id === categoryID;
      });
      if (match) {
        setSelectedCategoryList((selectedCategoryList) =>
          selectedCategoryList.concat(match)
        );
      }
    } else {
      const matchIndex = selectedCategoryList.findIndex(function (category) {
        return category.category_id === categoryID;
      });
      if (matchIndex > -1) {
        var list = [].concat(selectedCategoryList);
        list.splice(matchIndex, 1);
        setSelectedCategoryList(list);
      }
    }
  }

  async function onDrop(picture) {
    setRemoveAvatar(picture.length === 0);
    setAvatarImage(picture);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        setIsLoading(false);
        setIsDisabled(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Edit Experience",
      "Your changes have been saved successfully.",
      options
    );
  }

  function togglePermission(e) {
    e.persist();
    setPermissionTypeID(e.target.value);
  }

  function toggleBadge(e) {
    e.persist();
    setBadgeID(e.target.value);
  }

  function toggleFilter(e) {
    e.persist();
    setFilterID(e.target.value);
  }

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    await dataService.removeFollower(beat_id, selectedRow.user_id);
    setShowDeleteModal(false);
    loadFollowerData(
      followerSearchFilter,
      followerCurrentPage,
      followerSizePerPage,
      followerSortField,
      followerSortOrder
    );
  }

  async function performDeleteContent() {
    setShowDeleteContentModal(false);
    setShowContentLoading(true);
    setContentCurrentData([]);

    // ok, delete the row
    // console.log(selectedRow.item_id + " : " + selectedRow.type);
    await dataService.experienceDeleteContent(
      beat_id,
      selectedRow.item_id,
      selectedRow.creator_id,
      selectedRow.type
    );

    // refresh the table
    setContentCurrentPage(1);
    await loadContentData(
      contentFilter.value,
      1,
      contentSizePerPage,
      contentSortField,
      contentSortOrder
    );
  }

  async function performUnhide() {
    await dataService.experienceUnhide(beat_id, selectedRow.user_id);
    setShowUnhideModal(false);
    loadHiddenData(
      hiddenCurrentPage,
      hiddenSizePerPage,
      hiddenSortField,
      hiddenSortOrder
    );
  }

  function toggle(tab) {
    setActiveTab(tab);
  }

  function toggleDeleteContent() {
    setShowDeleteContentModal(!showDeleteContentModal);
  }

  function toggleUnhide() {
    setShowUnhideModal(!showUnhideModal);
  }

  function updateContentFilter(e) {
    setContentFilter(e);
    setContentCurrentPage(1);

    if (e.value === "Post") {
      setContentColumns(postTableColumns);
    } else if (e.value === "Support") {
      setContentColumns(supportTableColumns);
    } else if (e.value === "Comment") {
      setContentColumns(commentTableColumns);
    } else if (e.value === "Reaction") {
      setContentColumns(reactionTableColumns);
    } else {
      setContentColumns(contentTableColumns);
    }

    loadContentData(
      e.value,
      1,
      contentSizePerPage,
      contentSortField,
      contentSortOrder
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Edit Experience
          <Button color="light" className="float-right" onClick={performCancel}>
            Cancel
          </Button>
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/content/experiences">Experiences</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Experience</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Experience Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Activity ({contentTotalRecords})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Followers ({followerTotalRecords})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Members Hiding ({hiddenTotalRecords})
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <AvForm onValidSubmit={save}>
                    <Row>
                      <Col>
                        <ImageUploader
                          withIcon={false}
                          withLabel={false}
                          buttonText="Choose banner image"
                          onChange={onDrop}
                          defaultImages={defaultAvatar}
                          imgExtension={[".jpeg", ".jpg", ".png"]}
                          maxFileSize={5242880}
                          singleImage={true}
                          withPreview={true}
                          buttonClassName="btn btn-primary"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <AvGroup>
                          <Label for="name">Title</Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter a name"
                            value={name}
                            disabled={isDisabled}
                            onChange={(e) => setName(e.target.value)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a title.",
                              },
                            }}
                          />
                        </AvGroup>
                        <Table size="sm">
                          <tbody>
                            <tr>
                              <th width="5%">Owner</th>
                              <td>
                                <Link to={"/members/edit/" + ownerID}>
                                  {ownerName}
                                </Link>
                                {ownerStatusID === 2 && (
                                  <span className="text-warning ml-1">
                                    Disabled
                                  </span>
                                )}
                                {ownerStatusID === 3 && (
                                  <span className="text-danger ml-1">
                                    Blocked
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col sm={4}>
                        <Label for="description">Description</Label>
                        <Input
                          type="textarea"
                          name="description"
                          placeholder="Description"
                          value={description}
                          disabled={isDisabled}
                          onChange={(e) => setDescription(e.target.value)}
                          rows={4}
                        />
                      </Col>
                      <Col sm={4}>
                        <Label for="permission_type_id">Privacy</Label>
                        <AvRadioGroup
                          name="permission_type_id"
                          errorMessage="Pick one!"
                          value={permissionTypeID}
                          onChange={(e) => togglePermission(e)}
                          disabled={true}
                        >
                          <AvRadio customInput label="Public" value="1" />
                          <AvRadio customInput label="Anonymous" value="3" />
                          <AvRadio customInput label="Private" value="2" />
                        </AvRadioGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Label for="badge_id">Badge</Label>
                        <AvRadioGroup
                          name="badge_id"
                          errorMessage="Pick one!"
                          value={badgeID}
                          onChange={(e) => toggleBadge(e)}
                          disabled={isLoading}
                        >
                          <AvRadio customInput label="None" value="0" />
                          <AvRadio customInput label="Featured" value="1" />
                          <AvRadio customInput label="Marketing" value="2" />
                          <AvRadio
                            customInput
                            label="New Recommended"
                            value="3"
                          />
                          <AvRadio customInput label="Recommended" value="4" />
                          <AvRadio customInput label="Trending" value="5" />
                        </AvRadioGroup>
                      </Col>
                      <Col sm={4}>
                        <Label for="filter_id">Filter</Label>
                        <AvRadioGroup
                          name="filter_id"
                          errorMessage="Pick one!"
                          value={filterID}
                          onChange={(e) => toggleFilter(e)}
                          disabled={isLoading}
                        >
                          <AvRadio customInput label="None" value="0" />
                          <AvRadio customInput label="Available" value="2" />
                          <AvRadio
                            customInput
                            label="Hide from Home"
                            value="15"
                          />
                          <AvRadio
                            customInput
                            label="Hide from See All Experiences"
                            value="50"
                          />
                          <AvRadio
                            customInput
                            label="Hide from Everywhere"
                            value="100"
                          />
                        </AvRadioGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>Categories</h4>
                        <AvCheckboxGroup
                          name={"category"}
                          className="row ml-1 mr-1"
                        >
                          {categoryList.map(({ category_id, name }, index) => (
                            <CustomInput
                              type="checkbox"
                              key={"categoryFilter" + category_id}
                              id={"categoryFilter" + category_id}
                              label={name}
                              className="col-md-3 col-sm-4 col-xs-12 float-left"
                              checked={selectedCategoryList.some(
                                (row) => row.category_id === category_id
                              )}
                              disabled={isLoading}
                              onChange={(e) =>
                                toggleSelectedCategory(e, category_id)
                              }
                            />
                          ))}
                        </AvCheckboxGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>Notes</h4>
                        <Input
                          type="textarea"
                          name="notes"
                          placeholder="Notes"
                          value={notes}
                          disabled={isDisabled}
                          onChange={(e) => setNotes(e.target.value)}
                          rows={10}
                        />
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <Button
                        color="light"
                        size="lg"
                        className="mr-2"
                        onClick={performCancel}
                      >
                        Cancel
                      </Button>
                      {statusID === 1 && (
                        <Button color="primary" size="lg" disabled={isDisabled}>
                          {isLoading && (
                            <Spinner size="sm" className="mr-2 align-middle" />
                          )}
                          Save
                        </Button>
                      )}
                    </div>
                  </AvForm>
                </TabPane>
                <TabPane tabId="2">
                  <div className="mb-3">
                    <AvForm>
                      <Row>
                        <Col>
                          <h4>Content Type</h4>
                          <FormGroup>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={contentOptions}
                              value={contentFilter}
                              onChange={(e) => updateContentFilter(e)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                  <BootstrapTable
                    keyField="content_id"
                    data={contentCurrentData}
                    columns={contentColumns}
                    sort={{
                      dataField: contentSortField,
                      order: contentSortOrder,
                    }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper"
                    classes="table_class"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: contentSizePerPage,
                      sizePerPageList: [5, 10, 25, 50],
                      page: contentCurrentPage,
                      totalSize: contentTotalRecords,
                    })}
                    onTableChange={contentPageChanged}
                    noDataIndication={() =>
                      showContentLoading ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
                <TabPane tabId="3">
                  <div className="mb-3">
                    <InputGroup>
                      <Input
                        placeholder="Search..."
                        value={followerSearchFilter}
                        onChange={(e) =>
                          setFollowerSearchFilter(e.target.value)
                        }
                        onKeyPress={(e) => performKey(e)}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                      <InputGroupAddon addonType="append" color="primary">
                        <Button onClick={performSearch}>
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    keyField="beat_id"
                    data={followerCurrentData}
                    columns={followerTableColumns}
                    sort={{
                      dataField: followerSortField,
                      order: followerSortOrder,
                    }}
                    bootstrap4
                    striped
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: followerSizePerPage,
                      sizePerPageList: [5, 10, 25, 50],
                      page: followerCurrentPage,
                      totalSize: followerTotalRecords,
                    })}
                    onTableChange={followerPageChanged}
                    noDataIndication={() =>
                      showFollowerLoading ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
                <TabPane tabId="4">
                  <BootstrapTable
                    keyField="user_id"
                    data={hiddenCurrentData}
                    columns={hiddenTableColumns}
                    sort={{
                      dataField: hiddenSortField,
                      order: hiddenSortOrder,
                    }}
                    bootstrap4
                    striped
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: hiddenSizePerPage,
                      sizePerPageList: [5, 10, 25, 50],
                      page: hiddenCurrentPage,
                      totalSize: hiddenTotalRecords,
                    })}
                    onTableChange={hiddenPageChanged}
                    noDataIndication={() =>
                      showHiddenLoading ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
          <Modal
            key={"delete"}
            isOpen={showDeleteModal}
            toggle={() => toggleDelete()}
          >
            <ModalHeader toggle={() => toggleDelete()}>
              Remove Follower{selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to remove this follower?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleDelete()}>
                Cancel
              </Button>{" "}
              <Button color="danger" onClick={() => performDelete()}>
                Remove Follower
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            key={"deleteContent"}
            isOpen={showDeleteContentModal}
            toggle={() => toggleDeleteContent()}
          >
            <ModalHeader toggle={() => toggleDeleteContent()}>
              Delete Content{selectedRow ? " - " + selectedRow.type : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to delete this content?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleDeleteContent()}>
                Cancel
              </Button>{" "}
              <Button color="danger" onClick={() => performDeleteContent()}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            key={"unhide"}
            isOpen={showUnhideModal}
            toggle={() => toggleUnhide()}
          >
            <ModalHeader toggle={() => toggleUnhide()}>
              Unhide{selectedRow ? " from " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to unhide this experience from the chosen
                user?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleUnhide()}>
                Cancel
              </Button>{" "}
              <Button color="warning" onClick={() => performUnhide()}>
                Unhide
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            key={"post"}
            size="lg"
            isOpen={showPostModal}
            toggle={() => togglePost()}
          >
            <ModalHeader toggle={() => togglePost()}>Post</ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                {selectedRow !== null && selectedRow.post !== null && (
                  <AvForm>
                    <Row>
                      <Col sm={6}>
                        <AvGroup>
                          <Label for="created_date" className="float-left">
                            Date Posted
                          </Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="created_date"
                            id="created_date"
                            value={new Date(
                              selectedRow.created_date
                            ).toLocaleString()}
                            disabled={true}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Label for="text" className="float-left">
                          Text
                        </Label>
                        <Input
                          type="textarea"
                          name="text"
                          value={selectedRow.post.text}
                          disabled={true}
                          rows="4"
                        />
                      </Col>
                    </Row>
                  </AvForm>
                )}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => togglePost()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            key={"comment"}
            size="lg"
            isOpen={showCommentModal}
            toggle={() => toggleComment()}
          >
            <ModalHeader toggle={() => toggleComment()}>Comment</ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                {selectedRow !== null && selectedRow.comment !== null && (
                  <AvForm>
                    <Row>
                      <Col sm={6}>
                        <AvGroup>
                          <Label for="created_date" className="float-left">
                            Date Posted
                          </Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="created_date"
                            id="created_date"
                            value={new Date(
                              selectedRow.created_date
                            ).toLocaleString()}
                            disabled={true}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Label for="text" className="float-left">
                          Text
                        </Label>
                        <Input
                          type="textarea"
                          name="text"
                          value={selectedRow.comment.text}
                          disabled={true}
                          rows="4"
                        />
                      </Col>
                    </Row>
                  </AvForm>
                )}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => toggleComment()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default EditExperience;
