import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
  Input,
  InputGroupAddon,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import classnames from "classnames";

const dataService = require("../../services/dataServices.js");

const CategoryList = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentData1, setCurrentData1] = useState([]);
  const [showLoading1, setShowLoading1] = useState(true);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [sortField1, setSortField1] = useState("sort_by");
  const [sortOrder1, setSortOrder1] = useState("asc");
  const [sizePerPage1, setSizePerPage1] = useState(10);

  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentData2, setCurrentData2] = useState([]);
  const [showLoading2, setShowLoading2] = useState(true);
  const [totalRecords2, setTotalRecords2] = useState(0);
  const [sortField2, setSortField2] = useState("sort_by");
  const [sortOrder2, setSortOrder2] = useState("asc");
  const [sizePerPage2, setSizePerPage2] = useState(10);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [searchFilter1, setSearchFilter1] = useState("");
  const [searchFilter2, setSearchFilter2] = useState("");

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);
  const navigate = useHistory();

  function hasWritePermission() {
    var result = false;

    if (currentUser.permissions) {
      const matchIndex = currentUser.permissions.findIndex(function (row) {
        return row === "categories_write";
      });
      if (matchIndex > -1) {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  function toggle(tab) {
    setActiveTab(tab);
  }

  function performAdd() {
    navigate.push("/system-content/categories/add");
  }

  const tableActions1 = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    function editRow() {
      performEdit(row.category_id);
    }

    if (hasWritePermission()) {
      return (
        <UncontrolledButtonDropdown
          key={row.user_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={editRow}>Edit</DropdownItem>
            <DropdownItem onClick={deleteRow}>Deactivate</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    } else {
      return (
        <UncontrolledButtonDropdown
          key={row.user_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={editRow}>View</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    }
  };

  const tableActions2 = (cell, row) => {
    function activateRow() {
      setSelectedRow(row);
      setShowActivateModal(true);
    }

    if (hasWritePermission()) {
      return (
        <UncontrolledButtonDropdown
          key={row.user_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={activateRow}>Re-activate</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    } else {
      return <React.Fragment />;
    }
  };

  const tableColumns1 = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "sort_by",
      text: "Display Sort",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions1,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  const tableColumns2 = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "sort_by",
      text: "Display Sort",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions2,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  async function loadData1(filter, page, pageSize, sort, order) {
    setShowLoading1(true);
    setCurrentData1([]);

    var info = await dataService.categoryList(
      1,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setTotalRecords1(info.record_count);
    setShowLoading1(false);
    setCurrentData1(info.list);
  }

  async function loadData2(filter, page, pageSize, sort, order) {
    setShowLoading2(true);
    setCurrentData2([]);

    var info = await dataService.categoryList(
      0,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setTotalRecords2(info.record_count);
    setShowLoading2(false);
    setCurrentData2(info.list);
  }

  function performKey1(e) {
    if (e.charCode === 13) {
      loadData1(
        searchFilter1,
        currentPage1,
        sizePerPage1,
        sortField1,
        sortOrder1
      );
    }
  }

  function performKey2(e) {
    if (e.charCode === 13) {
      loadData2(
        searchFilter2,
        currentPage2,
        sizePerPage2,
        sortField2,
        sortOrder2
      );
    }
  }

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    await dataService.deleteCategory(selectedRow.category_id);
    setShowDeleteModal(false);
    refreshGrids();
  }

  function toggleActivate() {
    setShowActivateModal(!showActivateModal);
  }

  async function performActivate() {
    await dataService.activateCategory(selectedRow.category_id);
    setShowActivateModal(false);
    refreshGrids();
  }

  function performEdit(category_id) {
    navigate.push("/system-content/categories/edit/" + category_id);
  }

  function performSearch1() {
    loadData1(
      searchFilter1,
      currentPage1,
      sizePerPage1,
      sortField1,
      sortOrder1
    );
  }

  function performSearch2() {
    loadData2(
      searchFilter2,
      currentPage2,
      sizePerPage2,
      sortField2,
      sortOrder2
    );
  }

  function pageChanged1(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage1) {
        // normally, we will need to make a call out
        setCurrentPage1(1);
        setSizePerPage1(newState.sizePerPage);
        loadData1(
          searchFilter1,
          1,
          newState.sizePerPage,
          sortField1,
          sortOrder1
        );
      } else if (newState.page !== currentPage1) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage1(newState.page);
        loadData1(
          searchFilter1,
          newState.page,
          sizePerPage1,
          sortField1,
          sortOrder1
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField1) {
        setSortField1(newState.sortField);
      } else if (newState.sortOrder !== sortOrder1) {
        setSortOrder1(newState.sortOrder);
      }
      setCurrentPage1(1);

      loadData1(
        searchFilter1,
        1,
        sizePerPage1,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  function pageChanged2(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage2) {
        // normally, we will need to make a call out
        setCurrentPage2(1);
        setSizePerPage2(newState.sizePerPage);
        loadData2(
          searchFilter2,
          1,
          newState.sizePerPage,
          sortField2,
          sortOrder2
        );
      } else if (newState.page !== currentPage2) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage2(newState.page);
        loadData2(
          searchFilter2,
          newState.page,
          sizePerPage2,
          sortField2,
          sortOrder2
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField2) {
        setSortField2(newState.sortField);
      } else if (newState.sortOrder !== sortOrder2) {
        setSortOrder2(newState.sortOrder);
      }
      setCurrentPage2(1);

      loadData2(
        searchFilter2,
        1,
        sizePerPage2,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  function refreshGrids() {
    setCurrentPage1(1);
    setCurrentPage2(1);
    loadData1(
      searchFilter1,
      currentPage1,
      sizePerPage1,
      sortField1,
      sortOrder1
    );
    loadData2(
      searchFilter2,
      currentPage2,
      sizePerPage2,
      sortField2,
      sortOrder2
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Categories
          {hasWritePermission() && (
            <Button
              color="success"
              className="float-right"
              onClick={performAdd}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New
            </Button>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Categories</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Active Categories
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    In-active Categories
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="mb-3">
                    <InputGroup>
                      <Input
                        placeholder="Search..."
                        value={searchFilter1}
                        onChange={(e) => setSearchFilter1(e.target.value)}
                        onKeyPress={(e) => performKey1(e)}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                      <InputGroupAddon addonType="append" color="primary">
                        <Button onClick={performSearch1}>
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    keyField="category_id"
                    data={currentData1}
                    columns={tableColumns1}
                    sort={{ dataField: sortField1, order: sortOrder1 }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper"
                    classes="table_class"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: sizePerPage1,
                      sizePerPageList: [5, 10, 25, 50],
                      page: currentPage1,
                      totalSize: totalRecords1,
                    })}
                    onTableChange={pageChanged1}
                    noDataIndication={() =>
                      showLoading1 ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
                <TabPane tabId="2">
                  <div className="mb-3">
                    <InputGroup>
                      <Input
                        placeholder="Search..."
                        value={searchFilter2}
                        onChange={(e) => setSearchFilter2(e.target.value)}
                        onKeyPress={(e) => performKey2(e)}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                      <InputGroupAddon addonType="append" color="primary">
                        <Button onClick={performSearch2}>
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    keyField="category_id"
                    data={currentData2}
                    columns={tableColumns2}
                    sort={{ dataField: sortField2, order: sortOrder2 }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper"
                    classes="table_class"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: sizePerPage2,
                      sizePerPageList: [5, 10, 25, 50],
                      page: currentPage2,
                      totalSize: totalRecords2,
                    })}
                    onTableChange={pageChanged2}
                    noDataIndication={() =>
                      showLoading2 ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
          <Modal
            key={"delete"}
            isOpen={showDeleteModal}
            toggle={() => toggleDelete()}
          >
            <ModalHeader toggle={() => toggleDelete()}>
              Deactivate Category{selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to deactivate this category?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleDelete()}>
                Cancel
              </Button>{" "}
              <Button color="danger" onClick={() => performDelete()}>
                Deactivate
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            key={"activate"}
            isOpen={showActivateModal}
            toggle={() => toggleActivate()}
          >
            <ModalHeader toggle={() => toggleActivate()}>
              Activate Category{selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to activate this category?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleActivate()}>
                Cancel
              </Button>{" "}
              <Button color="success" onClick={() => performActivate()}>
                Activate
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default CategoryList;
