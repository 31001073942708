import async from "../components/Async";

import {
  //faBook,
  //faChartPie,
  //faCheckSquare,
  //faFile,
  //faFlask,
  //faHeart,
  faHome,
  //faMapMarkerAlt,
  //faTable,
  faSignInAlt,
  faUsers,
  faLayerGroup,
  faComments,
  faUserShield,
  faSyncAlt,
  //faTag,
  //faMoneyBill,
  faShieldAlt,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
//import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import ChangePassword from "../pages/auth/ChangePassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Members
import MemberList from "../pages/members/MemberList";
import AddMember from "../pages/members/AddMember";
import EditMember from "../pages/members/EditMember";

// Reports
import CustomerSpendReport from "../pages/reports/customerSpendReport";
import MonthlySessionsHeld from "../pages/reports/monthlySessionsHeld";
import MonthlyFreeTrialSessionsHeld from "../pages/reports/monthlyFreeTrialSessionsHeld";
import MonthlyNewCustomer from "../pages/reports/monthlyNewCustomer";
import MonthlyReturningCustomers from "../pages/reports/monthlyReturningCustomers";
import MonthlyCustomerSpend from "../pages/reports/monthlyCustomerSpend";
import MonthlyNewUser from "../pages/reports/monthlyNewUser";

// Experiences
import ExperienceList from "../pages/content/ExperienceList";
import AddExperience from "../pages/content/AddExperience";
import EditExperience from "../pages/content/EditExperience";

// Reporting
import ReportingList from "../pages/content/ReportingList";

// Algorithm
import Algorithm from "../pages/system-content/Algorithm";
//import MessageImport from "../pages/system-content/MessageImport";

// Users
import UserList from "../pages/admin/UserList";
import AddUser from "../pages/admin/AddUser";
import EditUser from "../pages/admin/EditUser";

// Roles
import RoleList from "../pages/admin/RoleList";
import AddRole from "../pages/admin/AddRole";
import EditRole from "../pages/admin/EditRole";

// Force Update
import ForceUpdate from "../pages/force-update/ForceUpdate";
// Promos
/*import PromoList from "../pages/marketing/PromoList";
import AddPromo from "../pages/marketing/AddPromo";
import EditPromo from "../pages/marketing/EditPromo";*/
// Partnerships
import PartnershipList from "../pages/system-content/PartnershipList";
import AddPartnership from "../pages/system-content/AddPartnership";
import EditPartnership from "../pages/system-content/EditPartnership";
import AddLabel from "../pages/system-content/AddLabel";
import EditLabel from "../pages/system-content/EditLabel";

// Categories
import CategoryList from "../pages/system-content/CategoryList";
import AddCategory from "../pages/system-content/AddCategory";
import EditCategory from "../pages/system-content/EditCategory";

// Banned Words
import WordList from "../pages/system-content/WordList";
import AddWord from "../pages/system-content/AddWord";
import EditWord from "../pages/system-content/EditWord";

// Quotes
import QuoteList from "../pages/system-content/QuoteList";
import AddQuote from "../pages/system-content/AddQuote";
import EditQuote from "../pages/system-content/EditQuote";

// Help
import HelpList from "../pages/system-content/HelpList";
import AddHelpCategory from "../pages/system-content/AddHelpCategory";
import EditHelpCategory from "../pages/system-content/EditHelpCategory";
import AddHelpTopic from "../pages/system-content/AddHelpTopic";
import EditHelpTopic from "../pages/system-content/EditHelpTopic";

// Analyitcs
import CommentingTeamStats from "../pages/analytics/CommentingTeamStats";
import AddTeamMember from "../pages/analytics/AddTeamMember";
import EditTeamMember from "../pages/analytics/EditTeamMember";
import RecentUserStats from "../pages/analytics/RecentUserStats";
import MonthlyUserStats from "../pages/analytics/MonthlyUserStats";
import SessionStats from "../pages/analytics/SessionStats";
import DownloadStats from "../pages/analytics/DownloadStats";
import SignupStats from "../pages/analytics/SignupStats";
import RevenueStats from "../pages/analytics/RevenueStats";
import RetentionStats from "../pages/analytics/RetentionStats";

// UI Elements
//import Alerts from "../pages/ui-elements/Alerts";
//import Buttons from "../pages/ui-elements/Buttons";
//import Cards from "../pages/ui-elements/Cards";
//import General from "../pages/ui-elements/General";
//import Grid from "../pages/ui-elements/Grid";
//import Modals from "../pages/ui-elements/Modals";
//import Notifications from "../pages/ui-elements/Notifications";
//import Tabs from "../pages/ui-elements/Tabs";
//import Typography from "../pages/ui-elements/Typography";

// Pages
//const Settings = async(() => import("../pages/pages/Settings"));
//const Clients = async(() => import("../pages/pages/Clients"));
//const Invoice = async(() => import("../pages/pages/Invoice"));
//const Pricing = async(() => import("../pages/pages/Pricing"));
//const Tasks = async(() => import("../pages/pages/Tasks"));
//const Chat = async(() => import("../pages/pages/Chat"));

// Documentation
//const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
//const Plugins = async(() => import("../pages/docs/Plugins"));
//const Changelog = async(() => import("../pages/docs/Changelog"));

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));
const LyfSupportDashboard = async(() =>
  import("../pages/dashboards/LyfSupport")
);

// Forms
//const Layouts = async(() => import("../pages/forms/Layouts"));
//const BasicElements = async(() => import("../pages/forms/BasicElements"));
//const AdvancedElements = async(() => import("../pages/forms/AdvancedElements"));
//const InputGroups = async(() => import("../pages/forms/InputGroups"));
//const Editors = async(() => import("../pages/forms/Editors"));
//const Validation = async(() => import("../pages/forms/Validation"));

// Tables
//const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
//const AdvancedTables = async(() => import("../pages/tables/Advanced"));

// Charts
//const Chartjs = async(() => import("../pages/charts/Chartjs"));
//const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
//const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
//const IonIcons = async(() => import("../pages/icons/IonIcons"));
//const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
//const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
//const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
//const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

// Routes
/*const landingRoutes = {
  path: "/",
  name: "Landing Page",
  component: Landing,
  children: null
};*/

const landingRoutes = {
  path: "/",
  name: "Lyf Support Dashboard",
  header: "Main",
  icon: faHome,
  component: LyfSupportDashboard,
  children: null,
  secure: true,
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  icon: faHome,
  component: Default,
  children: null,
  secure: true,
};

const lyfsupportDashboardRoutes = {
  path: "/dashboard/lyfsupport",
  name: "Lyf Support Dashboard",
  header: "Main",
  icon: faHome,
  component: LyfSupportDashboard,
  children: null,
  secure: true,
};
/*const uiRoutes = {
	path: "/ui",
	name: "User Interface",
	header: "Elements",
	icon: faFlask,
	children: [
		{
			path: "/ui/alerts",
			name: "Alerts",
			component: Alerts
		},
		{
			path: "/ui/buttons",
			name: "Buttons",
			component: Buttons
		},
		{
			path: "/ui/cards",
			name: "Cards",
			component: Cards
		},
		{
			path: "/ui/general",
			name: "General",
			component: General
		},
		{
			path: "/ui/grid",
			name: "Grid",
			component: Grid
		},
		{
			path: "/ui/modals",
			name: "Modals",
			component: Modals
		},
		{
			path: "/ui/notifications",
			name: "Notifications",
			component: Notifications
		},
		{
			path: "/ui/tabs",
			name: "Tabs",
			component: Tabs
		},
		{
			path: "/ui/typography",
			name: "Typography",
			component: Typography
		}
	],
	secure: true
};*/

/*const chartRoutes = {
	path: "/charts",
	name: "Charts",
	icon: faChartPie,
	badgeColor: "primary",
	badgeText: "New",
	children: [
		{
			path: "/charts/chartjs",
			name: "Chart.js",
			component: Chartjs
		},
		{
			path: "/charts/apexcharts",
			name: "ApexCharts",
			component: ApexCharts
		}
	],
	secure: true
};*/

/*const formRoutes = {
	path: "/forms",
	name: "Forms",
	icon: faCheckSquare,
	children: [
		{
			path: "/forms/layouts",
			name: "Layouts",
			component: Layouts
		},
		{
			path: "/forms/basic-elements",
			name: "Basic Elements",
			component: BasicElements
		},
		{
			path: "/forms/advanced-elements",
			name: "Advanced Elements",
			component: AdvancedElements
		},
		{
			path: "/forms/input-groups",
			name: "Input Groups",
			component: InputGroups
		},
		{
			path: "/forms/editors",
			name: "Editors",
			component: Editors
		},
		{
			path: "/forms/validation",
			name: "Validation",
			component: Validation
		}
	],
	secure: true
};*/

/*const tableRoutes = {
	path: "/tables",
	name: "Tables",
	icon: faTable,
	children: [
		{
			path: "/tables/bootstrap",
			name: "Bootstrap",
			component: BootstrapTables
		},
		{
			path: "/tables/advanced-tables",
			name: "Advanced",
			component: AdvancedTables
		}
	],
	secure: true
};*/

/*const iconRoutes = {
	path: "/icons",
	name: "Icons",
	icon: faHeart,
	children: [
		{
			path: "/icons/feather",
			name: "Feather",
			component: Feather
		},
		{
			path: "/icons/ion-icons",
			name: "Ion Icons",
			component: IonIcons
		},
		{
			path: "/icons/font-awesome",
			name: "Font Awesome",
			component: FontAwesome
		}
	],
	secure: true
};*/

/*const calendarRoutes = {
	path: "/calendar",
	name: "Calendar",
	icon: faCalendarAlt,
	component: Calendar,
	children: null
};*/

/*const mapRoutes = {
	path: "/maps",
	name: "Maps",
	icon: faMapMarkerAlt,
	children: [
		{
			path: "/maps/google-maps",
			name: "Google Maps",
			component: GoogleMaps
		},
		{
			path: "/maps/vector-maps",
			name: "Vector Maps",
			component: VectorMaps
		}
	],
	secure: true
};*/

/*const pageRoutes = {
	path: "/pages",
	name: "Pages",
	icon: faFile,
	keys: [],
	children: [
		{
			path: "/pages/settings",
			name: "Settings",
			component: Settings,
			keys: []
		},
		{
			path: "/pages/clients",
			name: "Clients",
			component: Clients,
			badgeColor: "primary",
			badgeText: "New",
			keys: []
		},
		{
			path: "/pages/invoice",
			name: "Invoice",
			component: Invoice,
			keys: []
		},
		{
			path: "/pages/pricing",
			name: "Pricing",
			component: Pricing,
			keys: []
		},
		{
			path: "/pages/tasks",
			name: "Tasks",
			component: Tasks,
			keys: []
		},
		{
			path: "/pages/chat",
			name: "Chat",
			component: Chat,
			badgeColor: "primary",
			badgeText: "New",
			keys: []
		},
		{
			path: "/pages/blank",
			name: "Blank Page",
			component: Blank,
			keys: []
		}
	],
	secure: true
};*/

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/change-password",
      name: "Change Password",
      component: ChangePassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  secure: false,
};

const memberRoutes = {
  path: "/members/list",
  name: "Members",
  keys: ["members_read", "members_write"],
  header: "System",
  icon: faUsers,
  component: MemberList,
  children: [
    {
      path: "/members/list",
      name: "Members",
      keys: ["members_read", "members_write"],
      component: MemberList,
      visible: false,
    },
    {
      path: "/members/add",
      name: "Add Member",
      keys: ["members_write"],
      component: AddMember,
      visible: false,
    },
    {
      path: "/members/edit/:user_id",
      name: "Edit Member",
      keys: ["members_read", "members_write"],
      component: EditMember,
      visible: false,
    },
  ],
  secure: true,
  hideChildren: true,
};

const memberContentRoutes = {
  path: "/content",
  name: "Member Content",
  icon: faComments,
  children: [
    {
      path: "/content/experiences",
      name: "Experiences",
      keys: ["experiences_read", "experiences_write"],
      component: ExperienceList,
    },
    {
      path: "/content/reports",
      name: "Content Reporting",
      keys: ["content_reports_read"],
      component: ReportingList,
    },
    {
      path: "/content/experiences/add",
      name: "Add Experience",
      keys: ["experiences_write"],
      component: AddExperience,
      visible: false,
    },
    {
      path: "/content/experiences/edit/:beat_id",
      name: "Edit Experience",
      keys: ["experiences_read", "experiences_write"],
      component: EditExperience,
      visible: false,
    },
  ],
  secure: true,
};

const systemContentRoutes = {
  path: "/system-content",
  name: "System Content",
  icon: faLayerGroup,
  children: [
    {
      path: "/system-content/partnerships",
      name: "Partnerships",
      keys: ["categories_read", "categories_write"],
      component: PartnershipList,
    },
    {
      path: "/system-content/partnerships/add",
      name: "Add Partnership",
      keys: ["categories_write"],
      component: AddPartnership,
      visible: false,
    },
    {
      path: "/system-content/partnerships/edit/:partnership_id",
      name: "Edit Partnership",
      keys: ["categories_read", "categories_write"],
      component: EditPartnership,
      visible: false,
    },
    {
      path: "/system-content/labels/add",
      name: "Add Label",
      keys: ["categories_write"],
      component: AddLabel,
      visible: false,
    },
    {
      path: "/system-content/labels/edit/:partnership_id",
      name: "Edit Label",
      keys: ["categories_read", "categories_write"],
      component: EditLabel,
      visible: false,
    },
    {
      path: "/system-content/categories",
      name: "Categories",
      keys: ["categories_read", "categories_write"],
      component: CategoryList,
    },
    {
      path: "/system-content/categories/add",
      name: "Add Category",
      keys: ["categories_write"],
      component: AddCategory,
      visible: false,
    },
    {
      path: "/system-content/categories/edit/:category_id",
      name: "Edit Category",
      keys: ["categories_read", "categories_write"],
      component: EditCategory,
      visible: false,
    },
    {
      path: "/system-content/banned-words",
      name: "Banned Words",
      keys: ["banned_words_read", "banned_words_write"],
      component: WordList,
    },
    {
      path: "/system-content/banned-words/add",
      name: "Add Banned Word",
      keys: ["banned_words_write"],
      component: AddWord,
      visible: false,
    },
    {
      path: "/system-content/banned-words/edit/:word_id",
      name: "Edit Banned Word",
      keys: ["banned_words_read", "banned_words_write"],
      component: EditWord,
      visible: false,
    },
    {
      path: "/system-content/algorithm",
      name: "Algorithm",
      keys: ["algorithm_read", "algorithm_write"],
      component: Algorithm,
    },
    {
      path: "/system-content/quotes",
      name: "Quotes",
      keys: ["quotes_read", "quotes_write"],
      component: QuoteList,
    },
    {
      path: "/system-content/quotes/add",
      name: "Add Quote",
      keys: ["quotes_write"],
      component: AddQuote,
      visible: false,
    },
    {
      path: "/system-content/quotes/edit/:quote_id",
      name: "Edit Quote",
      keys: ["quotes_read", "quotes_write"],
      component: EditQuote,
      visible: false,
    },
    {
      path: "/system-content/faq",
      name: "Help / FAQ",
      keys: ["help_read", "help_write"],
      component: HelpList,
    },
    {
      path: "/system-content/faq/add",
      name: "Add Category",
      keys: ["help_write"],
      component: AddHelpCategory,
      visible: false,
    },
    {
      path: "/system-content/faq/edit/:category_id",
      name: "Edit Category",
      keys: ["help_read", "help_write"],
      component: EditHelpCategory,
      visible: false,
    },
    {
      path: "/system-content/faq/add-topic/:category_id",
      name: "Add Topic",
      keys: ["help_write"],
      component: AddHelpTopic,
      visible: false,
    },
    {
      path: "/system-content/faq/edit-topic/:topic_id",
      name: "Edit Topic",
      keys: ["help_read", "help_write"],
      component: EditHelpTopic,
      visible: false,
    },
    /*{
			path: "/system-content/documents",
			name: "Documents",
			keys: ["documents_read", "documents_write"],
			component: AddMember
		},
		{
			path: "/system-content/import",
			name: "Sendbird Import",
			keys: [],
			component: MessageImport
		}*/
  ],
  secure: true,
};

const analyticRoutes = {
  path: "/analytics/commentingteam",
  name: "Commenting Team Stats",
  keys: ["members_read", "members_write"],
  header: "Analytics",
  icon: faUsers,
  component: CommentingTeamStats,
  children: [
    {
      path: "/analytics/commentingteam",
      name: "Members",
      keys: ["members_read", "members_write"],
      component: CommentingTeamStats,
      visible: false,
    },
    {
      path: "/analytics/commentingteam/add",
      name: "Add Team Member",
      keys: [],
      component: AddTeamMember,
      visible: false,
    },
    {
      path: "/analytics/commentingteam/edit/:team_member_id",
      name: "Edit Team Member",
      keys: [],
      component: EditTeamMember,
      visible: false,
    },
    {
      path: "/analytics/recentusers",
      name: "Recent User Stats",
      keys: [],
      component: RecentUserStats,
      visible: false,
    },
    {
      path: "/analytics/monthlyusers",
      name: "Monthly User Stats",
      keys: [],
      component: MonthlyUserStats,
      visible: false,
    },
    {
      path: "/analytics/session",
      name: "Session Length Stats",
      keys: [],
      component: SessionStats,
      visible: false,
    },
    {
      path: "/analytics/downloads",
      name: "New Downloads",
      keys: [],
      component: DownloadStats,
      visible: false,
    },
    {
      path: "/analytics/revenue",
      name: "Revenue",
      keys: [],
      component: RevenueStats,
      visible: false,
    },
    {
      path: "/analytics/signups",
      name: "New Signups",
      keys: [],
      component: SignupStats,
      visible: false,
    },
    {
      path: "/analytics/retention",
      name: "New Signups",
      keys: [],
      component: RetentionStats,
      visible: false,
    },
  ],
  secure: true,
  hideChildren: true,
};

/*const promosRoutes = {
	path: "/marketing/promos",
	name: "Promo Codes",
	header: "Marketing",
	keys: ["promo_codes_read", "promo_codes_write"],
	icon: faTag,
	component: PromoList,
	children: [
		{
			path: "/marketing/promos",
			name: "Promo Codes",
			keys: ["promo_codes_read", "promo_codes_write"],
			component: PromoList,
			visible: false
		},
		{
			path: "/marketing/promos/add",
			name: "Add Promo Code",
			keys: ["promo_codes_write"],
			component: AddPromo,
			visible: false
		},
		{
			path: "/marketing/promos/edit/:promo_id",
			name: "Edit Promo Code",
			keys: ["promo_codes_read", "promo_codes_write"],
			component: EditPromo,
			visible: false
		}
	],
	secure: true,
	hideChildren: true
};*/

/*const monetizationRoutes = {
	path: "/monetization",
	name: "Monetization",
	icon: faMoneyBill,
	children: [
		{
			path: "/monetization/subscriptions",
			name: "Subscriptions",
			keys: ["subscriptions_read", "subscriptions_write"],
			component: MemberList
		},
		{
			path: "/monetization/products",
			name: "Products",
			keys: ["products_read", "products_write"],
			component: MemberList
		},
		{
			path: "/monetization/subscribers",
			name: "Member Subscriptions",
			keys: ["member_subscriptions_read", "member_subscriptions_write"],
			component: MemberList
		},
		{
			path: "/monetization/purchases",
			name: "Member Purchases",
			keys: ["purchases_read"],
			component: MemberList
		}
	],
	secure: true
};*/

const usersRoutes = {
  path: "/admin/users",
  name: "Admin Users",
  keys: ["admin_users_read", "admin_users_write"],
  header: "Administration",
  icon: faUserShield,
  component: UserList,
  children: [
    {
      path: "/admin/users",
      name: "Admin Users",
      keys: ["admin_users_read", "admin_users_write"],
      component: UserList,
      visible: false,
    },
    {
      path: "/admin/users/add",
      name: "Add User",
      keys: ["admin_users_write"],
      component: AddUser,
      visible: false,
    },
    {
      path: "/admin/users/edit/:user_id",
      name: "Edit User",
      keys: ["admin_users_read", "admin_users_write"],
      component: EditUser,
      visible: false,
    },
  ],
  secure: true,
  hideChildren: true,
};

const rolesRoutes = {
  path: "/admin/roles",
  name: "Roles",
  keys: ["admin_roles_read", "admin_roles_write"],
  icon: faShieldAlt,
  component: RoleList,
  children: [
    {
      path: "/admin/roles",
      name: "Roles",
      keys: ["admin_roles_read", "admin_roles_write"],
      component: RoleList,
      visible: false,
    },
    {
      path: "/admin/roles/add",
      name: "Add Role",
      keys: ["admin_roles_write"],
      component: AddRole,
      visible: false,
    },
    {
      path: "/admin/roles/edit/:role_id",
      name: "Edit Role",
      keys: ["admin_roles_read", "admin_roles_write"],
      component: EditRole,
      visible: false,
    },
  ],
  secure: true,
  hideChildren: true,
};

const reportingRoutes = {
  path: "/reporting",
  name: "Reporting",
  icon: faFilePdf,
  children: [
    {
      path: "/reporting/monthly-new-user",
      name: "Monthly New User Sign Ups",
      keys: [],
      component: MonthlyNewUser,
    },
    {
      path: "/reporting/monthly-freetrial-sessions-held",
      name: "Monthly Free Trials",
      keys: [],
      component: MonthlyFreeTrialSessionsHeld,
    },
    {
      path: "/reporting/monthly-new-customer",
      name: "Monthly New Customers",
      keys: [],
      component: MonthlyNewCustomer,
    },
    {
      path: "/reporting/monthly-returning-customers",
      name: "Monthly Returning Customers",
      keys: [],
      component: MonthlyReturningCustomers,
    },
    {
      path: "/reporting/monthly-sessions-held",
      name: "Monthly Paid Sessions Held",
      keys: [],
      component: MonthlySessionsHeld,
    },
    {
      path: "/reporting/monthly-customer-spend",
      name: "Monthly Customer Spend",
      keys: [],
      component: MonthlyCustomerSpend,
    },
    {
      path: "/reporting/customer-spend",
      name: "Customer Spend Detail",
      keys: [],
      component: CustomerSpendReport,
    },
  ],
  secure: true,
};

const forceUpdateRoutes = {
  path: "/force-update",
  name: "Force Update",
  icon: faSyncAlt,
  component: ForceUpdate,
  children: null,
  secure: true,
};

/*const documentationRoutes = {
	path: "/docs",
	name: "Documentation",
	icon: faBook,
	children: [
		{
			path: "/docs/getting-started",
			name: "Getting Started",
			component: GettingStarted
		},
		{
			path: "/docs/plugins",
			name: "Plugins",
			component: Plugins
		},
		{
			path: "/docs/changelog",
			name: "Changelog",
			component: Changelog
		}
	],
	secure: true
};*/

// Dashboard specific routes
export const dashboard = [
  lyfsupportDashboardRoutes,
  dashboardRoutes,
  memberRoutes,
  memberContentRoutes,
  systemContentRoutes,
  analyticRoutes,
  // promosRoutes,
  // monetizationRoutes,
  usersRoutes,
  rolesRoutes,
  forceUpdateRoutes,
  reportingRoutes,
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  lyfsupportDashboardRoutes,
  dashboardRoutes,
  memberRoutes,
  memberContentRoutes,
  systemContentRoutes,
  analyticRoutes,
  // promosRoutes,
  // monetizationRoutes,
  usersRoutes,
  rolesRoutes,
  forceUpdateRoutes,
  reportingRoutes,
];
