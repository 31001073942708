import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const dataService = require("../../services/dataServices.js");

const AddWord = () => {
  const navigate = useHistory();
  const [word, setWord] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function constructObject() {
    var obj = {
      word: word,
      allow_on_home_flag: 1,
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);
    var obj = await constructObject();
    var result = await dataService.addWord(obj);

    showToastr(result.result.word_id);
  }

  function performCancel() {
    navigate.goBack();
  }

  function showToastr(word_id) {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        navigate.push("/system-content/banned-words/edit/" + word_id);
        setIsLoading(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Add Banned Word",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add Banned Word</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/system-content/banned-words">Banned Words</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Banned Word</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col>
                    <AvGroup>
                      <Label for="word">Word</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="word"
                        id="word"
                        placeholder="Enter word"
                        value={word}
                        disabled={isLoading}
                        onChange={(e) => setWord(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a word.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddWord;
