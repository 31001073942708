import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink, Spinner, Label, CustomInput,UncontrolledDropdown,Dropdown,DropdownToggle,DropdownMenu,DropdownItem, Form, FormGroup, Input, InputGroup
} from 'reactstrap';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { forceUpdateGet, forceUpdateSave } from '../../services/dataServices';
const dataService = require("../../services/dataServices.js");


const ForceUpdate = (props) => {

  async function constructObject() {
    var obj = {
      enabled: forceUpdateEnabled?true:false,
      ios_release: IOSRelease,
      android_release: AndroidRelease
    };

    return obj;
  }
  async function save() {
    var obj = await constructObject();
    console.log(obj);
  }

  const [forceUpdateEnabled,setForceUpdateEnabled]=useState(false)
  const [IOSRelease,setIOSRelease]=useState("")
  const [AndroidRelease,setAndroidRelease]=useState("")
  const [loading,setLoading]=useState("")

  useEffect(()=>
  {
    async function initiatePage(){
      setLoading('load')
      let ios=await forceUpdateGet('ios')
      let android=await forceUpdateGet('android')

      setForceUpdateEnabled(ios.is_force)
      setIOSRelease(ios.minimum_version_code)
      setAndroidRelease(android.minimum_version_code)
      setLoading('')
    }
    initiatePage()

    // Initiation
  },[])

  async function performCancel() {
    setLoading('cancel')
    setForceUpdateEnabled(false);
    setAndroidRelease("")
    setIOSRelease("")
    await forceUpdateSave(false, "", "")
    setLoading('')
  }

  async function performSave(){
    setLoading('save')
    await forceUpdateSave(forceUpdateEnabled,IOSRelease,AndroidRelease)
    setLoading('')
  }

  return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            Force Update
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Force Update</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Form onSubmit={(e)=>{e.preventDefault();}}>
                    {(loading==="load")?
                      <Col xs={12} className={"text-center"}>
                        <Spinner size="sm" className="mr-2 align-middle" /> Loading
                      </Col>
                      :''}
                    <FormGroup>
                      <Label for="forceUpdateEnabledCheckbox">Enable Force Update For iOS & Android Releases:</Label>
                      <div>
                        <CustomInput type="switch" id="forceUpdateEnabledCheckbox" name="forceUpdateEnabledCheckbox" label={forceUpdateEnabled?'Enabled':'Disabled'}
                                     checked={forceUpdateEnabled}
                                     onChange={(e) => setForceUpdateEnabled(e.target.checked)}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="iosRelease">iOS Release</Label>
                      <Input type={"text"} name="iosRelease" id="iosRelease" value={IOSRelease} onChange={(e)=>setIOSRelease(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="androidRelease">Android Release</Label>
                      <Input type={"text"} name="androidRelease" id="androidRelease" value={AndroidRelease} onChange={(e)=>setAndroidRelease(e.target.value)}/>
                    </FormGroup>
                    <Button
                        color="light"
                        size="lg"
                        className="mr-2"
                        onClick={performCancel}
                        disabled={loading.length}
                    >
                      {(loading.length && loading==="cancel")? (
                          <Spinner size="sm" className="mr-2 align-middle" />
                      ):''}
                      Cancel
                    </Button>
                    <Button color="primary" size="lg" onClick={performSave} disabled={loading.length}>
                      {(loading.length && loading==="save") ? (
                          <Spinner size="sm" className="mr-2 align-middle" />
                      ):''}
                      Save
                    </Button>
                  </Form>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
  );
};

export default ForceUpdate;
