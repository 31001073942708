import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { RefreshCw } from "react-feather";
import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import DateTime from "react-datetime";
import Select from "react-select";
import { CSVLink } from "react-csv";

import CommentingTeamList from "./CommentingTeamList";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const dataService = require("../../services/dataServices.js");

const teamOptions = [{ value: "0", label: "All" }];

const CommentingTeamStats = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [dataFilter, setDataFilter] = useState("all");
  const [data, setData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [teamFilter, setTeamFilter] = useState(teamOptions[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [teamList, setTeamList] = useState(teamOptions);
  const [csvData, setCSVData] = useState([]);

  const navigate = useHistory();

  function toggleTab(tab) {
    setActiveTab(tab);
  }

  const columns = [
    {
      id: "team_member_name",
      displayName: "Team Member",
    },
    {
      id: "date",
      displayName: "Date",
    },
    {
      id: "count",
      displayName: "Count",
    },
  ];

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.0)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 25,
          },
          display: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  useEffect(() => {
    async function loadData() {
      var today = DateTime.moment();
      var weekBegin = DateTime.moment().add(-7, "day");

      setStartDate(weekBegin);
      setEndDate(today);

      setIsLoading(true);

      // load up the team into the dropdown
      var new_list = [];
      new_list.push({ value: "0", label: "All" });

      var team_info = await dataService.teamMemberList(
        1,
        1,
        10000,
        "first_name",
        "asc"
      );
      team_info.list.forEach(function (value, index) {
        new_list.push({
          value: value.team_member_id,
          label: value.first_name + " " + value.last_name,
        });
      });
      setTeamList(new_list);

      var dta = await dataService.teamMemberData(
        "comments",
        weekBegin.format("YYYY-MM-DD"),
        today.format("YYYY-MM-DD"),
        0
      );
      setData(dta);

      // parse and construct the CSV data
      var raw = [];
      if (dta.datasets) {
        for (var i = 0; i < dta.datasets.length; i++) {
          var employee_name = dta.datasets[i].label; // user's name
          for (var j = 0; j < dta.labels.length; j++) {
            var date_string = dta.labels[j];
            var count = dta.datasets[i].data[j];

            raw.push({ Name: employee_name, Date: date_string, Count: count });
          }
        }
      }
      setCSVData(raw);

      setIsLoading(false);
    }

    loadData();
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  async function toggle(tab) {
    setDataFilter(tab);
    await getData(
      tab,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      parseInt(teamFilter.value)
    );
  }

  function performAdd() {
    navigate.push("/analytics/commentingteam/add");
  }

  async function updateStartDate(dt) {
    setStartDate(dt);
    await getData(
      dataFilter,
      dt.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      parseInt(teamFilter.value)
    );
  }

  async function updateEndDate(dt) {
    setEndDate(dt);
    await getData(
      dataFilter,
      startDate.format("YYYY-MM-DD"),
      dt.format("YYYY-MM-DD"),
      parseInt(teamFilter.value)
    );
  }

  async function updateTeamFilter(tm) {
    setTeamFilter(tm);
    await getData(
      dataFilter,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      parseInt(tm.value)
    );
  }

  async function getData(datatype, sdate, edate, teamid) {
    setIsLoading(true);
    var dta = await dataService.teamMemberData(datatype, sdate, edate, teamid);
    setData(dta);

    // parse and construct the CSV data
    var raw = [];
    if (dta.datasets) {
      for (var i = 0; i < dta.datasets.length; i++) {
        var employee_name = dta.datasets[i].label; // user's name
        for (var j = 0; j < dta.labels.length; j++) {
          var date_string = dta.labels[j];
          var count = dta.datasets[i].data[j];

          raw.push({ Name: employee_name, Date: date_string, Count: count });
        }
      }
    }
    setCSVData(raw);

    setIsLoading(false);
  }

  async function reloadData(e) {
    e.preventDefault();
    await getData(
      dataFilter,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      parseInt(teamFilter.value)
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Commenting Team Stats
          {isLoading && (
            <Button
              className="btn btn-md btn-primary float-right"
              disabled={true}
            >
              <Spinner size="sm" className="mr-2 align-middle" />
              Export to CSV
            </Button>
          )}
          {!isLoading && (
            <CSVLink
              data={csvData}
              asyncOnClick={true}
              filename={"data.csv"}
              className="btn btn-md btn-primary float-right"
            >
              Export to CSV
            </CSVLink>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Commenting Team Stats</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card className="flex-fill w-100">
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                <Row>
                  <Col xs={12} md={2} className="mb-2 mb-md-0">
                    <Nav pills className="card-header-pills">
                      <Dropdown
                        nav
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                      >
                        <DropdownToggle nav caret>
                          {dataFilter === "all" && (
                            <React.Fragment>All</React.Fragment>
                          )}
                          {dataFilter === "comments" && (
                            <React.Fragment>Comments</React.Fragment>
                          )}
                          {dataFilter === "replies" && (
                            <React.Fragment>Replies</React.Fragment>
                          )}
                          {dataFilter === "posts" && (
                            <React.Fragment>Posts</React.Fragment>
                          )}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            className={classnames({
                              active: dataFilter === "all",
                            })}
                            onClick={() => {
                              toggle("all");
                            }}
                          >
                            All
                          </DropdownItem>
                          <DropdownItem
                            className={classnames({
                              active: dataFilter === "comments",
                            })}
                            onClick={() => {
                              toggle("comments");
                            }}
                          >
                            Comments
                          </DropdownItem>
                          <DropdownItem
                            className={classnames({
                              active: dataFilter === "replies",
                            })}
                            onClick={() => {
                              toggle("replies");
                            }}
                          >
                            Replies
                          </DropdownItem>
                          <DropdownItem
                            className={classnames({
                              active: dataFilter === "posts",
                            })}
                            onClick={() => {
                              toggle("posts");
                            }}
                          >
                            Posts
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Nav>
                  </Col>
                  <Col xs={12} md={3} className="mb-2 mb-md-0">
                    <Row>
                      <Col
                        xs={4}
                        md={5}
                        className="d-flex align-items-center text-truncate"
                      >
                        Start Date
                      </Col>
                      <Col xs={8} md={7}>
                        <DateTime
                          input={true}
                          value={startDate}
                          timeFormat={false}
                          inputProps={{
                            placeholder: "Start date",
                            disabled: isLoading,
                            className: "form-control form-control-md",
                          }}
                          closeOnSelect={true}
                          onChange={(e) => updateStartDate(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={3} className="mb-2 mb-md-0">
                    <Row>
                      <Col
                        xs={4}
                        md={5}
                        className="d-flex align-items-center text-truncate"
                      >
                        End Date
                      </Col>
                      <Col xs={8} md={7}>
                        <DateTime
                          input={true}
                          value={endDate}
                          timeFormat={false}
                          inputProps={{
                            placeholder: "End date",
                            disabled: isLoading,
                            className: "form-control form-control-md",
                          }}
                          closeOnSelect={true}
                          onChange={(e) => updateEndDate(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={3}>
                    <Row>
                      <Col
                        xs={4}
                        md={3}
                        className="d-flex align-items-center text-truncate"
                      >
                        Member
                      </Col>
                      <Col xs={8} md={9}>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={teamList}
                          value={teamFilter}
                          onChange={(e) => updateTeamFilter(e)}
                          isDisabled={isLoading}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={1} className="d-none d-sm-block">
                    <div className="card-actions float-right">
                      <span className="cursor-pointer mr-1">
                        <RefreshCw onClick={(e) => reloadData(e)} />
                      </span>
                    </div>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody className="py-3">
              <div className="chart chart-sm">
                {isLoading && (
                  <div
                    style={{ height: 400 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Spinner size="sm" className="mr-2 align-middle" />
                  </div>
                )}
                {!isLoading && (
                  <Row>
                    <Col xs={12} sm={7} lg={9}>
                      <Bar data={data} options={options} height={400} />
                    </Col>
                    <Col xs={12} sm={5} lg={3}>
                      {teamFilter.value === "0" && (
                        <div
                          style={{
                            overflowX: "hidden",
                            overflowY: "scroll",
                            height: 400,
                            backgroundColor: "#EEEEEE",
                            borderColor: "#DDDDDD",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 7,
                            padding: 6,
                          }}
                        >
                          {data.datasets.map((dataset) => (
                            <Row style={{ padding: 4 }}>
                              <Col xs={9} className="text-truncate">
                                <div
                                  className="float-left mr-2"
                                  style={{
                                    backgroundColor: dataset.backgroundColor,
                                    borderColor: dataset.borderColor,
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    width: 30,
                                    height: "100%",
                                  }}
                                >
                                  &nbsp;
                                </div>
                                <small>{dataset.label}</small>
                              </Col>
                              <Col xs={3}>
                                <small className="float-right font-weight-bold">
                                  {dataset.data.reduce(
                                    (prev, next) => prev + next,
                                    0
                                  )}
                                </small>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )}
                      {teamFilter.value !== "0" && (
                        <Container
                          fluid
                          style={{
                            backgroundColor: "#EEEEEE",
                            borderRadius: 7,
                            borderColor: "#DDDDDD",
                            borderWidth: 1,
                            borderStyle: "solid",
                            height: "100%",
                            padding: 6,
                          }}
                        >
                          <Row>
                            <Col>
                              <h4
                                className="text-center"
                                style={{ marginTop: 100 }}
                              >
                                Total
                              </h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h1 className="text-center">
                                {data.datasets[0].data.reduce(
                                  (prev, next) => prev + next,
                                  0
                                )}
                              </h1>
                            </Col>
                          </Row>
                        </Container>
                      )}
                    </Col>
                  </Row>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="flex-fill w-100">
            <CardHeader>
              <div className="card-actions float-right">
                <span className="cursor-pointer mr-1">
                  <Button
                    color="success"
                    size="md"
                    className="float-right"
                    onClick={performAdd}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Add New
                  </Button>
                </span>
              </div>
              <CardTitle tag="h5" className="mb-0 float-left">
                <Nav pills className="card-header-pills">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggleTab("1");
                      }}
                    >
                      Active Team Members
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggleTab("2");
                      }}
                    >
                      In-active Team Members
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardTitle>
            </CardHeader>
            <CardBody className="py-3">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CommentingTeamList active_flag={1} />
                </TabPane>
                <TabPane tabId="2">
                  <CommentingTeamList active_flag={0} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(CommentingTeamStats);
