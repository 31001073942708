import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  Label,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import EditTeamMemberUsers from "./EditTeamMemberUsers";

const dataService = require("../../services/dataServices.js");

const EditTeamMember = () => {
  let { team_member_id } = useParams();
  const navigate = useHistory();

  const [activeTab, setActiveTab] = useState("1");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [active, setActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  function toggle(tab) {
    setActiveTab(tab);
  }

  function performAdd() {
    navigate.push("/analytics/commentingteam/" + team_member_id + "/users/add");
  }

  async function constructObject() {
    var obj = {
      team_member_id: team_member_id,
      first_name: firstName,
      last_name: lastName,
      active_flag: active ? 1 : 0,
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);
    setIsDisabled(true);

    var obj = await constructObject();
    await dataService.updateTeamMember(obj);

    showToastr();
  }

  function performCancel() {
    navigate.push("/analytics/commentingteam/");
  }

  useEffect(() => {
    async function loadData() {
      // load the role
      var data = await dataService.teamMember(team_member_id);

      setFirstName(data.first_name);
      setLastName(data.last_name);
      setActive(data.active_flag === 1);

      setIsLoading(false);
      setIsDisabled(false);
    }

    loadData();
  }, [team_member_id]);

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        setIsLoading(false);
        setIsDisabled(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Edit Team Member",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Edit Team Member
          <Button color="success" className="float-right" onClick={performAdd}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add New User
          </Button>
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/analytics/commentingteam">Commenting Team</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Team Member</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Team Member Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Users
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <AvForm onValidSubmit={save}>
                    <Row>
                      <Col sm={6}>
                        <AvGroup>
                          <Label for="first_name">First Name</Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="first_name"
                            id="first_name"
                            placeholder="Enter first name"
                            value={firstName}
                            disabled={isDisabled}
                            onChange={(e) => setFirstName(e.target.value)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a first name.",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={6}>
                        <AvGroup>
                          <Label for="last_name">Last Name</Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="Enter last name"
                            value={lastName}
                            disabled={isDisabled}
                            onChange={(e) => setLastName(e.target.value)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a last name.",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4}>
                        <Label for="active">Active?</Label>
                        <AvCheckboxGroup name={"active"}>
                          <CustomInput
                            type="checkbox"
                            id={"active"}
                            label="Active"
                            checked={active}
                            disabled={isLoading}
                            onChange={(e) => setActive(e.target.checked)}
                          />
                        </AvCheckboxGroup>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <Button
                        color="light"
                        size="lg"
                        className="mr-2"
                        onClick={performCancel}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" size="lg" disabled={isDisabled}>
                        {isLoading && (
                          <Spinner size="sm" className="mr-2 align-middle" />
                        )}
                        Save
                      </Button>
                    </div>
                  </AvForm>
                </TabPane>
                <TabPane tabId="2">
                  <EditTeamMemberUsers team_member_id={team_member_id} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditTeamMember;
