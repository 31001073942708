import React, { useState } from "react";
import { useStore } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";

const childRoutes = (Layout, routes, isAuthenticated, permissions) => {
  function compareKeys(permissions, keys) {
    var result = false;

    if (keys && permissions) {
      if (keys.length > 0) {
        keys.forEach((key) => {
          const matchIndex = permissions.findIndex(function (row) {
            return row === key;
          });
          if (matchIndex > -1) {
            result = true;
          }
        });
      } else {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  return routes.map(
    ({ children, path, secure, keys, component: Component }, index) =>
      children ? (
        children.map(({ path, keys, component: Component }, index) =>
          ((secure ?? false) && !isAuthenticated) ||
          (!compareKeys(permissions, keys) && isAuthenticated) ? (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Redirect
                  to={{ pathname: "/auth/sign-in", state: { from: path } }}
                />
              )}
            />
          ) : (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          )
        )
      ) : ((secure ?? false) && !isAuthenticated) ||
        (!compareKeys(permissions, keys) && isAuthenticated) ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Redirect
              to={{ pathname: "/auth/sign-in", state: { from: path } }}
            />
          )}
        />
      ) : (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );
};

const Routes = () => {
  const store = useStore();
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("authenticated") ?? false
  );
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);

  store.subscribe(() => {
    const currentAuth = store.getState().authentication.isAuthenticated;

    // storing locally
    if (currentAuth) {
      localStorage.setItem("authenticated", currentAuth);
    }

    setIsAuthenticated(currentAuth);
  });

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {childRoutes(
            DashboardLayout,
            landingRoutes,
            isAuthenticated,
            currentUser ? currentUser.permissions : []
          )}
          {childRoutes(
            DashboardLayout,
            dashboardRoutes,
            isAuthenticated,
            currentUser ? currentUser.permissions : []
          )}
          {childRoutes(AuthLayout, pageRoutes, isAuthenticated, [])}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
