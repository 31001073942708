import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroup,
  ListGroupItem,
  FormGroup,
  CustomInput,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";

const dataService = require("../../services/dataServices.js");

const options = [
  { value: "0", label: "Unlimited" },
  { value: "3", label: "3 Hours" },
  { value: "6", label: "6 Hours" },
  { value: "12", label: "12 Hours" },
  { value: "24", label: "24 Hours" },
  { value: "36", label: "36 Hours" },
  { value: "48", label: "48 Hours" },
  { value: "72", label: "72 Hours" },
  { value: "168", label: "7 Days" },
  { value: "336", label: "14 Days" },
  { value: "504", label: "21 Days" },
  { value: "720", label: "30 Days" },
];

const ageOptions = [
  { value: "1", label: "Experience Create Date" },
  { value: "2", label: "Experience Activity Date" },
  { value: "3", label: "Top Post Create Date" },
  { value: "4", label: "Top Post Activity Date" },
];

const sortOptions = [
  { value: "1", label: "Aggregate Score" },
  { value: "2", label: "Top Post Score" },
];

const scoreAgeOptions = [
  { value: "0", label: "Unlimited" },
  { value: "3", label: "3 Hours" },
  { value: "6", label: "6 Hours" },
  { value: "12", label: "12 Hours" },
  { value: "24", label: "24 Hours" },
  { value: "36", label: "36 Hours" },
  { value: "48", label: "48 Hours" },
  { value: "72", label: "72 Hours" },
  { value: "168", label: "7 Days" },
  { value: "336", label: "14 Days" },
  { value: "504", label: "21 Days" },
  { value: "720", label: "30 Days" },
];

const RunTable = (props) => {
  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "score",
      text: "Score",
      sort: true,
    },
  ];

  return (
    <BootstrapTable
      keyField="beat_id"
      data={props.list}
      columns={tableColumns}
      bootstrap4
      striped
      bordered={false}
      noDataIndication={
        <div
          style={{ height: 100 }}
          className="d-flex align-items-center justify-content-center"
        >
          <span>
            Currently no run results. If you've clicked the Run button, please
            be patient. Executing a simulated run may take a few minutes to
            complete.
          </span>
        </div>
      }
    />
  );
};

const RecentActivity = (props) => {
  const [isRunLoading, setIsRunLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [runList, setRunList] = useState([]);

  const [reportFilter15, setReportFilter15] = useState(false);
  const [reportFilter50, setReportFilter50] = useState(false);
  const [reportFilter100, setReportFilter100] = useState(false);

  const [badgeFilter1, setBadgeFilter1] = useState(false);
  const [badgeFilter2, setBadgeFilter2] = useState(false);
  const [badgeFilter3, setBadgeFilter3] = useState(false);
  const [badgeFilter4, setBadgeFilter4] = useState(false);
  const [badgeFilter5, setBadgeFilter5] = useState(false);

  const [permissionFilter1, setPermissionFilter1] = useState(false);
  const [permissionFilter2, setPermissionFilter2] = useState(false);

  const [lyfUserFilter, setLyfUserFilter] = useState(false);
  const [badWordFilter, setBadWordFilter] = useState(false);

  const [maximumAge, setMaximumAge] = useState(options[0]);
  const [ageID, setAgeID] = useState(ageOptions[0]);
  const [sortID, setSortID] = useState(sortOptions[0]);
  const [scoreAge, setScoreAge] = useState(scoreAgeOptions[0]);
  const [formula, setFormula] = useState("{score}");

  const [scorePerPost, setScorePerPost] = useState("0");
  const [scorePerComment, setScorePerComment] = useState("0");
  const [scorePerSupport, setScorePerSupport] = useState("0");

  useEffect(() => {
    async function loadData() {
      var data = await dataService.algorithm(props.typeID);
      if (data) {
        var filter15 = data.filters.find((row) => {
          return row.filter_id === 15;
        });
        if (filter15) {
          setReportFilter15(true);
        }

        var filter50 = data.filters.find((row) => {
          return row.filter_id === 50;
        });
        if (filter50) {
          setReportFilter50(true);
        }

        var filter100 = data.filters.find((row) => {
          return row.filter_id === 100;
        });
        if (filter100) {
          setReportFilter100(true);
        }

        var match1 = data.badges.find((row) => {
          return row.badge_id === 1;
        });
        if (match1) {
          setBadgeFilter1(true);
        }

        var match2 = data.badges.find((row) => {
          return row.badge_id === 2;
        });
        if (match2) {
          setBadgeFilter2(true);
        }

        var match3 = data.badges.find((row) => {
          return row.badge_id === 3;
        });
        if (match3) {
          setBadgeFilter3(true);
        }

        var match4 = data.badges.find((row) => {
          return row.badge_id === 4;
        });
        if (match4) {
          setBadgeFilter4(true);
        }

        var match5 = data.badges.find((row) => {
          return row.badge_id === 5;
        });
        if (match5) {
          setBadgeFilter5(true);
        }

        setPermissionFilter1(data.public_flag === 1);
        setPermissionFilter2(data.anonymous_flag === 1);
        setLyfUserFilter(data.block_lyf_user_flag === 1);
        setBadWordFilter(data.block_banned_words_flag === 1);
        setFormula(data.formula);
        setScorePerPost("" + data.score_per_post);
        setScorePerComment("" + data.score_per_comment);
        setScorePerSupport("" + data.score_per_support);

        var maxMatch = options.find((row) => {
          return row.value === "" + data.maximum_age;
        });
        if (maxMatch) {
          setMaximumAge(maxMatch);
        }

        var ageMatch = ageOptions.find((row) => {
          return row.value === "" + data.age_id;
        });
        if (ageMatch) {
          setAgeID(ageMatch);
        }

        var sortMatch = sortOptions.find((row) => {
          return row.value === "" + data.sort_id;
        });
        if (sortMatch) {
          setSortID(sortMatch);
        }

        var scoreAgeMatch = scoreAgeOptions.find((row) => {
          return row.value === "" + data.score_age;
        });
        if (scoreAgeMatch) {
          setScoreAge(scoreAgeMatch);
        }
      }

      // load the category list
      var info = await dataService.categoryList(1, "", 1, 1000, "name", "asc");
      if (info && info.list) {
        setCategoryList(info.list);
      } else {
        setCategoryList([]);
      }

      // grab the list of selected categories
      data.categories.forEach((category) => {
        var match = info.list.find((row) => {
          return row.category_id === category.category_id;
        });

        if (match) {
          setSelectedCategoryList((selectedCategoryList) =>
            selectedCategoryList.concat(match)
          );
        }
      });

      // hide the loader
      setIsLoadingCategories(false);
    }

    loadData();
  }, [props]);

  function toggleSelectedCategory(e, categoryID) {
    if (e.target.checked === true) {
      var match = categoryList.find((row) => {
        return row.category_id === categoryID;
      });
      if (match) {
        setSelectedCategoryList((selectedCategoryList) =>
          selectedCategoryList.concat(match)
        );
      }
    } else {
      const matchIndex = selectedCategoryList.findIndex(function (category) {
        return category.category_id === categoryID;
      });
      if (matchIndex > -1) {
        var list = [].concat(selectedCategoryList);
        list.splice(matchIndex, 1);
        setSelectedCategoryList(list);
      }
    }
  }

  async function run() {
    setRunList([]);
    setIsRunLoading(true);

    var obj = constructObject();
    var records = await dataService.runAlgorithm(props.typeID, obj);
    setRunList(records);
    setIsRunLoading(false);
  }

  async function publish() {
    setIsLoading(true);

    var obj = constructObject();
    await dataService.saveAlgorithm(props.typeID, obj);
    setIsLoading(false);
  }

  function constructObject() {
    var obj = {
      public_flag: permissionFilter1 ? 1 : 0,
      anonymous_flag: permissionFilter2 ? 1 : 0,
      block_lyf_user_flag: lyfUserFilter ? 1 : 0,
      block_banned_words_flag: badWordFilter ? 1 : 0,
      maximum_age: parseInt(maximumAge.value),
      age_id: parseInt(ageID.value),
      sort_id: parseInt(sortID.value),
      formula: formula,
      filters: [],
      badges: [],
      categories: [],
      score_per_post: parseInt(scorePerPost),
      score_per_comment: parseInt(scorePerComment),
      score_per_support: parseInt(scorePerSupport),
      score_age: parseInt(scoreAge.value),
      //"scores": scoreList
    };

    if (reportFilter15) {
      obj.filters.push({ algorithm_id: props.typeID, filter_id: 15 });
    }
    if (reportFilter50) {
      obj.filters.push({ algorithm_id: props.typeID, filter_id: 50 });
    }
    if (reportFilter100) {
      obj.filters.push({ algorithm_id: props.typeID, filter_id: 100 });
    }

    if (badgeFilter1) {
      obj.badges.push({ algorithm_id: props.typeID, badge_id: 1 });
    }
    if (badgeFilter2) {
      obj.badges.push({ algorithm_id: props.typeID, badge_id: 2 });
    }
    if (badgeFilter3) {
      obj.badges.push({ algorithm_id: props.typeID, badge_id: 3 });
    }
    if (badgeFilter4) {
      obj.badges.push({ algorithm_id: props.typeID, badge_id: 4 });
    }
    if (badgeFilter5) {
      obj.badges.push({ algorithm_id: props.typeID, badge_id: 5 });
    }

    selectedCategoryList.forEach((category) => {
      obj.categories.push({
        algorithm_id: props.typeID,
        category_id: category.category_id,
      });
    });

    return obj;
  }

  return isLoadingCategories ? (
    <div className="d-flex align-items-center justify-content-center">
      <Spinner color="primary" />
    </div>
  ) : (
    <React.Fragment>
      <AvForm>
        <ListGroup flush>
          <ListGroupItem>
            <Row>
              <Col sm={3}>
                <h4>Excluded Report Levels</h4>
                <AvCheckboxGroup name={"reportFilter_" + props.typeID}>
                  <CustomInput
                    type="checkbox"
                    id={"reportFilter15_" + props.typeID}
                    label="Hide from Home"
                    checked={reportFilter15}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setReportFilter15(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"reportFilter50_" + props.typeID}
                    label="Hide from See All Experiences"
                    checked={reportFilter50}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setReportFilter50(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"reportFilter100_" + props.typeID}
                    label="Hide from Everywhere"
                    checked={reportFilter100}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setReportFilter100(e.target.checked)}
                  />
                </AvCheckboxGroup>
              </Col>
              <Col sm={3}>
                <h4>Included Badges</h4>
                <AvCheckboxGroup name={"badgeFilter_" + props.typeID}>
                  <CustomInput
                    type="checkbox"
                    id={"badgeFilter1_" + props.typeID}
                    label="Featured"
                    checked={badgeFilter1}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setBadgeFilter1(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"badgeFilter2_" + props.typeID}
                    label="Marketing"
                    checked={badgeFilter2}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setBadgeFilter2(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"badgeFilter3_" + props.typeID}
                    label="New Recommended"
                    checked={badgeFilter3}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setBadgeFilter3(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"badgeFilter4_" + props.typeID}
                    label="Recommended"
                    checked={badgeFilter4}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setBadgeFilter4(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"badgeFilter5_" + props.typeID}
                    label="Trending"
                    checked={badgeFilter5}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setBadgeFilter5(e.target.checked)}
                  />
                </AvCheckboxGroup>
              </Col>
              <Col sm={3}>
                <h4>Privacy Allowed</h4>
                <AvCheckboxGroup name={"permissionFilter_" + props.typeID}>
                  <CustomInput
                    type="checkbox"
                    id={"permissionFilter1_" + props.typeID}
                    label="Public"
                    checked={permissionFilter1}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setPermissionFilter1(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"permissionFilter2_" + props.typeID}
                    label="Public - Anonymous"
                    checked={permissionFilter2}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setPermissionFilter2(e.target.checked)}
                  />
                </AvCheckboxGroup>
                <h4>Exclusions</h4>
                <AvCheckboxGroup name={"exclusionFilter_" + props.typeID}>
                  <CustomInput
                    type="checkbox"
                    id={"exclusionFilter1_" + props.typeID}
                    label="Lyf User"
                    checked={lyfUserFilter}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setLyfUserFilter(e.target.checked)}
                  />
                  <CustomInput
                    type="checkbox"
                    id={"exclusionFilter2_" + props.typeID}
                    label="Contains Banned Words"
                    checked={badWordFilter}
                    disabled={isLoading || isRunLoading}
                    onChange={(e) => setBadWordFilter(e.target.checked)}
                  />
                </AvCheckboxGroup>
              </Col>
              <Col sm={3}>
                <h4>Maximum Age</h4>
                <FormGroup>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={options}
                    value={maximumAge}
                    onChange={(e) => setMaximumAge(e)}
                    isDisabled={isLoading || isRunLoading}
                  />
                </FormGroup>
                <h4>Age Filter</h4>
                <FormGroup>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={ageOptions}
                    value={ageID}
                    onChange={(e) => setAgeID(e)}
                    isDisabled={isLoading || isRunLoading}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ListGroupItem>
          {props.typeID !== 4 && (
            <ListGroupItem>
              <Row>
                <Col sm={12}>
                  <h4>Excluded Categories</h4>
                  <AvCheckboxGroup
                    name={"categoryFilter_" + props.typeID}
                    className="row ml-1 mr-1"
                  >
                    {categoryList.map(({ category_id, name }, index) => (
                      <CustomInput
                        type="checkbox"
                        key={
                          "categoryFilter" + category_id + "_" + props.typeID
                        }
                        id={"categoryFilter" + category_id + "_" + props.typeID}
                        label={name}
                        className="col-md-3 col-sm-4 col-xs-12 float-left"
                        checked={selectedCategoryList.some(
                          (row) => row.category_id === category_id
                        )}
                        disabled={isLoading || isRunLoading}
                        onChange={(e) => toggleSelectedCategory(e, category_id)}
                      />
                    ))}
                  </AvCheckboxGroup>
                </Col>
              </Row>
            </ListGroupItem>
          )}
          <ListGroupItem>
            <Row>
              <Col sm={3}>
                <h4>Score per Post</h4>
                <FormGroup>
                  <AvField
                    bsSize="lg"
                    type="text"
                    name="score_per_post"
                    id="score_per_post"
                    value={scorePerPost}
                    onChange={(e) => setScorePerPost(e.target.value)}
                    disabled={isLoading || isRunLoading}
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <h4>Score per Comment</h4>
                <FormGroup>
                  <AvField
                    bsSize="lg"
                    type="text"
                    name="score_per_comment"
                    id="score_per_comment"
                    value={scorePerComment}
                    onChange={(e) => setScorePerComment(e.target.value)}
                    disabled={isLoading || isRunLoading}
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <h4>Score per Support</h4>
                <FormGroup>
                  <AvField
                    bsSize="lg"
                    type="text"
                    name="score_per_support"
                    id="score_per_support"
                    value={scorePerSupport}
                    onChange={(e) => setScorePerSupport(e.target.value)}
                    disabled={isLoading || isRunLoading}
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <h4>Score Cut Off Time</h4>
                <FormGroup>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={scoreAgeOptions}
                    value={scoreAge}
                    onChange={(e) => setScoreAge(e)}
                    isDisabled={isLoading || isRunLoading}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <h4>Sort by Score Type</h4>
                <FormGroup>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={sortOptions}
                    value={sortID}
                    onChange={(e) => setSortID(e)}
                    isDisabled={isLoading || isRunLoading}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <h4>Formula Used</h4>
                <FormGroup>
                  <AvField
                    bsSize="lg"
                    type="textarea"
                    name="formula"
                    id="formula"
                    value={formula}
                    onChange={(e) => setFormula(e.target.value)}
                    disabled={isLoading || isRunLoading}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="text-center">
            <Button
              color="light"
              size="lg"
              className="mr-2"
              onClick={run}
              disabled={isLoading || isRunLoading}
            >
              {isRunLoading && (
                <Spinner size="sm" className="mr-2 align-middle" />
              )}
              Run
            </Button>
            <Button
              color="primary"
              size="lg"
              onClick={publish}
              disabled={isLoading || isRunLoading}
            >
              {isLoading && <Spinner size="sm" className="mr-2 align-middle" />}
              Publish
            </Button>
          </ListGroupItem>
          <ListGroupItem>
            <RunTable list={runList} />
          </ListGroupItem>
        </ListGroup>
      </AvForm>
    </React.Fragment>
  );
};

const Algorithm = () => {
  const [activeTab, setActiveTab] = useState("1");

  function toggle(tab_id) {
    setActiveTab(tab_id);
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Algorithm</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Algorithm</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Now Trending
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Recommended
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    New Experiences
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Category Default
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <RecentActivity typeID={1} />
                </TabPane>
                <TabPane tabId="2">
                  <RecentActivity typeID={2} />
                </TabPane>
                <TabPane tabId="3">
                  <RecentActivity typeID={3} />
                </TabPane>
                <TabPane tabId="4">
                  <RecentActivity typeID={4} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Algorithm;
