import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { useHistoryState } from "../../services/useHistoryState.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import classnames from "classnames";

import MemberListActive from "./MemberListActive";
import MemberListDeleted from "./MemberListDeleted";
import MemberListBlocked from "./MemberListBlocked";
import MemberListLyfSupport from "./MemberListLyfSupport";

const MemberList = (props) => {
  const [activeTab, setActiveTab] = useHistoryState(
    "memberList_activeTab",
    "1"
  );

  const [hasWritePermission, setHasWritePermission] = useState(false);

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);
  const navigate = useHistory();

  function toggle(tab) {
    setActiveTab(tab);
  }

  function performAdd() {
    navigate.push("/members/add");
  }

  useEffect(() => {
    let isCancelled = false;

    const loadData = async () => {
      if (!isCancelled) {
        var perm = getWritePermission();
        setHasWritePermission(perm);
      }
    };

    function getWritePermission() {
      var result = false;

      if (currentUser.permissions) {
        const matchIndex = currentUser.permissions.findIndex(function (row) {
          return row === "members_write";
        });
        if (matchIndex > -1) {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    }

    loadData();

    return () => {
      isCancelled = true;
    };
  });

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Members
          {hasWritePermission === true && (
            <Button
              color="success"
              className="float-right"
              onClick={performAdd}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New
            </Button>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Members</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Active Members
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Deleted Members
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Blocked Members
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Lyf Support Blocked Members
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <MemberListActive hasWritePermission={hasWritePermission} />
                </TabPane>
                <TabPane tabId="2">
                  <MemberListDeleted hasWritePermission={hasWritePermission} />
                </TabPane>
                <TabPane tabId="3">
                  <MemberListBlocked hasWritePermission={hasWritePermission} />
                </TabPane>
                <TabPane tabId="4">
                  <MemberListLyfSupport
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MemberList;
