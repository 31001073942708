import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import classnames from "classnames";

const dataService = require("../../services/dataServices.js");

const ReportingList = (props) => {
  const [viewActiveTab, setViewActiveTab] = useState("1");

  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState("report_count");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sizePerPage, setSizePerPage] = useState(10);

  const [currentReportsData, setCurrentReportsData] = useState([]);
  const [showReportsLoading, setShowReportsLoading] = useState(false);
  const [sortReportsField, setSortReportsField] = useState("created_date");
  const [sortReportsOrder, setSortReportsOrder] = useState("desc");
  const [showReportInfoLoading, setShowReportInfoLoading] = useState(false);
  const [infoObject, setInfoObject] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const tableActions = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    function ignoreRow() {
      setSelectedRow(row);
      setShowIgnoreModal(true);
    }

    async function viewRow() {
      setSelectedRow(row);
      setShowViewModal(true);
      loadViewData(row);
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={viewRow}>View</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={ignoreRow}>Ignore</DropdownItem>
          <DropdownItem onClick={deleteRow}>Delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const typeDisplay = (cell, row) => {
    if (cell === "beat") {
      return <React.Fragment>Experience</React.Fragment>;
    } else if (cell === "post") {
      return <React.Fragment>Post</React.Fragment>;
    } else if (cell === "comment") {
      return <React.Fragment>Comment</React.Fragment>;
    } else if (cell === "user") {
      return <React.Fragment>Member</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const tableColumns = [
    {
      dataField: "reporting_name",
      text: "User Reporting",
      sort: true,
    },
    {
      dataField: "reported_name",
      text: "User Reported",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type Reported",
      sort: true,
      formatter: typeDisplay,
    },
    {
      dataField: "report_count",
      text: "# of Reports",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const tableReportsColumns = [
    {
      dataField: "created_date",
      text: "Date",
      sort: true,
      formatter: dateDisplay,
    },
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "user_email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "text",
      text: "Report Description",
      sort: true,
    },
  ];

  async function loadReportsData(row, sort, order) {
    setShowReportInfoLoading(true);
    setShowReportsLoading(true);
    setCurrentReportsData([]);

    var info = await dataService.reportingDetailList(
      row.item_id,
      row.type,
      sort,
      order
    );

    setShowReportsLoading(false);
    setCurrentReportsData(info.list);

    // now load the detail
    if (row.type === "beat") {
      var obj1 = await dataService.experience(row.item_id);
      setInfoObject(obj1);
    } else if (row.type === "post") {
      var obj2 = await dataService.post(row.item_id);
      setInfoObject(obj2);
    } else if (row.type === "comment") {
      var obj3 = await dataService.comment(row.item_id);
      setInfoObject(obj3);
    } else if (row.type === "user") {
      var obj4 = await dataService.member(row.item_id);
      setInfoObject(obj4);
    }

    setShowReportInfoLoading(false);
  }

  async function loadData(page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.reportingList(page, pageSize, sort, order);

    if (info) {
      setTotalRecords(info.record_count);
      setShowLoading(false);
      setCurrentData(info.list);
    }
  }

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    await dataService.deleteReport(selectedRow.item_id, selectedRow.type);
    setShowDeleteModal(false);
    refreshGrids();
  }

  function toggleIgnore() {
    setShowIgnoreModal(!showIgnoreModal);
  }

  async function performIgnore() {
    await dataService.ignoreReport(selectedRow.item_id, selectedRow.type);
    setShowIgnoreModal(false);
    refreshGrids();
  }

  function toggleView() {
    setShowViewModal(!showViewModal);
  }

  function loadViewData(row) {
    setViewActiveTab("1");
    setSortReportsField("created_date");
    setSortReportsOrder("desc");
  }

  function pageChanged(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage) {
        // normally, we will need to make a call out
        setCurrentPage(1);
        setSizePerPage(newState.sizePerPage);
        loadData(1, newState.sizePerPage, sortField, sortOrder);
      } else if (newState.page !== currentPage) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage(newState.page);
        loadData(newState.page, sizePerPage, sortField, sortOrder);
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField) {
        setSortField(newState.sortField);
      } else if (newState.sortOrder !== sortOrder) {
        setSortOrder(newState.sortOrder);
      }
      setCurrentPage(1);

      loadData(1, sizePerPage, newState.sortField, newState.sortOrder);
    }
  }

  function pageReportsChanged(type, newState) {
    if (type === "sort") {
      if (newState.sortField !== sortReportsField) {
        setSortReportsField(newState.sortField);
      } else if (newState.sortOrder !== sortReportsOrder) {
        setSortReportsOrder(newState.sortOrder);
      }

      loadReportsData(selectedRow, newState.sortField, newState.sortOrder);
    }
  }

  function refreshGrids() {
    setCurrentPage(1);
    loadData(currentPage, sizePerPage, sortField, sortOrder);
  }

  function toggleViewTab(tab) {
    setViewActiveTab(tab);
  }

  const permissionDisplay = (row) => {
    if (row.permission_type_id === 1) {
      if (row.anonymous_avatar_id === 0) {
        return <React.Fragment>Public</React.Fragment>;
      } else {
        return <React.Fragment>Anonymous</React.Fragment>;
      }
    } else {
      return <React.Fragment>Private</React.Fragment>;
    }
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Content Reporting</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Content Reporting</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <BootstrapTable
                keyField="item_id"
                data={currentData}
                columns={tableColumns}
                sort={{ dataField: sortField, order: sortOrder }}
                bootstrap4
                striped
                wrapperClasses="table_wrapper"
                classes="table_class"
                remote={{ pagination: true, filter: true, sort: true }}
                bordered={false}
                pagination={paginationFactory({
                  sizePerPage: sizePerPage,
                  sizePerPageList: [5, 10, 25, 50],
                  page: currentPage,
                  totalSize: totalRecords,
                })}
                onTableChange={pageChanged}
                noDataIndication={() =>
                  showLoading ? (
                    <div
                      style={{ height: 250 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div
                      style={{ height: 250 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span>There are no records</span>
                    </div>
                  )
                }
              />
            </CardBody>
          </Card>
          <Modal
            key={"delete"}
            isOpen={showDeleteModal}
            toggle={() => toggleDelete()}
          >
            <ModalHeader toggle={() => toggleDelete()}>Delete</ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to delete this content?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleDelete()}>
                Cancel
              </Button>{" "}
              <Button color="danger" onClick={() => performDelete()}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            key={"ignore"}
            isOpen={showIgnoreModal}
            toggle={() => toggleIgnore()}
          >
            <ModalHeader toggle={() => toggleIgnore()}>
              Ignore Report
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to ignore this report?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleIgnore()}>
                Cancel
              </Button>{" "}
              <Button color="primary" onClick={() => performIgnore()}>
                Ignore Report
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            size="lg"
            key={"view"}
            isOpen={showViewModal}
            toggle={() => toggleView()}
          >
            <ModalHeader toggle={() => toggleView()}>
              <Nav pills className="card-header-pills ml-1">
                <NavItem>
                  <NavLink
                    className={classnames({ active: viewActiveTab === "1" })}
                    onClick={() => {
                      toggleViewTab("1");
                    }}
                  >
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: viewActiveTab === "2" })}
                    onClick={() => {
                      toggleViewTab("2");
                    }}
                  >
                    Reports
                  </NavLink>
                </NavItem>
              </Nav>
            </ModalHeader>
            <ModalBody className="m-2">
              <TabContent activeTab={viewActiveTab}>
                <TabPane tabId="1">
                  {showReportInfoLoading && (
                    <div
                      style={{ height: 250 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Spinner color="primary" />
                    </div>
                  )}
                  {!showReportInfoLoading &&
                    selectedRow &&
                    infoObject &&
                    selectedRow.type === "beat" && (
                      <React.Fragment>
                        {infoObject.banner_url &&
                          infoObject.banner_url !== "" && (
                            <div
                              style={{
                                background:
                                  "url(" +
                                  infoObject.banner_url +
                                  ") no-repeat center center fixed",
                                backgroundSize: "cover",
                                width: "100%",
                                height: "160px",
                              }}
                            />
                          )}
                        <Table size="sm" className="my-2">
                          <tbody>
                            <tr>
                              <th>Title</th>
                              <td>{infoObject.name}</td>
                            </tr>
                            <tr>
                              <th>Description</th>
                              <td>
                                {decodeURIComponent(
                                  escape(infoObject.description)
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Privacy</th>
                              <td>{permissionDisplay(infoObject)}</td>
                            </tr>
                            {infoObject.status_id === 1 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-success">
                                    Active
                                  </span>
                                </td>
                              </tr>
                            )}
                            {infoObject.status_id === 2 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-success">
                                    Completed
                                  </span>
                                </td>
                              </tr>
                            )}
                            {infoObject.status_id === 3 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-warning">
                                    Archived
                                  </span>
                                </td>
                              </tr>
                            )}
                            {infoObject.status_id === 4 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-danger">
                                    Deleted
                                  </span>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>
                                <Link
                                  to={
                                    "/content/experiences/edit/" +
                                    infoObject.beat_id
                                  }
                                >
                                  View Experience
                                </Link>
                              </th>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </React.Fragment>
                    )}
                  {!showReportInfoLoading &&
                    selectedRow &&
                    infoObject &&
                    selectedRow.type === "post" && (
                      <React.Fragment>
                        <Table size="sm" className="my-2">
                          <tbody>
                            <tr>
                              <th>User</th>
                              <td>{infoObject.creator_name}</td>
                            </tr>
                            <tr>
                              <th>Text</th>
                              <td>
                                {decodeURIComponent(escape(infoObject.text))}
                              </td>
                            </tr>
                            <tr>
                              <th># Comments</th>
                              <td>{infoObject.comment_count}</td>
                            </tr>
                            <tr>
                              <th>Created</th>
                              <td>
                                {new Date(
                                  infoObject.created_date
                                ).toLocaleString()}
                              </td>
                            </tr>
                            <tr>
                              <th>Privacy</th>
                              <td>{permissionDisplay(infoObject)}</td>
                            </tr>
                            {infoObject.status_id === 1 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-success">
                                    Active
                                  </span>
                                </td>
                              </tr>
                            )}
                            {infoObject.status_id === 2 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-danger">
                                    Deleted
                                  </span>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>
                                <Link
                                  to={
                                    "/content/experiences/edit/" +
                                    infoObject.beat_id
                                  }
                                >
                                  View Experience
                                </Link>
                              </th>
                              <td></td>
                            </tr>
                            <tr>
                              <th>
                                <Link
                                  to={
                                    "/content/posts/edit/" + infoObject.post_id
                                  }
                                >
                                  View Post
                                </Link>
                              </th>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </React.Fragment>
                    )}
                  {!showReportInfoLoading &&
                    selectedRow &&
                    infoObject &&
                    selectedRow.type === "comment" && (
                      <React.Fragment>
                        <Table size="sm" className="my-2">
                          <tbody>
                            <tr>
                              <th>User</th>
                              <td>{infoObject.creator_name}</td>
                            </tr>
                            <tr>
                              <th>Text</th>
                              <td>
                                {decodeURIComponent(escape(infoObject.text))}
                              </td>
                            </tr>
                            <tr>
                              <th>Created</th>
                              <td>
                                {new Date(
                                  infoObject.created_date
                                ).toLocaleString()}
                              </td>
                            </tr>
                            <tr>
                              <th>Privacy</th>
                              <td>{permissionDisplay(infoObject)}</td>
                            </tr>
                            <tr>
                              <th>
                                <Link
                                  to={
                                    "/content/experiences/edit/" +
                                    infoObject.beat_id
                                  }
                                >
                                  View Experience
                                </Link>
                              </th>
                              <td></td>
                            </tr>
                            <tr>
                              <th>
                                <Link
                                  to={
                                    "/content/comments/edit/" +
                                    infoObject.comment_id
                                  }
                                >
                                  View Comment
                                </Link>
                              </th>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </React.Fragment>
                    )}
                  {!showReportInfoLoading &&
                    selectedRow &&
                    infoObject &&
                    selectedRow.type === "user" && (
                      <React.Fragment>
                        <Table size="sm" className="my-2">
                          <tbody>
                            <tr>
                              <th>Email</th>
                              <td>{infoObject.email}</td>
                            </tr>
                            <tr>
                              <th>Name</th>
                              <td>{infoObject.name}</td>
                            </tr>
                            <tr>
                              <th>Display Name</th>
                              <td>{infoObject.display_name}</td>
                            </tr>
                            <tr>
                              <th>Location</th>
                              <td>{infoObject.location}</td>
                            </tr>
                            {infoObject.status_id === 1 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-success">
                                    Active
                                  </span>
                                </td>
                              </tr>
                            )}
                            {infoObject.status_id === 2 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-success">
                                    Disabled
                                  </span>
                                </td>
                              </tr>
                            )}
                            {infoObject.status_id === 3 && (
                              <tr>
                                <th>Status</th>
                                <td>
                                  <span className="badge badge-warning">
                                    Blocked
                                  </span>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>
                                <Link
                                  to={"/members/edit/" + infoObject.user_id}
                                >
                                  View Member
                                </Link>
                              </th>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </React.Fragment>
                    )}
                </TabPane>
                <TabPane tabId="2">
                  <BootstrapTable
                    keyField="report_id"
                    data={currentReportsData}
                    columns={tableReportsColumns}
                    sort={{
                      dataField: sortReportsField,
                      order: sortReportsOrder,
                    }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper"
                    classes="table_class"
                    remote={{ pagination: false, filter: false, sort: true }}
                    bordered={false}
                    onTableChange={pageReportsChanged}
                    noDataIndication={() =>
                      showReportsLoading ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
              </TabContent>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleView()}>
                Cancel
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default ReportingList;
