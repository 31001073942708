var Cookies = require("js-cookie");

module.exports = {
  setCookie: function (name, value, days) {
    return Cookies.set(name, value, { expires: days });
  },
  getCookie: function (name) {
    return Cookies.get(name);
  },
  eraseCookie: function (name) {
    return Cookies.remove(name);
  },
};
