import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistoryState } from "../../services/useHistoryState.js";

const dataService = require("../../services/dataServices.js");

const EditMemberPurchases = (props) => {
  const productLink = (cell, row) => {
    if (row.session_cost > 0) {
      return <span className="text-warning">{row.product.title}</span>;
    } else {
      return <React.Fragment>{row.product.title}</React.Fragment>;
    }
  };

  const costDisplay = (cell, row) => {
    if (row.session_cost > 0) {
      return <span className="text-warning">{row.session_cost}</span>;
    } else {
      return <React.Fragment>{row.session_credit}</React.Fragment>;
    }
  };

  const purchaseDateDisplay = (cell, row) => {
    if (row.session_cost > 0) {
      return (
        <span className="text-warning">
          {new Date(row.created_date).toLocaleString()}
        </span>
      );
    } else {
      return (
        <React.Fragment>
          {new Date(row.created_date).toLocaleString()}
        </React.Fragment>
      );
    }
  };

  const startDateDisplay = (cell, row) => {
    if (row.thread_created_date === null) {
      return <React.Fragment></React.Fragment>;
    } else {
      return (
        <React.Fragment>
          {new Date(row.thread_created_date).toLocaleString()}
        </React.Fragment>
      );
    }
  };

  const endDateDisplay = (cell, row) => {
    if (row.thread_expiration_date === null) {
      return <React.Fragment></React.Fragment>;
    } else {
      return (
        <React.Fragment>
          {new Date(row.thread_expiration_date).toLocaleString()}
        </React.Fragment>
      );
    }
  };

  const statusDisplay = (cell, row) => {
    if (row.thread_status_id === 1) {
      return <React.Fragment>Active</React.Fragment>;
    } else if (row.thread_status_id === 2) {
      return <React.Fragment>Queued</React.Fragment>;
    } else if (row.thread_status_id === 3) {
      return <React.Fragment>Closed</React.Fragment>;
    } else if (row.thread_status_id === 3) {
      return <React.Fragment>Cancelled</React.Fragment>;
    } else {
      return <React.Fragment>Unknown</React.Fragment>;
    }
  };

  const [currentPage, setCurrentPage] = useHistoryState(
    "editMemberPurchases_currentPage",
    1
  );
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useHistoryState(
    "editMemberPurchases_sortField",
    "purchase_created_date"
  );
  const [sortOrder, setSortOrder] = useHistoryState(
    "editMemberPurchases_sortOrder",
    "desc"
  );
  const [sizePerPage, setSizePerPage] = useHistoryState(
    "editMemberPurchases_sizePerPage",
    10
  );
  const [giftCredits, setGiftCredits] = useState("");
  const [showAddGiftModal, setShowAddGiftModal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const tableColumns = [
    {
      dataField: "purchase_id",
      text: "ID",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "product_title",
      text: "Product",
      sort: true,
      formatter: productLink,
    },
    {
      dataField: "purchase_session_credit",
      text: "Sessions Amount",
      sort: true,
      formatter: costDisplay,
    },
    {
      dataField: "purchase_created_date",
      text: "Purchase Date",
      sort: true,
      formatter: purchaseDateDisplay,
    },
    {
      dataField: "thread_created_date",
      text: "Session Start Date",
      sort: true,
      formatter: startDateDisplay,
    },
    {
      dataField: "thread_expiration_date",
      text: "Session End Date",
      sort: true,
      formatter: endDateDisplay,
    },
    {
      dataField: "thread_status_id",
      text: "Session Status",
      sort: true,
      formatter: statusDisplay,
    },
    {
      dataField: "thread_note",
      text: "Note",
      sort: true,
    },
  ];

  function toggleAddGift() {
    setGiftCredits("");
    setShowAddGiftModal(!showAddGiftModal);
  }

  async function performAddGift() {
    // add das gift yo
    await dataService.memberAddGift(props.user_id, giftCredits);

    // done!
    setShowAddGiftModal(false);
    setCurrentPage(1);
    loadData(1, sizePerPage, sortField, sortOrder);
  }

  async function loadData(page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.memberPurchaseList(
      props.user_id,
      page,
      pageSize,
      sort,
      order
    );
    if (info.record_count !== undefined) {
      setTotalRecords(info.record_count);
      setShowLoading(false);
      setCurrentData(info.list);
    }
  }

  function pageChanged(type, newState) {
    if (firstLoad === true) {
      setFirstLoad(false);
      loadData(currentPage, sizePerPage, sortField, sortOrder);
    } else {
      if (type === "pagination") {
        if (newState.sizePerPage !== sizePerPage) {
          // normally, we will need to make a call out
          setCurrentPage(1);
          setSizePerPage(newState.sizePerPage);
          loadData(1, newState.sizePerPage, sortField, sortOrder);
        } else if (newState.page !== currentPage) {
          // normally, we will need to make a call out to get the passed page of data
          setCurrentPage(newState.page);
          loadData(newState.page, sizePerPage, sortField, sortOrder);
        }
      } else if (type === "sort") {
        // normally, we will need to make a call out

        if (newState.sortField !== sortField) {
          setSortField(newState.sortField);
        } else if (newState.sortOrder !== sortOrder) {
          setSortOrder(newState.sortOrder);
        }

        setCurrentPage(1);
        loadData(1, sizePerPage, newState.sortField, newState.sortOrder);
      }
    }
  }

  useEffect(() => {
    async function reloadData() {
      // show spinner
      setShowLoading(true);
      setCurrentData([]);

      // load data
      var info = await dataService.memberPurchaseList(
        props.user_id,
        1,
        10,
        "purchase_created_date",
        "desc"
      );
      if (info.record_count !== undefined) {
        setTotalRecords(info.record_count);
        setShowLoading(false);
        setCurrentData(info.list);
      }
    }

    if (firstLoad === false) {
      reloadData();
    }
  }, [props.user_id, firstLoad]);

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col sm={3} className="align-self-center">
          <Row>
            <Col sm={6} className="text-right">
              <strong>Current Sessions Available</strong>
            </Col>
            <Col sm={6}>{props.sessionsAvailable}</Col>
          </Row>
        </Col>
        <Col sm={3} className="align-self-center">
          <Row>
            <Col sm={6} className="text-right">
              <strong>Total Sessions Purchased</strong>
            </Col>
            <Col sm={6}>{props.totalPurchased}</Col>
          </Row>
        </Col>
        <Col sm={3} className="align-self-center">
          <Row>
            <Col sm={6} className="text-right">
              <strong>Sessions Used</strong>
            </Col>
            <Col sm={6}>{props.totalUsed}</Col>
          </Row>
        </Col>
        <Col sm={3} className="align-self-center text-right">
          <Button color="primary" size="md" onClick={toggleAddGift}>
            Add Gift Sessions
          </Button>
        </Col>
      </Row>
      <BootstrapTable
        keyField="purchase_id"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"addGift"}
        isOpen={showAddGiftModal}
        toggle={() => toggleAddGift()}
      >
        <ModalHeader toggle={() => toggleAddGift()}>
          Add Gift Sessions
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-3">
            Enter an amount of sessions you wish to gift this member.
          </p>
          <AvForm>
            <Row>
              <Col>
                <AvGroup>
                  <AvField
                    bsSize="lg"
                    type="text"
                    name="credits"
                    id="credits"
                    placeholder="Enter number of sessions"
                    value={giftCredits}
                    onChange={(e) => setGiftCredits(e.target.value)}
                  />
                </AvGroup>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleAddGift()}>
            Cancel
          </Button>{" "}
          <Button color="success" onClick={() => performAddGift()}>
            Add Gift
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditMemberPurchases;
