import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  CustomInput,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import ImageUploader from "react-images-upload";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const dataService = require("../../services/dataServices.js");

const AddUser = () => {
  const navigate = useHistory();
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatarImage, setAvatarImage] = useState([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const [selectedRoleList, setSelectedRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function save() {
    setIsLoading(true);
    var obj = await constructObject();
    var result = await dataService.addUser(obj);
    showToastr(result.result.user_id);
  }

  function showToastr(user_id) {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        navigate.push("/admin/users/edit/" + user_id);
        setIsLoading(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Add User",
      "Your changes have been saved successfully.",
      options
    );
  }

  async function constructObject() {
    var aString = avatarImage.length > 0 ? await toBase64(avatarImage[0]) : "";

    var obj = {
      email: email,
      phone: mobileNumber,
      first_name: firstName,
      last_name: lastName,
      avatar: aString,
      roles: [],
    };

    selectedRoleList.forEach((role) => {
      obj.roles.push({ role_id: role.role_id, name: role.name });
    });

    return obj;
  }

  function performCancel() {
    navigate.goBack();
  }

  async function onDrop(picture) {
    setAvatarImage(picture);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    async function loadData() {
      // load the category list
      var info = await dataService.roleList(1, 1000, "name", "asc");
      if (info && info.list) {
        setRoleList(info.list);
      } else {
        setRoleList([]);
      }

      // hide the loader
      setIsLoadingRoles(false);
    }
    console.log("LOAD");
    loadData();
  }, []);

  function toggleSelectedRole(e, roleID) {
    if (e.target.checked === true) {
      var match = roleList.find((row) => {
        return row.role_id === roleID;
      });
      if (match) {
        setSelectedRoleList((selectedRoleList) =>
          selectedRoleList.concat(match)
        );
      }
    } else {
      const matchIndex = selectedRoleList.findIndex(function (role_id) {
        return role_id === roleID;
      });
      if (matchIndex > -1) {
        var list = [].concat(selectedRoleList);
        list.splice(matchIndex, 1);
        setSelectedRoleList(list);
      }
    }
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add User</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/users/list">Admin Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add User</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col>
                    <ImageUploader
                      withIcon={false}
                      withLabel={false}
                      buttonText="Choose avatar"
                      onChange={onDrop}
                      imgExtension={[".jpeg", ".jpg", ".png"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      withPreview={true}
                      buttonClassName="btn btn-primary"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="email">Email</Label>
                      <AvField
                        bsSize="lg"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        disabled={isLoading}
                        onChange={(e) => setEmail(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter an email.",
                          },
                          email: {
                            value: true,
                            errorMessage: "Please enter a valid email.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="mobileNumber">Mobile Number</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="mobileNumber"
                        id="mobileNumber"
                        placeholder="Enter mobile number"
                        value={mobileNumber}
                        disabled={isLoading}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a mobile number.",
                          },
                          tel: {
                            value: true,
                            errorMessage: "Please enter a valid mobile number.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="first_name">First Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="Enter first name"
                        value={firstName}
                        disabled={isLoading}
                        onChange={(e) => setFirstName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a first name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="last_name">Last Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Enter last name"
                        value={lastName}
                        disabled={isLoading}
                        onChange={(e) => setLastName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a last name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Roles</h4>
                    {isLoadingRoles && (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner color="primary" />
                      </div>
                    )}
                    {!isLoadingRoles && (
                      <AvCheckboxGroup
                        name={"roleID"}
                        className="row ml-1 mr-1"
                      >
                        {roleList.map(({ role_id, name }, index) => (
                          <CustomInput
                            type="checkbox"
                            key={"role" + role_id}
                            id={"role" + role_id}
                            label={name}
                            className="col-md-3 col-sm-4 col-xs-12 float-left"
                            checked={selectedRoleList.some(
                              (row) => row.role_id === role_id
                            )}
                            disabled={isLoading}
                            onChange={(e) => toggleSelectedRole(e, role_id)}
                          />
                        ))}
                      </AvCheckboxGroup>
                    )}
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading || isLoadingRoles}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="lg"
                    disabled={isLoading || isLoadingRoles}
                  >
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddUser;
