import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStore } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  FormGroup,
  CardImg,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { faL } from "@fortawesome/free-solid-svg-icons";

const dataService = require("../../services/dataServices.js");

const options = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
];
const typeOptions = [
  {
    value: "0",
    label:
      "Psychologist Partnership (QR Tracking, Toggle, Transcript Transfer, Revenue Tracking)",
  },
  { value: "1", label: "Revenue Partnership (QR Tracking, Revenue Tracking)" },
];
const EditPartnership = () => {
  let { partnership_id } = useParams();
  const navigate = useHistory();
  const [name, setName] = useState("");
  const [sortBy, setSortBy] = useState(options[0]);
  const [color, setColor] = useState("#000000");
  const [email, setEmail] = useState("");
  const [typePartnership, setTypePartnership] = useState(typeOptions[0]);
  const [logo, setLogo] = useState("");
  const [logoName, setLogoName] = useState("");
  const [qrImage, setQrImage] = useState("");
  const [qrLink, setQrLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [description, setDescrption] = useState("");
  const [website, setWebsite] = useState("");
  const [links, setLinks] = useState([]);
  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);

  async function constructObject() {
    var obj = {
      partnership_id: partnership_id,
      partnership_name: name,
      sort_rank: parseInt(sortBy.value),
      color: color,
      partnership_email: email,
      type: parseInt(typePartnership.value),
      logo: logo,
      website: website,
      description: description,
      links: links,
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);
    setIsDisabled(true);

    var obj = await constructObject();
    await dataService.updatePartnership(obj);

    showToastr();
  }

  function performCancel() {
    navigate.push("/system-content/partnerships/");
  }

  useEffect(() => {
    async function loadData() {
      // load the role
      var data = await dataService.partnership(partnership_id);

      setName(data.partnership_name);
      setEmail(data.partnership_email);
      setColor(data.color);
      setTypePartnership(typeOptions[data.type]);
      setLogo(data.logo);
      setLogoName("logo");
      setQrImage(data.qr_code.replace("image/svg+xml", "image/png"));
      setQrLink(data.link);
      setEmailDisabled(data.type === 1 ? true : false);
      setWebsite(data.website);
      setDescrption(data.description);
      setLinks(data.links);
      var sortMatch = options.find((row) => {
        return row.value === "" + data.sort_rank;
      });
      if (sortMatch) {
        setSortBy(sortMatch);
      }

      setIsLoading(false);
      setIsDisabled(!hasWritePermission());
    }

    function hasWritePermission() {
      var result = false;

      if (currentUser.permissions) {
        const matchIndex = currentUser.permissions.findIndex(function (row) {
          return row === "categories_write";
        });
        if (matchIndex > -1) {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    }

    loadData();
  }, []);

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        setIsLoading(false);
        setIsDisabled(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Edit Partnership",
      "Your changes have been saved successfully.",
      options
    );
  }
  function readFile(file) {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader();

      // Register event listeners
      reader.addEventListener("loadend", (e) => resolve(e.target.result));
      reader.addEventListener("error", reject);

      // Read file
      reader.readAsDataURL(file);
    });
  }
  async function readLogo(input) {
    if (input.files && input.files[0]) {
      var result = await readFile(input.files[0]);
      setLogo(result);
      setLogoName(input.files[0].name);
    }
  }
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Edit Partnership</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/system-content/partnerships">Partnerships</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Partnership</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col sm={4}>
                    <AvGroup>
                      <Label for="name">Name</Label>
                      <AvField
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        value={name}
                        disabled={isDisabled}
                        onChange={(e) => setName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={4}>
                    <Label for="sort_by">Sort Rank</Label>
                    <FormGroup>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={options}
                        value={sortBy}
                        onChange={(e) => setSortBy(e)}
                        isDisabled={isDisabled}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <AvGroup>
                      <Label for="colour">Colour</Label>
                      <AvField
                        type="color"
                        name="colour"
                        id="colour"
                        placeholder="Enter colour"
                        value={color}
                        disabled={isDisabled}
                        onChange={(e) => setColor(e.target.value)}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Label for="type">Type</Label>
                    <FormGroup>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={typeOptions}
                        value={typePartnership}
                        onChange={(e) => {
                          setTypePartnership(e);
                          setEmailDisabled(e.value === "1" ? true : false);
                        }}
                        isDisabled={isDisabled}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <AvGroup>
                      <Label for="email" disabled={emailDisabled}>
                        Email Address to send transcript
                      </Label>
                      <AvField
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        disabled={isDisabled || emailDisabled}
                        onChange={(e) => setEmail(e.target.value)}
                        validate={{
                          required: {
                            value: isDisabled || emailDisabled ? false : true,
                            errorMessage: "Please enter an email.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={2}>
                    <div>
                      <Label for="logoFile"></Label>
                      <div className="d-flex justify-content-center">
                        <div className="btn btn-primary btn-rounded">
                          <label
                            className="form-label text-white m-1"
                            for="logoFile"
                          >
                            Choose logo
                            {logoName === "" ? "" : " (" + logoName + ")"}
                          </label>
                          <input
                            type="file"
                            className="form-control d-none"
                            id="logoFile"
                            accept="image/svg+xml"
                            disabled={isDisabled}
                            onChange={(e) => readLogo(e.target)}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={2} className="align-self-center">
                    <Label for="logo"></Label>
                    <FormGroup className="align-middle">
                      <img
                        alt={logoName}
                        src={logo}
                        style={{ backgroundColor: color }}
                        className="img-fluid rounded-circle"
                        width="60"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <AvGroup>
                      <Label for="website" disabled={isDisabled}>
                        Website
                      </Label>
                      <AvField
                        type="text"
                        name="website"
                        id="website"
                        placeholder="Enter website"
                        value={website}
                        disabled={isDisabled}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <AvGroup>
                      <Label for="description" disabled={isDisabled}>
                        Text
                      </Label>
                      <AvField
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="Enter text"
                        value={description}
                        disabled={isDisabled}
                        onChange={(e) => setDescrption(e.target.value)}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                {links.map((link, index) => (
                  <Row className="align-items-center">
                    <Col sm={4}>
                      <AvGroup>
                        <Label for={"link" + index} disabled={isDisabled}>
                          Text with link
                        </Label>
                        <AvField
                          type="text"
                          name={"link" + index}
                          id={"link" + index}
                          placeholder="Enter text"
                          value={link.description}
                          disabled={isDisabled}
                          onChange={(e) => {
                            links[index].description = e.target.value;
                            setLinks(links);
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={4}>
                      <AvGroup>
                        <Label for={"url" + index} disabled={isDisabled}>
                          Link URL
                        </Label>
                        <AvField
                          type="text"
                          name={"url" + index}
                          id={"url" + index}
                          placeholder="Enter url"
                          value={link.link}
                          disabled={isDisabled}
                          onChange={(e) => {
                            links[index].link = e.target.value;
                            setLinks(links);
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <Button
                        color="secondary"
                        size="sm"
                        className="mt-3"
                        style={{ backgroundColor: "#ff474c" }}
                        onClick={() => {
                          links.splice(index, 1);
                          setLinks([...links]);
                        }}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="mt-3 mb-5">
                  <Button
                    color="secondary"
                    size="md"
                    onClick={() => {
                      links.push({ id: "", description: "", link: "" });
                      setLinks([...links]);
                    }}
                    disabled={isLoading}
                  >
                    Add Link
                  </Button>
                </div>
                <Row className="align-items-center justify-content-center mt-3">
                  <Col sm={3}>
                    <Card>
                      <CardImg alt={qrLink} src={qrImage} width="100%" />
                    </Card>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isDisabled}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditPartnership;
