import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const dataService = require("../../services/dataServices.js");

const AddRole = () => {
  const navigate = useHistory();
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [permissionList, setPermissionList] = useState([]);

  async function save() {
    setIsLoading(true);

    var result = await dataService.addRole(name, permissionList);

    showToastr(result.role_id);
  }

  function performCancel() {
    navigate.goBack();
  }

  function removePermission(key) {
    const matchIndex = permissionList.findIndex(function (key_value) {
      return key_value === key;
    });
    if (matchIndex > -1) {
      var list = [].concat(permissionList);
      list.splice(matchIndex, 1);
      setPermissionList(list);
    }
  }

  function togglePermission(e, key) {
    e.persist();

    if (key === "permission_members") {
      removePermission("members_read");
      removePermission("members_write");
    } else if (key === "permission_experiences") {
      removePermission("experiences_read");
      removePermission("experiences_write");
    } else if (key === "permission_posts") {
      removePermission("posts_read");
      removePermission("posts_write");
    } else if (key === "permission_comments") {
      removePermission("comments_read");
      removePermission("comments_write");
    } else if (key === "permission_content_reports") {
      removePermission("content_reports_read");
    } else if (key === "permission_contactus") {
      removePermission("contactus_read");
      removePermission("contactus_write");
    } else if (key === "permission_algorithm") {
      removePermission("algorithm_read");
      removePermission("algorithm_write");
    } else if (key === "permission_documents") {
      removePermission("documents_read");
      removePermission("documents_write");
    } else if (key === "permission_categories") {
      removePermission("categories_read");
      removePermission("categories_write");
    } else if (key === "permission_quotes") {
      removePermission("quotes_read");
      removePermission("quotes_write");
    } else if (key === "permission_banned_words") {
      removePermission("banned_words_read");
      removePermission("banned_words_write");
    } else if (key === "permission_help") {
      removePermission("help_read");
      removePermission("help_write");
    } else if (key === "permission_promo_codes") {
      removePermission("promo_codes_read");
      removePermission("promo_codes_write");
    } else if (key === "permission_member_subscriptions") {
      removePermission("member_subscriptions_read");
      removePermission("member_subscriptions_write");
    } else if (key === "permission_subscriptions") {
      removePermission("subscriptions_read");
      removePermission("subscriptions_write");
    } else if (key === "permission_purchases") {
      removePermission("purchases_read");
    } else if (key === "permission_products") {
      removePermission("products_read");
      removePermission("products_write");
    } else if (key === "permission_admin_users") {
      removePermission("admin_users_read");
      removePermission("admin_users_write");
    } else if (key === "permission_admin_roles") {
      removePermission("admin_roles_read");
      removePermission("admin_roles_write");
    } else if (key === "permission_reports_1") {
      removePermission("admin_reports_1_execute");
    } else if (key === "permission_reports_2") {
      removePermission("admin_reports_2_execute");
    } else if (key === "permission_reports_3") {
      removePermission("admin_reports_3_execute");
    } else if (key === "permission_reports_adhoc") {
      removePermission("admin_reports_adhoc_execute");
      removePermission("admin_reports_adhoc_write");
    }

    if (e.target.value !== "") {
      setPermissionList((permissions) => permissions.concat(e.target.value));
    }
  }

  function showToastr(role_id) {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        navigate.push("/admin/roles/edit/" + role_id);
        setIsLoading(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Add Role",
      "Your changes have been saved successfully.",
      options
    );
  }

  function matchingValue(input_name) {
    if (input_name === "permission_members") {
      if (
        permissionList.find((row) => {
          return row === "members_read";
        })
      ) {
        return "members_read";
      }
      if (
        permissionList.find((row) => {
          return row === "members_write";
        })
      ) {
        return "members_write";
      }
    }

    if (input_name === "permission_experiences") {
      if (
        permissionList.find((row) => {
          return row === "experiences_read";
        })
      ) {
        return "experiences_read";
      }
      if (
        permissionList.find((row) => {
          return row === "experiences_write";
        })
      ) {
        return "experiences_write";
      }
    }

    if (input_name === "permission_posts") {
      if (
        permissionList.find((row) => {
          return row === "posts_read";
        })
      ) {
        return "posts_read";
      }
      if (
        permissionList.find((row) => {
          return row === "posts_write";
        })
      ) {
        return "posts_write";
      }
    }

    if (input_name === "permission_comments") {
      if (
        permissionList.find((row) => {
          return row === "comments_read";
        })
      ) {
        return "comments_read";
      }
      if (
        permissionList.find((row) => {
          return row === "comments_write";
        })
      ) {
        return "comments_write";
      }
    }

    if (input_name === "permission_content_reports") {
      if (
        permissionList.find((row) => {
          return row === "content_reports_read";
        })
      ) {
        return "content_reports_read";
      }
    }

    if (input_name === "permission_contactus") {
      if (
        permissionList.find((row) => {
          return row === "contactus_read";
        })
      ) {
        return "contactus_read";
      }
      if (
        permissionList.find((row) => {
          return row === "contactus_write";
        })
      ) {
        return "contactus_write";
      }
    }

    if (input_name === "permission_algorithm") {
      if (
        permissionList.find((row) => {
          return row === "algorithm_read";
        })
      ) {
        return "algorithm_read";
      }
      if (
        permissionList.find((row) => {
          return row === "algorithm_write";
        })
      ) {
        return "algorithm_write";
      }
    }

    if (input_name === "permission_documents") {
      if (
        permissionList.find((row) => {
          return row === "documents_read";
        })
      ) {
        return "documents_read";
      }
      if (
        permissionList.find((row) => {
          return row === "documents_write";
        })
      ) {
        return "documents_write";
      }
    }

    if (input_name === "permission_categories") {
      if (
        permissionList.find((row) => {
          return row === "categories_read";
        })
      ) {
        return "categories_read";
      }
      if (
        permissionList.find((row) => {
          return row === "categories_write";
        })
      ) {
        return "categories_write";
      }
    }

    if (input_name === "permission_quotes") {
      if (
        permissionList.find((row) => {
          return row === "quotes_read";
        })
      ) {
        return "quotes_read";
      }
      if (
        permissionList.find((row) => {
          return row === "quotes_write";
        })
      ) {
        return "quotes_write";
      }
    }

    if (input_name === "permission_banned_words") {
      if (
        permissionList.find((row) => {
          return row === "banned_words_read";
        })
      ) {
        return "banned_words_read";
      }
      if (
        permissionList.find((row) => {
          return row === "banned_words_write";
        })
      ) {
        return "banned_words_write";
      }
    }

    if (input_name === "permission_help") {
      if (
        permissionList.find((row) => {
          return row === "help_read";
        })
      ) {
        return "help_read";
      }
      if (
        permissionList.find((row) => {
          return row === "help_write";
        })
      ) {
        return "help_write";
      }
    }

    if (input_name === "permission_promo_codes") {
      if (
        permissionList.find((row) => {
          return row === "promo_codes_read";
        })
      ) {
        return "promo_codes_read";
      }
      if (
        permissionList.find((row) => {
          return row === "promo_codes_write";
        })
      ) {
        return "promo_codes_write";
      }
    }

    if (input_name === "permission_member_subscriptions") {
      if (
        permissionList.find((row) => {
          return row === "member_subscriptions_read";
        })
      ) {
        return "member_subscriptions_read";
      }
      if (
        permissionList.find((row) => {
          return row === "member_subscriptions_write";
        })
      ) {
        return "member_subscriptions_write";
      }
    }

    if (input_name === "permission_subscriptions") {
      if (
        permissionList.find((row) => {
          return row === "subscriptions_read";
        })
      ) {
        return "subscriptions_read";
      }
      if (
        permissionList.find((row) => {
          return row === "subscriptions_write";
        })
      ) {
        return "subscriptions_write";
      }
    }

    if (input_name === "permission_purchases") {
      if (
        permissionList.find((row) => {
          return row === "purchases_read";
        })
      ) {
        return "purchases_read";
      }
    }

    if (input_name === "permission_products") {
      if (
        permissionList.find((row) => {
          return row === "products_read";
        })
      ) {
        return "products_read";
      }
      if (
        permissionList.find((row) => {
          return row === "products_write";
        })
      ) {
        return "products_write";
      }
    }

    if (input_name === "permission_admin_users") {
      if (
        permissionList.find((row) => {
          return row === "admin_users_read";
        })
      ) {
        return "admin_users_read";
      }
      if (
        permissionList.find((row) => {
          return row === "admin_users_write";
        })
      ) {
        return "admin_users_write";
      }
    }

    if (input_name === "permission_admin_roles") {
      if (
        permissionList.find((row) => {
          return row === "admin_roles_read";
        })
      ) {
        return "admin_roles_read";
      }
      if (
        permissionList.find((row) => {
          return row === "admin_roles_write";
        })
      ) {
        return "admin_roles_write";
      }
    }

    if (input_name === "permission_reports_1") {
      if (
        permissionList.find((row) => {
          return row === "admin_reports_1_execute";
        })
      ) {
        return "admin_reports_1_execute";
      }
    }

    if (input_name === "permission_reports_2") {
      if (
        permissionList.find((row) => {
          return row === "admin_reports_2_execute";
        })
      ) {
        return "admin_reports_2_execute";
      }
    }

    if (input_name === "permission_reports_3") {
      if (
        permissionList.find((row) => {
          return row === "admin_reports_3_execute";
        })
      ) {
        return "admin_reports_3_execute";
      }
    }

    if (input_name === "permission_reports_adhoc") {
      if (
        permissionList.find((row) => {
          return row === "admin_reports_adhoc_execute";
        })
      ) {
        return "admin_reports_adhoc_execute";
      }
      if (
        permissionList.find((row) => {
          return row === "admin_reports_adhoc_write";
        })
      ) {
        return "admin_reports_adhoc_write";
      }
    }

    return "";
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add Role</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/roles">Roles</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Role</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="name">Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={4}>
                    <h4>Members</h4>
                    <AvRadioGroup
                      name="permission_members"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_members")}
                      onChange={(e) =>
                        togglePermission(e, "permission_members")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="members_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="members_write"
                      />
                    </AvRadioGroup>
                    <h4>Comments</h4>
                    <AvRadioGroup
                      name="permission_comments"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_comments")}
                      onChange={(e) =>
                        togglePermission(e, "permission_comments")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="comments_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="comments_write"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Experiences</h4>
                    <AvRadioGroup
                      name="permission_experiences"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_experiences")}
                      onChange={(e) =>
                        togglePermission(e, "permission_experiences")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="experiences_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="experiences_write"
                      />
                    </AvRadioGroup>
                    <h4>Content Reporting</h4>
                    <AvRadioGroup
                      name="permission_content_reports"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_content_reports")}
                      onChange={(e) =>
                        togglePermission(e, "permission_content_reports")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read"
                        value="content_reports_read"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Posts</h4>
                    <AvRadioGroup
                      name="permission_posts"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_posts")}
                      onChange={(e) => togglePermission(e, "permission_posts")}
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="posts_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="posts_write"
                      />
                    </AvRadioGroup>
                    <h4>Contact Us</h4>
                    <AvRadioGroup
                      name="permission_contactus"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_contactus")}
                      onChange={(e) =>
                        togglePermission(e, "permission_contactus")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="contactus_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="contactus_write"
                      />
                    </AvRadioGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={4}>
                    <h4>Categories</h4>
                    <AvRadioGroup
                      name="permission_categories"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_categories")}
                      onChange={(e) =>
                        togglePermission(e, "permission_categories")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="categories_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="categories_write"
                      />
                    </AvRadioGroup>
                    <h4>Quotes</h4>
                    <AvRadioGroup
                      name="permission_quotes"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_quotes")}
                      onChange={(e) => togglePermission(e, "permission_quotes")}
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="quotes_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="quotes_write"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Banned Words</h4>
                    <AvRadioGroup
                      name="permission_banned_words"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_banned_words")}
                      onChange={(e) =>
                        togglePermission(e, "permission_banned_words")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="banned_words_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="banned_words_write"
                      />
                    </AvRadioGroup>
                    <h4>Help / FAQ</h4>
                    <AvRadioGroup
                      name="permission_help"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_help")}
                      onChange={(e) => togglePermission(e, "permission_help")}
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="help_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="help_write"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Algorithm</h4>
                    <AvRadioGroup
                      name="permission_algorithm"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_algorithm")}
                      onChange={(e) =>
                        togglePermission(e, "permission_algorithm")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="algorithm_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="algorithm_write"
                      />
                    </AvRadioGroup>
                    <h4>Documents</h4>
                    <AvRadioGroup
                      name="permission_documents"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_documents")}
                      onChange={(e) =>
                        togglePermission(e, "permission_documents")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="documents_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="documents_write"
                      />
                    </AvRadioGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={4}>
                    <h4>Promo Codes</h4>
                    <AvRadioGroup
                      name="permission_promo_codes"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_promo_codes")}
                      onChange={(e) =>
                        togglePermission(e, "permission_promo_codes")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="promo_codes_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="promo_codes_write"
                      />
                    </AvRadioGroup>
                    <h4>Member Subscriptions</h4>
                    <AvRadioGroup
                      name="permission_member_subscriptions"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_member_subscriptions")}
                      onChange={(e) =>
                        togglePermission(e, "permission_member_subscriptions")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read"
                        value="member_subscriptions_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="member_subscriptions_write"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Subscriptions</h4>
                    <AvRadioGroup
                      name="permission_subscriptions"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_subscriptions")}
                      onChange={(e) =>
                        togglePermission(e, "permission_subscriptions")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="subscriptions_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="subscriptions_write"
                      />
                    </AvRadioGroup>
                    <h4>Member Purchases</h4>
                    <AvRadioGroup
                      name="permission_purchases"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_purchases")}
                      onChange={(e) =>
                        togglePermission(e, "permission_purchases")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read"
                        value="purchases_read"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Products</h4>
                    <AvRadioGroup
                      name="permission_products"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_products")}
                      onChange={(e) =>
                        togglePermission(e, "permission_products")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio customInput label="Read" value="products_read" />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="products_write"
                      />
                    </AvRadioGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={4}>
                    <h4>Admin Users</h4>
                    <AvRadioGroup
                      name="permission_admin_users"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_admin_users")}
                      onChange={(e) =>
                        togglePermission(e, "permission_admin_users")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="admin_users_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="admin_users_write"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Roles</h4>
                    <AvRadioGroup
                      name="permission_admin_roles"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_admin_roles")}
                      onChange={(e) =>
                        togglePermission(e, "permission_admin_roles")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Read Only"
                        value="admin_roles_read"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="admin_roles_write"
                      />
                    </AvRadioGroup>
                  </Col>
                  <Col sm={4}>
                    <h4>Reporting - Report 1</h4>
                    <AvRadioGroup
                      name="permission_reports_1"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_reports_1")}
                      onChange={(e) =>
                        togglePermission(e, "permission_reports_1")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Execute"
                        value="admin_reports_1_execute"
                      />
                    </AvRadioGroup>
                    <h4>Reporting - Report 2</h4>
                    <AvRadioGroup
                      name="permission_reports_2"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_reports_2")}
                      onChange={(e) =>
                        togglePermission(e, "permission_reports_2")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Execute"
                        value="admin_reports_2_execute"
                      />
                    </AvRadioGroup>
                    <h4>Reporting - Report 3</h4>
                    <AvRadioGroup
                      name="permission_reports_3"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_reports_3")}
                      onChange={(e) =>
                        togglePermission(e, "permission_reports_3")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Execute"
                        value="admin_reports_3_execute"
                      />
                    </AvRadioGroup>
                    <h4>Reporting - Ad-hoc</h4>
                    <AvRadioGroup
                      name="permission_reports_adhoc"
                      errorMessage="Pick one!"
                      value={matchingValue("permission_reports_adhoc")}
                      onChange={(e) =>
                        togglePermission(e, "permission_reports_adhoc")
                      }
                      disabled={isLoading}
                    >
                      <AvRadio customInput label="No Access" value="" />
                      <AvRadio
                        customInput
                        label="Execute Only"
                        value="admin_reports_adhoc_execute"
                      />
                      <AvRadio
                        customInput
                        label="Read / Write"
                        value="admin_reports_adhoc_write"
                      />
                    </AvRadioGroup>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddRole;
