import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const dataService = require("../../services/dataServices.js");

const QuoteList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState("text");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sizePerPage, setSizePerPage] = useState(10);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);
  const navigate = useHistory();

  function hasWritePermission() {
    var result = false;

    if (currentUser.permissions) {
      const matchIndex = currentUser.permissions.findIndex(function (row) {
        return row === "quotes_write";
      });
      if (matchIndex > -1) {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  function performAdd() {
    navigate.push("/system-content/quotes/add");
  }

  const tableActions = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    function editRow() {
      performEdit(row.quote_id);
    }

    if (hasWritePermission()) {
      return (
        <UncontrolledButtonDropdown
          key={row.user_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={editRow}>Edit</DropdownItem>
            <DropdownItem onClick={deleteRow}>Delete</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    } else {
      return (
        <UncontrolledButtonDropdown
          key={row.user_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={editRow}>View</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    }
  };

  const tableColumns = [
    {
      dataField: "text",
      text: "Text",
      sort: true,
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  async function loadData(page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.quoteList(page, pageSize, sort, order);

    setTotalRecords(info.record_count);
    setShowLoading(false);
    setCurrentData(info.list);
  }

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    await dataService.deleteQuote(selectedRow.quote_id);
    setShowDeleteModal(false);
    refreshGrids();
  }

  function performEdit(quote_id) {
    navigate.push("/system-content/quotes/edit/" + quote_id);
  }

  function pageChanged(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage) {
        // normally, we will need to make a call out
        setCurrentPage(1);
        setSizePerPage(newState.sizePerPage);
        loadData(1, newState.sizePerPage, sortField, sortOrder);
      } else if (newState.page !== currentPage) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage(newState.page);
        loadData(newState.page, sizePerPage, sortField, sortOrder);
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField) {
        setSortField(newState.sortField);
      } else if (newState.sortOrder !== sortOrder) {
        setSortOrder(newState.sortOrder);
      }
      setCurrentPage(1);

      loadData(1, sizePerPage, newState.sortField, newState.sortOrder);
    }
  }

  function refreshGrids() {
    setCurrentPage(1);
    loadData(currentPage, sizePerPage, sortField, sortOrder);
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Quotes
          {hasWritePermission() && (
            <Button
              color="success"
              className="float-right"
              onClick={performAdd}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New
            </Button>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Quotes</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <BootstrapTable
                keyField="quote_id"
                data={currentData}
                columns={tableColumns}
                sort={{ dataField: sortField, order: sortOrder }}
                bootstrap4
                striped
                wrapperClasses="table_wrapper"
                classes="table_class"
                remote={{ pagination: true, filter: true, sort: true }}
                bordered={false}
                pagination={paginationFactory({
                  sizePerPage: sizePerPage,
                  sizePerPageList: [5, 10, 25, 50],
                  page: currentPage,
                  totalSize: totalRecords,
                })}
                onTableChange={pageChanged}
                noDataIndication={() =>
                  showLoading ? (
                    <div
                      style={{ height: 250 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div
                      style={{ height: 250 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span>There are no records</span>
                    </div>
                  )
                }
              />
            </CardBody>
          </Card>
          <Modal
            key={"delete"}
            isOpen={showDeleteModal}
            toggle={() => toggleDelete()}
          >
            <ModalHeader toggle={() => toggleDelete()}>
              Delete Quote
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to delete this quote?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleDelete()}>
                Cancel
              </Button>{" "}
              <Button color="warning" onClick={() => performDelete()}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default QuoteList;
