import React, { useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Label,
  CustomInput,
  Spinner,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { authenticate } from "../../redux/actions/authenticationActions";
import avatar from "../../assets/img/avatars/lyf_large.png";

const authService = require("../../services/authService.js");
const CookieController = require("../../services/CookieController.js");

const SignIn = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(
    CookieController.getCookie("last_email") ?? ""
  );
  const [password, setPassword] = useState(
    CookieController.getCookie("last_password") ?? ""
  );
  const [rememberMe, setRememberMe] = useState(
    CookieController.getCookie("last_email") ?? "xxx" !== ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordError, setPasswordError] = useState(
    "Please enter a password."
  );

  store.subscribe(() => {
    const currentAuth = store.getState().authentication.isAuthenticated;
    localStorage.setItem("authenticated", currentAuth);
    setIsAuthenticated(currentAuth);
  });

  async function attemptLogin(e) {
    setPasswordError("Please enter a password.");
    e.preventDefault();

    if (email !== "" && password !== "") {
      setIsLoading(true);

      var user = await authService.startSessionForUser(email, password);
      if (user && user !== undefined && user !== null) {
        if (user.user_id !== undefined) {
          if (rememberMe) {
            await CookieController.setCookie("last_email", email, 30);
            await CookieController.setCookie("last_password", password, 30);
          } else {
            await CookieController.setCookie("last_email", "", 30);
            await CookieController.setCookie("last_password", "", 30);
          }

          setIsLoading(false);
          dispatch(authenticate(user));
        } else {
          // login failed
          setIsLoading(false);
          setPasswordError("Email and/or Password is incorrect.");
          setPassword("");
        }
      } else {
        // unexpected error
        setIsLoading(false);
        setPasswordError("An unexpected error occurred.  Try again.");
        setPassword("");
      }
    }
  }

  if (isAuthenticated === true) {
    const currentUser =
      store.getState().authentication.user ??
      (localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user"))
        : null);

    if (currentUser.must_change_password_flag === 1) {
      return (
        <Redirect
          to={{
            pathname: "/auth/change-password",
            state: { from: "/auth/sign-in" },
          }}
        />
      );
    } else {
      return (
        <Redirect to={{ pathname: "/", state: { from: "/auth/sign-in" } }} />
      );
    }
  } else {
    return (
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={avatar}
                alt="Lyf"
                className="img-fluid"
                width="120"
                height="120"
              />
            </div>
            <AvForm onValidSubmit={attemptLogin}>
              <AvGroup>
                <Label for="email">Email</Label>
                <AvField
                  bsSize="lg"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter an email.",
                    },
                    email: {
                      value: true,
                      errorMessage: "Please enter a valid email.",
                    },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label for="password">Password</Label>
                <AvField
                  bsSize="lg"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  validate={{
                    required: { value: true, errorMessage: passwordError },
                  }}
                />
                <small>
                  <Link to="/auth/reset-password">Forgot password?</Link>
                </small>
              </AvGroup>
              <div>
                <CustomInput
                  type="checkbox"
                  id="rememberMe"
                  label="Remember me next time"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(!rememberMe)}
                />
              </div>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" disabled={isLoading}>
                  {isLoading && (
                    <Spinner size="sm" className="mr-2 align-middle" />
                  )}
                  Sign in
                </Button>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    );
  }
};

export default SignIn;
