// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_RIGHT_ENABLE = "SIDEBAR_RIGHT_ENABLE";
export const SIDEBAR_RIGHT_DISABLE = "SIDEBAR_RIGHT_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Authentication
export const IS_AUTHENTICATED_TOGGLE = "IS_AUTHENTICATED_TOGGLE";
export const IS_AUTHENTICATED_TRUE = "IS_AUTHENTICATED_TRUE";
export const IS_AUTHENTICATED_FALSE = "IS_AUTHENTICATED_FALSE";
