import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./redux/store/index";
import Routes from "./routes/Routes";
import { ThemeProvider, createTheme } from "@mui/material/styles";

class App extends React.Component {
  componentDidMount() {
    try {
      // Remove `active` className from `.splash` element in `public/index.html`
      !document.querySelector(".splash") ||
        document.querySelector(".splash").classList.remove("active");
    } catch {}
  }

  render() {
    const THEME = createTheme({
      typography: {
        fontFamily:
          '"Hind Vadodara", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
      },
    });
    return (
      <ThemeProvider theme={THEME}>
        <Provider store={store}>
          <Routes />
          <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
