import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  Input,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import ImageUploader from "react-images-upload";
import DateTime from "react-datetime";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { toastr } from "react-redux-toastr";

const dataService = require("../../services/dataServices.js");

const AddMember = () => {
  const navigate = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [location, setLocation] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [advice, setAdvice] = useState("");
  const [memory, setMemory] = useState("");
  const [lesson, setLesson] = useState("");
  const [avatarImage, setAvatarImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("Please enter an email.");

  async function constructObject() {
    var aString = avatarImage.length > 0 ? await toBase64(avatarImage[0]) : "";

    var obj = {
      email: email,
      password: password,
      name: name,
      display_name: displayName,
      location: location,
      birth_date: birthDate ? birthDate.getTime() : 0,
      advice: advice,
      memory: memory,
      lesson: lesson,
      avatar: aString,
      remove_avatar_flag: 0,
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);

    // ok, save the object
    var obj = await constructObject();
    var result = await dataService.addMember(obj);

    if (result.status === "success") {
      showToastr(result.result.user_id);
    } else {
      setEmailError(result.result);
      setEmail("");
    }

    setIsLoading(false);
  }

  function performCancel() {
    navigate.goBack();
  }

  function updateEmail(e) {
    setEmailError("Please enter an email.");
    setEmail(e);
  }

  async function onDrop(picture) {
    setAvatarImage(picture);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function showToastr(user_id) {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        navigate.push("/members/edit/" + user_id);
        setIsLoading(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Add Member",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add Member</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/members/list">Members</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Member</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col>
                    <ImageUploader
                      withIcon={false}
                      withLabel={false}
                      buttonText="Choose avatar"
                      onChange={onDrop}
                      imgExtension={[".jpeg", ".jpg", ".png"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      withPreview={true}
                      buttonClassName="btn btn-primary"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="email">Email</Label>
                      <AvField
                        bsSize="lg"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        disabled={isLoading}
                        onChange={(e) => updateEmail(e.target.value)}
                        validate={{
                          required: { value: true, errorMessage: emailError },
                          email: {
                            value: true,
                            errorMessage: "Please enter a valid email.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="password">Password</Label>
                      <AvField
                        bsSize="lg"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter password"
                        value={password}
                        disabled={isLoading}
                        onChange={(e) => setPassword(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a password.",
                          },
                          minLength: {
                            value: 8,
                            errorMessage:
                              "Password must be at least 8 characters long.",
                          },
                          pattern: {
                            value: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{1,}$",
                            errorMessage:
                              "Must contain at least 1 capital, 1 lower case, and 1 number.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="name">Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter a name"
                        value={name}
                        disabled={isLoading}
                        onChange={(e) => setName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="display_name">Display Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="display_name"
                        id="display_name"
                        placeholder="Enter a display name"
                        value={displayName}
                        disabled={isLoading}
                        onChange={(e) => setDisplayName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a display name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="location">Country</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="location"
                        id="location"
                        placeholder="Enter a location"
                        value={location}
                        disabled={isLoading}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <Label for="start_date">Birth Date</Label>
                    <DateTime
                      input={true}
                      value={birthDate}
                      timeFormat={false}
                      inputProps={{
                        placeholder: "Birth date",
                        disabled: isLoading,
                        className: "form-control form-control-lg",
                      }}
                      closeOnSelect={true}
                      onChange={(e) => setBirthDate(e._d)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Label for="advice">Advice</Label>
                    <Input
                      type="textarea"
                      name="advice"
                      placeholder="Advice"
                      value={advice}
                      disabled={isLoading}
                      onChange={(e) => setAdvice(e.target.value)}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="advice">Memory</Label>
                    <Input
                      type="textarea"
                      name="memory"
                      placeholder="Memory"
                      value={memory}
                      disabled={isLoading}
                      onChange={(e) => setMemory(e.target.value)}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="advice">Lesson</Label>
                    <Input
                      type="textarea"
                      name="lesson"
                      placeholder="Lesson"
                      value={lesson}
                      disabled={isLoading}
                      onChange={(e) => setLesson(e.target.value)}
                    />
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddMember;
