import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  CustomInput,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const dataService = require("../../services/dataServices.js");

const AddQuote = () => {
  const navigate = useHistory();
  const [text, setText] = useState("");
  const [source, setSource] = useState("");
  const [active, setActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  async function constructObject() {
    var obj = {
      text: text,
      source: source,
      active_flag: active === true ? 1 : 0,
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);
    var obj = await constructObject();
    var result = await dataService.addQuote(obj);

    showToastr(result.result.quote_id);
  }

  function performCancel() {
    navigate.goBack();
  }

  function showToastr(quote_id) {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        navigate.push("/system-content/quotes/edit/" + quote_id);
        setIsLoading(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Add Topic",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add Quote</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/system-content/quotes">Quotes</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Quote</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="text">Text</Label>
                      <AvField
                        bsSize="lg"
                        type="textarea"
                        name="text"
                        id="text"
                        placeholder="Enter text"
                        value={text}
                        disabled={isLoading}
                        rows="4"
                        onChange={(e) => setText(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter the text.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="source">Source</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="source"
                        id="source"
                        placeholder="Enter source"
                        value={source}
                        disabled={isLoading}
                        onChange={(e) => setSource(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a source.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <Label for="active">Active?</Label>
                    <AvCheckboxGroup name={"active"}>
                      <CustomInput
                        type="checkbox"
                        id={"active"}
                        label="Active"
                        checked={active}
                        disabled={isLoading}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    </AvCheckboxGroup>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddQuote;
