import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStore } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  CustomInput,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import ImageUploader from "react-images-upload";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const dataService = require("../../services/dataServices.js");

const EditUser = () => {
  let { user_id } = useParams();
  const navigate = useHistory();
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatarImage, setAvatarImage] = useState([]);
  const [defaultAvatar, setDefaultAvatar] = useState([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const [selectedRoleList, setSelectedRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);

  async function save() {
    setIsLoading(true);
    setIsDisabled(true);

    var obj = await constructObject();
    await dataService.updateUser(obj);

    showToastr();
  }

  function performCancel() {
    navigate.push("/admin/users/");
  }

  useEffect(() => {
    async function loadData() {
      // load the role
      var data = await dataService.user(user_id);

      setEmail(data.email);
      setMobileNumber(data.mobile_number);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      if (data.roles) {
        setSelectedRoleList(data.roles);
      }
      if (data.avatar_url !== "" && data.avatar_url !== null) {
        setDefaultAvatar([data.avatar_url]);
      }

      var info = await dataService.roleList(1, 1000, "name", "asc");
      if (info && info.list) {
        setRoleList(info.list);
      } else {
        setRoleList([]);
      }

      // hide the loader
      setIsLoading(false);
      setIsLoadingRoles(false);
      setIsDisabled(!hasWritePermission());
    }

    function hasWritePermission() {
      var result = false;

      if (currentUser.permissions) {
        const matchIndex = currentUser.permissions.findIndex(function (row) {
          return row === "admin_users_write";
        });
        if (matchIndex > -1) {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    }

    loadData();
  }, [user_id, currentUser]);

  async function constructObject() {
    var aString = avatarImage.length > 0 ? await toBase64(avatarImage[0]) : "";

    var obj = {
      user_id: user_id,
      email: email,
      phone: mobileNumber,
      first_name: firstName,
      last_name: lastName,
      avatar: aString,
      roles: [],
    };

    selectedRoleList.forEach((role) => {
      obj.roles.push({ role_id: role.role_id, name: role.name });
    });

    return obj;
  }

  async function onDrop(picture) {
    console.log(picture[0].name);
    setAvatarImage(picture);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function toggleSelectedRole(e, roleID) {
    if (e.target.checked === true) {
      var match = roleList.find((row) => {
        return row.role_id === roleID;
      });
      if (match) {
        setSelectedRoleList((selectedRoleList) =>
          selectedRoleList.concat(match)
        );
      }
    } else {
      const matchIndex = selectedRoleList.findIndex(function (role_id) {
        return role_id === roleID;
      });
      if (matchIndex > -1) {
        var list = [].concat(selectedRoleList);
        list.splice(matchIndex, 1);
        setSelectedRoleList(list);
      }
    }
  }

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        setIsLoading(false);
        setIsDisabled(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Edit User",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Edit User</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/users">Admin Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit User</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col>
                    <ImageUploader
                      withIcon={false}
                      withLabel={false}
                      buttonText="Choose avatar"
                      onChange={onDrop}
                      defaultImages={defaultAvatar}
                      imgExtension={[".jpeg", ".jpg", ".png"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      withPreview={true}
                      buttonClassName="btn btn-primary"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="email">Email</Label>
                      <AvField
                        bsSize="lg"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        disabled={isDisabled}
                        onChange={(e) => setEmail(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter an email.",
                          },
                          email: {
                            value: true,
                            errorMessage: "Please enter a valid email.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="mobileNumber">Mobile Number</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="mobileNumber"
                        id="mobileNumber"
                        placeholder="Enter mobile number"
                        value={mobileNumber}
                        disabled={isLoading}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a mobile number.",
                          },
                          tel: {
                            value: true,
                            errorMessage: "Please enter a valid mobile number.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="first_name">First Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="Enter first name"
                        value={firstName}
                        disabled={isDisabled}
                        onChange={(e) => setFirstName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a first name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="last_name">Last Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Enter last name"
                        value={lastName}
                        disabled={isDisabled}
                        onChange={(e) => setLastName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a last name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Roles</h4>
                    {isLoadingRoles && (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner color="primary" />
                      </div>
                    )}
                    {!isLoadingRoles && (
                      <AvCheckboxGroup
                        name={"roleID"}
                        className="row ml-1 mr-1"
                      >
                        {roleList.map(({ role_id, name }, index) => (
                          <CustomInput
                            type="checkbox"
                            key={"role" + role_id}
                            id={"role" + role_id}
                            label={name}
                            className="col-md-3 col-sm-4 col-xs-12 float-left"
                            disabled={isDisabled}
                            checked={selectedRoleList.some(
                              (row) => row.role_id === role_id
                            )}
                            onChange={(e) => toggleSelectedRole(e, role_id)}
                          />
                        ))}
                      </AvCheckboxGroup>
                    )}
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading || isLoadingRoles}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isDisabled}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditUser;
