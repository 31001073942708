import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const dataService = require("../../services/dataServices.js");

const RoleTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sizePerPage, setSizePerPage] = useState(10);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);
  const navigate = useHistory();

  const tableActions = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    function editRow() {
      performEdit(row.role_id);
    }

    function hasWritePermission() {
      var result = false;

      if (currentUser.permissions) {
        const matchIndex = currentUser.permissions.findIndex(function (row) {
          return row === "admin_roles_write";
        });
        if (matchIndex > -1) {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    }

    if (hasWritePermission()) {
      return (
        <UncontrolledButtonDropdown
          key={row.user_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={editRow}>Edit</DropdownItem>
            <DropdownItem onClick={deleteRow}>Delete</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    } else {
      return (
        <UncontrolledButtonDropdown
          key={row.user_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={editRow}>View</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    }
  };

  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  async function loadData(page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.roleList(page, pageSize, sort, order);

    setTotalRecords(info.record_count);
    setShowLoading(false);
    setCurrentData(info.list);
  }

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    // delete the row
    await dataService.deleteRole(selectedRow.role_id);

    // reset the screen
    setSelectedRow(null);
    setShowDeleteModal(false);
    loadData(1, sizePerPage, sortField, sortOrder);
  }

  function performEdit(role_id) {
    navigate.push("/admin/roles/edit/" + role_id);
  }

  function pageChanged(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage) {
        // normally, we will need to make a call out
        setCurrentPage(1);
        setSizePerPage(newState.sizePerPage);
        loadData(1, newState.sizePerPage, sortField, sortOrder);
      } else if (newState.page !== currentPage) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage(newState.page);
        loadData(newState.page, sizePerPage, sortField, sortOrder);
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField) {
        setSortField(newState.sortField);
      } else if (newState.sortOrder !== sortOrder) {
        setSortOrder(newState.sortOrder);
      }
      setCurrentPage(1);

      loadData(1, sizePerPage, newState.sortField, newState.sortOrder);
    }
  }

  return (
    <React.Fragment>
      <Modal
        key={"delete_" + props.statusID}
        isOpen={showDeleteModal}
        toggle={() => toggleDelete()}
      >
        <ModalHeader toggle={() => toggleDelete()}>
          Delete Role{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to delete this role? Your changes can not be
            undone.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDelete()}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => performDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <BootstrapTable
        keyField="role_id"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
    </React.Fragment>
  );
};

const RoleList = () => {
  const navigate = useHistory();
  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);

  function performAdd() {
    navigate.push("/admin/roles/add");
  }

  function hasWritePermission() {
    var result = false;

    if (currentUser.permissions) {
      const matchIndex = currentUser.permissions.findIndex(function (row) {
        return row === "admin_roles_write";
      });
      if (matchIndex > -1) {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Roles
          {hasWritePermission() && (
            <Button
              color="success"
              className="float-right"
              onClick={performAdd}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New
            </Button>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Roles</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <RoleTable />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RoleList;
