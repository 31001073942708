import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Spinner, Row, Col } from "reactstrap";

const dataService = require("../../services/dataServices.js");

const RetentionSteps = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      var dta = await dataService.retentionSteps();
      setData(dta);
      setIsLoading(false);
    }

    loadData();
  }, []);

  async function getData() {
    setIsLoading(true);
    var dta = await dataService.retentionSteps();
    setData(dta);
    setIsLoading(false);
  }

  function getAlpha(week, cellNumber) {
    if (cellNumber === 1) {
      if (week.week_number >= 2) {
        return week.week_1_pct < 5 ? 0.05 : week.week_1_pct / 100;
      }
    } else if (cellNumber === 2) {
      if (week.week_number >= 3) {
        return week.week_2_pct < 5 ? 0.05 : week.week_2_pct / 100;
      }
    } else if (cellNumber === 3) {
      if (week.week_number >= 4) {
        return week.week_3_pct < 5 ? 0.05 : week.week_3_pct / 100;
      }
    } else if (cellNumber === 4) {
      if (week.week_number >= 5) {
        return week.week_4_pct < 5 ? 0.05 : week.week_4_pct / 100;
      }
    } else if (cellNumber === 5) {
      if (week.week_number >= 6) {
        return week.week_5_pct < 5 ? 0.05 : week.week_5_pct / 100;
      }
    }

    return 0.0;
  }

  function getColor(week, cellNumber) {
    var alpha = getAlpha(week, cellNumber);
    if (alpha < 0.33) {
      return "#666666";
    }

    return "#FFFFFF";
  }

  function getAverage(weekNumber) {
    if (data.length > 0) {
      var total = 0.0;
      var count = 6;

      data.forEach(function (value, index) {
        if (weekNumber === 1) {
          total = total + value.week_1_pct;
          count = 5;
        } else if (weekNumber === 2) {
          total = total + value.week_2_pct;
          count = 4;
        } else if (weekNumber === 3) {
          total = total + value.week_3_pct;
          count = 3;
        } else if (weekNumber === 4) {
          total = total + value.week_4_pct;
          count = 2;
        } else if (weekNumber === 5) {
          total = total + value.week_5_pct;
          count = 1;
        }
      });

      if (weekNumber === 0) {
        return 100.0;
      } else {
        return Math.floor(10 * (total / count)) / 10;
      }
    }

    return 0.0;
  }

  return (
    <Card className="flex-fill w-100">
      <CardBody className="py-3">
        {isLoading && (
          <div
            style={{ height: 400 }}
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner size="sm" className="mr-2 align-middle" />
          </div>
        )}
        {!isLoading && (
          <React.Fragment>
            <Row className="border-bottom">
              <Col sm={0} md={3} className="d-none d-md-block"></Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>
                  <strong>Week 0</strong>
                </small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>
                  <strong>Week 1</strong>
                </small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>
                  <strong>Week 2</strong>
                </small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>
                  <strong>Week 3</strong>
                </small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>
                  <strong>Week 4</strong>
                </small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>
                  <strong>Week 5</strong>
                </small>
              </Col>
              <Col sm={0} md={3} className="d-none d-md-block"></Col>
            </Row>
            <Row className="border-bottom">
              <Col sm={0} md={3} className="d-none d-md-block"></Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>{getAverage(0)}%</small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>{getAverage(1)}%</small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>{getAverage(2)}%</small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>{getAverage(3)}%</small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>{getAverage(4)}%</small>
              </Col>
              <Col xs={2} sm={2} md={1} className="text-center">
                <small>{getAverage(5)}%</small>
              </Col>
              <Col sm={0} md={3} className="d-none d-md-block"></Col>
            </Row>
            {data.map((week, index) => (
              <Row className="border-bottom justify-content-center">
                <Col sm={0} md={3} className="d-none d-md-block">
                  <h6 className="mt-1 mb-0">{week.title}</h6>
                  <div className="mt-0 mb-1">
                    <small>{week.user_count} Users</small>
                  </div>
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  style={{ backgroundColor: "rgba(2, 101, 110, 1.0)" }}
                  className="d-flex align-items-center justify-content-center pt-1 pb-1"
                >
                  <small style={{ color: "#FFFFFF" }}>100.0%</small>
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  style={{
                    backgroundColor:
                      "rgba(2, 101, 110, " + getAlpha(week, 1) + ")",
                  }}
                  className="d-flex align-items-center justify-content-center pt-1 pb-1"
                >
                  {week.week_number >= 2 && (
                    <small style={{ color: getColor(week, 1) }}>
                      {week.week_1_pct}%
                    </small>
                  )}
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  style={{
                    backgroundColor:
                      "rgba(2, 101, 110, " + getAlpha(week, 2) + ")",
                  }}
                  className="d-flex align-items-center justify-content-center pt-1 pb-1"
                >
                  {week.week_number >= 3 && (
                    <small style={{ color: getColor(week, 2) }}>
                      {week.week_2_pct}%
                    </small>
                  )}
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  style={{
                    backgroundColor:
                      "rgba(2, 101, 110, " + getAlpha(week, 3) + ")",
                  }}
                  className="d-flex align-items-center justify-content-center pt-1 pb-1"
                >
                  {week.week_number >= 4 && (
                    <small style={{ color: getColor(week, 3) }}>
                      {week.week_3_pct}%
                    </small>
                  )}
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  style={{
                    backgroundColor:
                      "rgba(2, 101, 110, " + getAlpha(week, 4) + ")",
                  }}
                  className="d-flex align-items-center justify-content-center pt-1 pb-1"
                >
                  {week.week_number >= 5 && (
                    <small style={{ color: getColor(week, 4) }}>
                      {week.week_4_pct}%
                    </small>
                  )}
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  style={{
                    backgroundColor:
                      "rgba(2, 101, 110, " + getAlpha(week, 5) + ")",
                  }}
                  className="d-flex align-items-center justify-content-center pt-1 pb-1"
                >
                  {week.week_number >= 6 && (
                    <small style={{ color: getColor(week, 5) }}>
                      {week.week_5_pct}%
                    </small>
                  )}
                </Col>
                <Col sm={0} md={3} className="d-none d-md-block"></Col>
              </Row>
            ))}
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(RetentionSteps);
