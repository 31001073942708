import * as types from "../constants";

export function toggleAuthentication() {
  return {
    type: types.IS_AUTHENTICATED_TOGGLE,
  };
}

export function authenticate(user) {
  localStorage.setItem("user", JSON.stringify(user));

  return {
    type: types.IS_AUTHENTICATED_TRUE,
    user: user,
  };
}

export function deauthenticate() {
  localStorage.clear();

  return {
    type: types.IS_AUTHENTICATED_FALSE,
  };
}
