import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  Label,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const AddExperience = () => {
  const navigate = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function save() {
    setIsLoading(true);
  }

  function performCancel() {
    navigate.goBack();
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add Member</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/members/list">Members</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Member</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader></CardHeader>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="email">Email</Label>
                      <AvField
                        bsSize="lg"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter an email.",
                          },
                          email: {
                            value: true,
                            errorMessage: "Please enter a valid email.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="password">Password</Label>
                      <AvField
                        bsSize="lg"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a password.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddExperience;
