import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import { Button, Card, CardBody, Label, Spinner } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

const dataService = require("../../services/dataServices.js");
const CookieController = require("../../services/CookieController.js");

var Base64 = require("js-base64").Base64;

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(
    "Please confirm your password."
  );
  const navigate = useHistory();
  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);

  async function attemptReset(e) {
    e.preventDefault();

    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        setPasswordError("Confirmation password does not match.");
        setConfirmPassword("");
      } else {
        setIsLoading(true);
        await dataService.changePassword(currentUser.user_id, password);
        setIsLoading(false);

        // update the API cookie
        const encodedAuthString = Base64.encode(
          currentUser.email + ":" + password
        );
        const finalAuthHeader = "Admin " + encodedAuthString;
        CookieController.setCookie("basiccookie", finalAuthHeader, 1);

        navigate.push("/dashboard");
      }
    }
  }

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Change password</h1>
        <p className="lead">Enter a new password below.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <AvForm onValidSubmit={attemptReset}>
              <AvGroup>
                <Label>New Password</Label>
                <AvField
                  bsSize="lg"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a password.",
                    },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label>Confirm New Password</Label>
                <AvField
                  bsSize="lg"
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  validate={{
                    required: { value: true, errorMessage: passwordError },
                    match: {
                      value: "password",
                      errorMessage: "Confirmation password does not match.",
                    },
                  }}
                />
              </AvGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" disabled={isLoading}>
                  {isLoading && (
                    <Spinner size="sm" className="mr-2 align-middle" />
                  )}
                  Change Password
                </Button>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ChangePassword;
