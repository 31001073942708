import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { CSVLink } from "react-csv";
const dataService = require("../../services/dataServices.js");
const CustomerSpendReport = (props) => {
  const [dateRange, setDateRange] = useState([
    new DateObject().subtract(7, "day"),
    new DateObject(),
  ]);
  const [searchText, setSearchText] = useState("");
  const tableColumns1 = [
    {
      dataField: "user_id",
      text: "Customer Number",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (!isNaN(cell)) return <a href={`/members/edit/${cell}`}>{cell}</a>;
        else return cell;
      },
    },
    {
      dataField: "display_name",
      text: "Customer Name",
      sort: true,
    },
    {
      dataField: "number_sessions_limited",
      text: "Number Of Sessions held for Selected Dates",
      sort: true,
    },
    {
      dataField: "number_topups_limited",
      text: "Number of Top Ups for the Selected Dates",
      sort: true,
    },
    {
      dataField: "total_sessions_topups_limited",
      text: "Total Number of sessions held for the Selected Dates",
      sort: true,
    },
    {
      dataField: "amount_spent_limited",
      text: "Amount spent for the Selected Dates",
      sort: true,
    },
    {
      dataField: "total_purchased",
      text: "Total Number of sessions purchased",
      sort: true,
    },
    {
      dataField: "total_spent",
      text: "Total Amount spent",
      sort: true,
    },
    {
      dataField: "total_gifted",
      text: "Total Number of Sessions Gifted",
      sort: true,
    },
    {
      dataField: "total_sessions_topups",
      text: "Total number of sessions held",
      sort: true,
    },
    {
      dataField: "credit_left",
      text: "Credit left",
      sort: true,
    },
    {
      dataField: "first_purchase_date",
      text: "First Purchase Date",
      sort: true,
    },
    {
      dataField: "last_purchase_session_date",
      text: "Last Purchase Date",
      sort: true,
    },
    {
      dataField: "diff_first_last_purchase_session_date",
      text: "Number of Days between first and last purchase",
      sort: true,
    },
    {
      dataField: "diff_now_last_purchase_session_date",
      text: "Days since the last purchase",
      sort: true,
    },
  ];
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentData1, setCurrentData1] = useState([]);
  const [showLoading1, setShowLoading1] = useState(false);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [sortField1, setSortField1] = useState("user_id");
  const [sortOrder1, setSortOrder1] = useState("desc");
  const [sizePerPage1, setSizePerPage1] = useState(10);

  function addSumsToData(data) {
    if (!(data && data.length)) {
      return [];
    }
    let lastObject = {};
    tableColumns1.forEach(function (item) {
      lastObject[item.dataField] = 0;
    });
    lastObject.user_id = "#";
    lastObject.display_name = "Sum";

    data.forEach(function (dataLine, index) {
      for (const [key, value] of Object.entries(dataLine)) {
        // If the value is a number and it isn't in one of fields in the array (fields that shouldn't get summed up)
        if (
          !(
            (isNaN(value) && isNaN(value.replace(/^[$]+/, ""))) ||
            [
              "user_id",
              "display_name",
              "first_purchase_date",
              "last_purchase_session_date",
              "diff_first_last_purchase_session_date",
              "diff_now_last_purchase_session_date",
            ].includes(key)
          )
        ) {
          // Add the number to the lastObject (sums row)
          // It may be a currency value with a dollar sign, so remove the dollar sign and add the float value
          lastObject[key] =
            parseFloat(lastObject[key]) +
            (isNaN(value) ? parseFloat(value.replace(/^[$]+/, "")) : value);
          if (
            Number(lastObject[key]) === lastObject[key] &&
            lastObject[key] % 1 !== 0
          )
            // If the result is a float value, make sure it has only 2 decimal places
            lastObject[key] = lastObject[key].toFixed(2);
        }
      }
      if (!data[index].amount_spent_limited.toString().startsWith("$")) {
        data[index].amount_spent_limited =
          "$" + data[index].amount_spent_limited;
        data[index].total_spent = "$" + data[index].total_spent;
      }
    });

    lastObject.amount_spent_limited = "$" + lastObject.amount_spent_limited;
    lastObject.total_spent = "$" + lastObject.total_spent;

    return [...data, ...[lastObject]];
  }

  async function loadData1(startDate, endDate, page, pageSize, search) {
    setShowLoading1(true);
    setCurrentData1([]);

    if (isNaN(pageSize)) {
      page = 0;
      pageSize = 20;
    }

    var info = await dataService.customerSpendReport(
      startDate.format("YYYY-MM-DD"),
      new DateObject(endDate.format("YYYY-MM-DD"))
        .add(1, "day")
        .format("YYYY-MM-DD"),
      page,
      pageSize,
      "user_id",
      "desc",
      search
    );

    setTotalRecords1(info.record_count);

    await setCurrentData1(sortData(info.data, sortField1, sortOrder1));
    setShowLoading1(false);
  }

  useEffect(() => {
    loadData1(dateRange[0], dateRange[1], 1, sizePerPage1, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function pageChanged1(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage1) {
        // normally, we will need to make a call out
        await setCurrentPage1(1);
        await setSizePerPage1(newState.sizePerPage);
        await loadData1(
          dateRange[0],
          dateRange[1],
          1,
          newState.sizePerPage,
          searchText
        );
      } else if (newState.page !== currentPage1) {
        // normally, we will need to make a call out to get the passed page of data
        await setCurrentPage1(newState.page);
        await loadData1(
          dateRange[0],
          dateRange[1],
          newState.page,
          sizePerPage1,
          searchText
        );
      }
    } else if (type === "sort") {
      setSortField1(newState.sortField);
      setSortOrder1(newState.sortOrder);

      setCurrentData1(
        sortData(currentData1, newState.sortField, newState.sortOrder)
      );
      /*

      This part is commented because we no longer want to sort table records from the API, so sorting the current page data
      would be enough.

      // normally, we will need to make a call out
      if (newState.sortField !== sortField1) {
        await setSortField1(newState.sortField);
      }
      if (newState.sortOrder !== sortOrder1) {
        await setSortOrder1(newState.sortOrder);
      }
      await setCurrentPage1(1);
      loadData1(startDate,endDate,1,sizePerPage1,newState.sortField,newState.sortOrder);
      */
    }
  }
  function HandleDatePickerChange(dateArray) {
    if (dateArray.length === 2) {
      setDateRange(dateArray);
      setSearchText("");
      loadData1(dateArray[0], dateArray[1], 1, sizePerPage1, "");
    }
  }
  function HandleSearchChange(e) {
    setSearchText(e.target.value);
  }
  function sortData(tmpData, sortField, sortOrder) {
    let sortMethodNumber = sortOrder === "asc" ? 1 : -1;
    tmpData = tmpData.sort((valA, valB) => {
      var val1 = valA[sortField];
      var val2 = valB[sortField];
      val1 =
        isNaN(val1) && val1.length > 1
          ? val1[0] === "$"
            ? Number(val1.substring(1))
            : val1.slice(-1) === "%"
            ? Number(val1.substring(0, val1.length - 1))
            : val1
          : val1;
      val2 =
        isNaN(val2) && val2.length > 1
          ? val2[0] === "$"
            ? Number(val2.substring(1))
            : val2.slice(-1) === "%"
            ? Number(val2.substring(0, val2.length - 1))
            : val2
          : val2;
      if (val1 === val2) return 0;
      return (val1 > val2 ? 1 : -1) * sortMethodNumber;
    });
    return tmpData;
  }

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <UncontrolledDropdown>
      <DropdownToggle caret>
        {isNaN(currSizePerPage) ? "All" : currSizePerPage}
      </DropdownToggle>
      <DropdownMenu style={{ top: "100% !important" }}>
        {options.map((option) => (
          <DropdownItem
            key={option.text}
            onClick={() => onSizePerPageChange(option.page)}
          >
            {option.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Customer Spend Detail
          <CSVLink
            data={currentData1}
            asyncOnClick={true}
            filename={"data.csv"}
            className="btn btn-md btn-primary float-right"
          >
            Export to CSV
          </CSVLink>
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Customer Spend Detail</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg={4}>
                  <DatePicker
                    range
                    format="MMM D, YYYY"
                    disabled={showLoading1}
                    value={dateRange}
                    onChange={HandleDatePickerChange}
                    plugins={[<DatePanel />]}
                    render={(value, openCalendar) => {
                      return (
                        <div>
                          <Paper
                            component="form"
                            aria-label="fingerprint"
                            onClick={openCalendar}
                            className="text-secondary"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              width: 320,
                            }}
                          >
                            <Icon>date_range</Icon>
                            <InputBase
                              sx={{
                                ml: 1,
                                flex: 1,
                                fontWeight: "bold",
                                fontSize: 14,
                              }}
                              className="text-secondary"
                              value={value}
                              inputProps={{
                                "aria-label": "search",
                              }}
                            />
                            <Icon>arrow_drop_down</Icon>
                          </Paper>
                        </div>
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                    onSubmit={(e) => {
                      loadData1(
                        dateRange[0],
                        dateRange[1],
                        1,
                        sizePerPage1,
                        searchText
                      );
                      e.preventDefault();
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      value={searchText}
                      onChange={HandleSearchChange}
                      inputProps={{ "aria-label": "search" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={() => {
                        loadData1(
                          dateRange[0],
                          dateRange[1],
                          1,
                          sizePerPage1,
                          searchText
                        );
                      }}
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </Paper>
                </Col>
              </Row>
              <Row className={"mb-5"}>
                <Col lg={12} className={"mb-5 pb-5"}>
                  <BootstrapTable
                    keyField="key"
                    data={addSumsToData(currentData1)}
                    columns={tableColumns1}
                    sort={{ dataField: sortField1, order: sortOrder1 }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper my-4 table-responsive"
                    classes="table_class table_layout_auto"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: sizePerPage1,
                      sizePerPageList: [5, 10, 25, 50, 500, 1000, "All"],
                      page: currentPage1,
                      totalSize: totalRecords1,
                      sizePerPageRenderer,
                    })}
                    onTableChange={pageChanged1}
                    noDataIndication={() =>
                      showLoading1 ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerSpendReport;
