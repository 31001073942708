import React, { useState } from "react";
import { connect, useStore } from "react-redux";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import classNames from "classnames";

import { Badge, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import routes from "../routes/index";
import avatar from "../assets/img/avatars/user.png";
import logo from "../assets/img/avatars/lyf_small.png";

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({ user, name, icon, isOpen, children, onClick, location, to }) => {
    var badgeColor = "";
    var badgeText = "";

    /*if (name === "Member Content") {
		badgeColor = "danger";
		badgeText = "4";
	}*/

    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(({ user, name, icon, location, to }) => {
  var badgeColor = "";
  var badgeText = "";

  /*if (name === "Content Reporting") {
		badgeColor = "danger";
		badgeText = "3";
	}
	else if (name === "Contact Us") {
		badgeColor = "danger";
		badgeText = "1";
	}*/

  const getSidebarItemClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <NavLink to={to} className="sidebar-link" activeClassName="active">
        {icon ? (
          <React.Fragment>
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className="align-middle mr-2"
            />{" "}
            <span className="align-middle">{name}</span>
          </React.Fragment>
        ) : (
          name
        )}{" "}
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} pill className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );
});

const Sidebar = ({ location, sidebar }) => {
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);
  if (currentUser === null) {
    return <Redirect to={{ pathname: "/auth/sign-in" }} />;
  }

  var userAvatar = currentUser
    ? currentUser.avatar_url === ""
      ? null
      : currentUser.avatar_url
    : null;
  var img = userAvatar ?? avatar;

  function compareCategoryKeys(category) {
    var result = false;

    if (category.children && currentUser.permissions) {
      category.children.forEach((child) => {
        if (child.keys.length > 0) {
          child.keys.forEach((key) => {
            const matchIndex = currentUser.permissions.findIndex(function (
              row
            ) {
              return row === key;
            });
            if (matchIndex > -1) {
              result = true;
            }
          });
        } else {
          result = true;
        }
      });
    } else if (category.keys && currentUser.permissions) {
      if (category.keys.length > 0) {
        category.keys.forEach((key) => {
          const matchIndex = currentUser.permissions.findIndex(function (row) {
            return row === key;
          });
          if (matchIndex > -1) {
            result = true;
          }
        });
      } else {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  function compareKeys(keys) {
    var result = false;

    if (keys && currentUser.permissions) {
      if (keys.length > 0) {
        keys.forEach((key) => {
          const matchIndex = currentUser.permissions.findIndex(function (row) {
            return row === key;
          });
          if (matchIndex > -1) {
            result = true;
          }
        });
      } else {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  return (
    <nav
      className={classNames(
        "sidebar",
        sidebar.isOpen || "toggled",
        !sidebar.isOnRight || "sidebar-right"
      )}
    >
      <div className="sidebar-content">
        <NavLink
          to={"/"}
          className={classNames(
            "sidebar-brand",
            !sidebar.isOnRight || "text-right"
          )}
        >
          {sidebar.isOnRight || <img src={logo} alt="Lyf" width="30" />}{" "}
          <span className="align-middle"> Administration </span>{" "}
          {!sidebar.isOnRight || <img src={logo} alt="Lyf" width="30" />}
        </NavLink>
        <div className="sidebar-user">
          <img
            src={img}
            className="img-fluid rounded-circle mb-2"
            alt={currentUser ? currentUser.first_name : ""}
          />
          <div className="font-weight-bold">
            {currentUser
              ? currentUser.first_name + " " + currentUser.last_name
              : ""}
          </div>
        </div>
        <ul className="sidebar-nav">
          {routes.map((category, index) => {
            if (category.visible ?? true) {
              return (
                <React.Fragment key={index}>
                  {category.header && compareCategoryKeys(category) ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}
                  {category.children &&
                  !(category.hideChildren ?? false) &&
                  compareCategoryKeys(category) ? (
                    <SidebarCategory
                      name={category.name}
                      user={currentUser}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) =>
                        (route.visible ?? true) && compareKeys(route.keys) ? (
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            user={currentUser}
                          />
                        ) : (
                          <React.Fragment key={index} />
                        )
                      )}
                    </SidebarCategory>
                  ) : compareCategoryKeys(category) ? (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      user={currentUser}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
  }))(Sidebar)
);
