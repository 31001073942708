import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStore } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  CustomInput,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import DateTime from "react-datetime";
import classnames from "classnames";

import { useHistoryState } from "../../services/useHistoryState.js";

import EditMemberActivity from "./EditMemberActivity";
import EditMemberFollowing from "./EditMemberFollowing";
import EditMemberFriends from "./EditMemberFriends";
import EditMemberPurchases from "./EditMemberPurchases";
import EditMemberDevices from "./EditMemberDevices";
import EditMemberHiddenExperiences from "./EditMemberHiddenExperiences";
import EditMemberHiddenMembers from "./EditMemberHiddenMembers";
import EditMemberActiveExperiences from "./EditMemberActiveExperiences";
import EditMemberCompletedExperiences from "./EditMemberCompletedExperiences";
import EditMemberDeletedExperiences from "./EditMemberDeletedExperiences";
import EditMemberBlockingMembers from "./EditMemberBlockingMembers";

const dataService = require("../../services/dataServices.js");

const EditMember = () => {
  let { user_id } = useParams();
  const navigate = useHistory();
  const store = useStore();

  const [activeTab, setActiveTab] = useHistoryState(
    "editMember_activeTab_" + user_id,
    "1"
  );
  const [teamList, setTeamList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [teamID, setTeamID] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [advice, setAdvice] = useState("");
  const [memory, setMemory] = useState("");
  const [lesson, setLesson] = useState("");
  const [hearAbouUs, setHearAbouUs] = useState("");
  const [statusID, setStatusID] = useState(1);
  const [typeID, setTypeID] = useState(3);
  const [isTester, setIsTester] = useState("1");
  const [ageRestricted, setAgeRestricted] = useState(false);
  const [lyfSupport, setLyfSupport] = useState(false);
  const [lyfSupportEnabled, setLyfSupportEnabled] = useState(true);
  const [botUser, setBotUser] = useState(false);
  const [totalPurchased, setTotalPurchased] = useState(0);
  const [totalUsed, setTotalUsed] = useState(0);
  const [sessionsAvailable, setSessionsAvailable] = useState("");
  const [avatarImage, setAvatarImage] = useState([]);
  const [defaultAvatar, setDefaultAvatar] = useState([]);
  const [removeAvatar, setRemoveAvatar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [appDownloaded, setAppDownloaded] = useState(<></>);
  const [joinedWith, setJoinedWith] = useState("");
  const [provider, setProvider] = useState("");

  const [hasWritePermission, setHasWritePermission] = useState(false);

  function toggle(tab) {
    setActiveTab(tab);
  }

  async function constructObject() {
    var aString = avatarImage.length > 0 ? await toBase64(avatarImage[0]) : "";

    var obj = {
      user_id: user_id,
      email: email,
      country_code: countryCode.value,
      phone: phone,
      name: name,
      display_name: displayName,
      location: location.value,
      birth_date: birthDate ? birthDate.getTime() : 0,
      advice: advice,
      memory: memory,
      lesson: lesson,
      status_id: statusID,
      type_id:
        isTester === "2" ? 4 : isTester === "1" && typeID === 4 ? 3 : typeID,
      hide_age_restricted_flag: ageRestricted === true ? 0 : 1,
      lyf_support_professional_flag: lyfSupport === true ? 1 : 0,
      lyf_support_enabled_flag: lyfSupportEnabled === true ? 1 : 0,
      fake_flag: botUser === true ? 1 : 0,
      team_member_id: teamID.value === "" ? 0 : parseInt(teamID.value),
      avatar_url: aString,
      remove_avatar_flag: removeAvatar ? 1 : 0,
      how_hear_about_us: hearAbouUs,
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);
    setIsDisabled(true);

    var obj = await constructObject();
    await dataService.updateMember(obj);
    setIsLoading(false);
    setIsDisabled(false);

    // showToastr();
  }

  function performCancel() {
    navigate.goBack();
  }

  function toggleStatusID(e) {
    e.persist();
    setStatusID(e.target.value);
  }

  function toggleAccountTypeID(e) {
    e.persist();
    setIsTester(e.target.value);
  }

  useEffect(() => {
    async function loadData() {
      // load up the team into the dropdown
      var country_list = [];
      country_list.push({ value: "", label: "" });

      var country_code_list = [];
      country_code_list.push({ value: "", label: "" });

      var country_info = await dataService.countryList(1, 10000, "name", "asc");
      if (country_info.list !== undefined) {
        country_info.list.forEach(function (value, index) {
          country_list.push({
            value: value.code,
            label: value.name + " (+" + value.phone_code + ")",
          });
          country_code_list.push({ value: value.code, label: value.name });
        });
        setCountryList(country_list);
        setCountryCodeList(country_code_list);
      }

      // load up the team into the dropdown
      var new_list = [];
      new_list.push({ value: "", label: "None" });

      var team_info = await dataService.teamMemberList(
        1,
        1,
        10000,
        "first_name",
        "asc"
      );
      if (team_info.list !== undefined) {
        team_info.list.forEach(function (value, index) {
          new_list.push({
            value: value.team_member_id,
            label: value.first_name + " " + value.last_name,
          });
        });
        setTeamList(new_list);
      }

      // load the member data
      var data = await dataService.member(user_id);

      setEmail(data.email);
      setPhone(data.phone);
      setName(data.name === "Random_Lyfer" ? "" : data.name);
      setDisplayName(
        data.display_name === "Random_Lyfer" ? "" : data.display_name
      );
      if (data.birth_date === 0) {
        setBirthDate(null);
      } else {
        setBirthDate(new Date(data.birth_date));
      }
      setAdvice(data.advice);
      setMemory(data.memory);
      setLesson(data.lesson);
      setHearAbouUs(data.how_hear_about_us);
      setAgeRestricted(data.hide_age_restricted_flag === 0);
      setLyfSupport(data.lyf_support_professional_flag === 1);
      setLyfSupportEnabled(data.lyf_support_enabled_flag === 1);
      setBotUser(data.fake_flag === 1);
      setStatusID(data.status_id);
      setTypeID(data.type_id);
      setIsTester(data.type_id === 4 ? "2" : "1");
      setTotalPurchased(data.total_purchased);
      if (data.subscription_id !== 0) {
        setSessionsAvailable("Unlimited");
      } else {
        var x = data.total_purchased - data.total_used;
        setSessionsAvailable(x >= 0 ? x : 0);
      }
      setTotalUsed(data.total_used);
      if (data.avatar_url !== "" && data.avatar_url !== null) {
        setDefaultAvatar([data.avatar_url]);
      }

      var sortMatch = new_list.find((row) => {
        return row.value === data.team_member_id;
      });
      if (sortMatch) {
        setTeamID(sortMatch);
      } else {
        setTeamID(new_list[0]);
      }

      var cMatch = country_list.find((row) => {
        return row.value === data.country_code;
      });
      if (cMatch) {
        setCountryCode(cMatch);
      } else {
        setCountryCode(country_list[0]);
      }

      var lMatch = country_code_list.find((row) => {
        return row.value === data.location;
      });
      if (lMatch) {
        setLocation(lMatch);
      } else {
        setLocation(country_code_list[0]);
      }
      var lyfsupportImg = <></>;
      var lyfImg = <></>;
      if (data.last_lyfSupport_activity_date > 0) {
        lyfsupportImg = (
          <img
            src="/img/LyfSupportApp.svg"
            style={{ width: 30 }}
            alt="LyfSupport App"
          />
        );
      }

      if (data.lyf_app_used) {
        lyfImg = (
          <img src="/img/LyfApp.svg" style={{ width: 30 }} alt="Lyf App" />
        );
      }
      setAppDownloaded(
        <React.Fragment>
          {lyfImg} {lyfsupportImg}
        </React.Fragment>
      );

      var appText = "";
      if (data.type_id === 0) {
        appText = "Lyf App";
      } else {
        appText = "Lyf Support App";
      }

      setJoinedWith(appText);
      var provider = "";
      switch (data.provider) {
        case 0:
          provider = "Facebook";
          break;
        case 1:
          provider = "Apple";
          break;
        case 2:
          provider = "Google";
          break;
        case 3:
          provider = "Email Address";
          break;
        default:
          provider = "Email Address";
          break;
      }
      setProvider(provider);
      var perm = getWritePermission();
      setHasWritePermission(perm);

      setIsLoading(false);
      setIsDisabled(!perm);
    }

    const currentUser =
      store.getState().authentication.user ??
      (localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user"))
        : null);

    function getWritePermission() {
      var result = false;

      if (currentUser.permissions) {
        const matchIndex = currentUser.permissions.findIndex(function (row) {
          return row === "members_write";
        });
        if (matchIndex > -1) {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    }

    loadData();
  }, [user_id, store]);

  async function onDrop(picture) {
    setRemoveAvatar(picture.length === 0);
    setAvatarImage(picture);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        setIsLoading(false);
        setIsDisabled(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Edit Member",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Edit Member
          <Button color="light" className="float-right" onClick={performCancel}>
            Cancel
          </Button>
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/members/list">Members</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Member {user_id}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Member Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Member Content
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Experiences Created
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Purchases
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Devices
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    Hidden Content
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => {
                      toggle("7");
                    }}
                  >
                    Friends
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "8" })}
                    onClick={() => {
                      toggle("8");
                    }}
                  >
                    Following
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <AvForm onValidSubmit={save}>
                    <Row>
                      <Col>
                        <div className="d-flex flex-column pb-3  align-items-center">
                          <div>
                            <ImageUploader
                              withIcon={false}
                              withLabel={false}
                              buttonText="Choose avatar"
                              onChange={onDrop}
                              defaultImages={defaultAvatar}
                              imgExtension={[".jpeg", ".jpg", ".png"]}
                              maxFileSize={5242880}
                              singleImage={true}
                              withPreview={true}
                              buttonClassName="btn btn-primary"
                            />
                          </div>
                          <div>{appDownloaded}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <AvGroup>
                          <Label for="email">Email</Label>
                          <AvField
                            bsSize="lg"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter an email"
                            value={email}
                            disabled={isDisabled}
                            onChange={(e) => setEmail(e.target.value)}
                            validate={{
                              email: {
                                value: true,
                                errorMessage: "Please enter a valid email.",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={4}>
                        <AvGroup>
                          <Label for="name">Name</Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter a name"
                            value={name}
                            disabled={isDisabled}
                            onChange={(e) => setName(e.target.value)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a name.",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={4}>
                        <AvGroup>
                          <Label for="display_name">Display Name</Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="display_name"
                            id="display_name"
                            placeholder="Enter a display name"
                            value={displayName}
                            disabled={isDisabled}
                            onChange={(e) => setDisplayName(e.target.value)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a display name.",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Row>
                          <Col sm={6}>
                            <Label for="phone">Mobile Number</Label>
                            <FormGroup>
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={countryList}
                                value={countryCode}
                                onChange={(e) => setCountryCode(e)}
                                isDisabled={isLoading}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <AvGroup>
                              <Label for="countryCode">&nbsp;</Label>
                              <AvField
                                bsSize="lg"
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="Enter a mobile number"
                                value={phone}
                                disabled={isDisabled}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={4}>
                        <Label for="location">Country</Label>
                        <FormGroup>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={countryCodeList}
                            value={location}
                            onChange={(e) => setLocation(e)}
                            isDisabled={isDisabled}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <Label for="start_date">Birth Date</Label>
                        <DateTime
                          input={true}
                          value={birthDate}
                          timeFormat={false}
                          inputProps={{
                            placeholder: "Birth date",
                            disabled: isDisabled,
                            className: "form-control form-control-lg",
                          }}
                          closeOnSelect={true}
                          onChange={(e) => setBirthDate(e._d)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Label for="advice">Advice</Label>
                        <Input
                          type="textarea"
                          name="advice"
                          placeholder="Advice"
                          value={advice}
                          disabled={isDisabled}
                          onChange={(e) => setAdvice(e.target.value)}
                          rows="4"
                        />
                      </Col>
                      <Col sm={4}>
                        <Label for="advice">Memory</Label>
                        <Input
                          type="textarea"
                          name="memory"
                          placeholder="Memory"
                          value={memory}
                          disabled={isDisabled}
                          onChange={(e) => setMemory(e.target.value)}
                          rows="4"
                        />
                      </Col>
                      <Col sm={4}>
                        <Label for="advice">Lesson</Label>
                        <Input
                          type="textarea"
                          name="lesson"
                          placeholder="Lesson"
                          value={lesson}
                          disabled={isDisabled}
                          onChange={(e) => setLesson(e.target.value)}
                          rows="4"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <AvGroup>
                          <Label for="JoinedWith">
                            Which app they siged up with first:
                          </Label>

                          <AvField
                            type="text"
                            name="JoinedWith"
                            id="JoinedWith"
                            value={joinedWith}
                            disabled={true}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={4}>
                        <AvGroup>
                          <Label for="Signedup">How user signed up</Label>

                          <AvField
                            type="text"
                            name="Signedup"
                            id="Signedup"
                            value={provider}
                            disabled={true}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={2}>
                        <Label for="status_id">Status</Label>
                        <AvRadioGroup
                          name="status_id"
                          errorMessage="Pick one!"
                          value={statusID}
                          onChange={(e) => toggleStatusID(e)}
                          disabled={isDisabled}
                        >
                          <AvRadio customInput label="Active" value={1} />
                          <AvRadio customInput label="Deleted" value={2} />
                          <AvRadio customInput label="Blocked" value={3} />
                        </AvRadioGroup>
                      </Col>
                      <Col sm={2}>
                        <Label for="account_type_id">Account Type</Label>
                        <AvRadioGroup
                          name="account_type_id"
                          errorMessage="Pick one!"
                          value={isTester}
                          onChange={(e) => toggleAccountTypeID(e)}
                          disabled={isDisabled}
                        >
                          <AvRadio
                            customInput
                            label={`User${
                              typeID === 0
                                ? " (Lyf)"
                                : typeID === 1
                                ? " (LyfSupport)"
                                : typeID === 2
                                ? " (Psychologist)"
                                : ""
                            }`}
                            value="1"
                          />
                          <AvRadio customInput label="Tester" value="2" />
                        </AvRadioGroup>
                      </Col>
                      <Col sm={3}>
                        <Label for="hear_about_us">
                          How Did You Hear About Us?
                        </Label>
                        <AvRadioGroup
                          name="hear_about_us"
                          errorMessage="!"
                          value={!hearAbouUs ? "0" : "1"}
                          disabled={!hearAbouUs ? true : isDisabled}
                        >
                          <AvRadio
                            customInput
                            label={!hearAbouUs ? "(empty)" : hearAbouUs}
                            onClick={(e) => {
                              setHearAbouUs("");
                            }}
                            style={{ cursor: "pointer" }}
                            value="1"
                          />
                        </AvRadioGroup>
                      </Col>
                      <Col sm={3}>
                        <Label for="ageRestricted">Flags</Label>
                        <AvCheckboxGroup name={"ageRestricted"}>
                          <CustomInput
                            type="checkbox"
                            id={"ageRestricted"}
                            label="Show NSFW"
                            checked={ageRestricted}
                            disabled={isDisabled}
                            onChange={(e) => setAgeRestricted(e.target.checked)}
                          />
                          <CustomInput
                            type="checkbox"
                            id={"lyfSupport"}
                            label="Lyf Support Professional"
                            checked={lyfSupport}
                            disabled={isDisabled}
                            onChange={(e) => setLyfSupport(e.target.checked)}
                          />
                          <CustomInput
                            type="checkbox"
                            id={"lyfSupportEnabled"}
                            label="Lyf Support Enabled"
                            checked={lyfSupportEnabled}
                            disabled={isDisabled}
                            onChange={(e) =>
                              setLyfSupportEnabled(e.target.checked)
                            }
                          />
                          <CustomInput
                            type="checkbox"
                            id={"botUser"}
                            label="Bot User"
                            checked={botUser}
                            disabled={isDisabled}
                            onChange={(e) => setBotUser(e.target.checked)}
                          />
                        </AvCheckboxGroup>
                      </Col>
                      <Col sm={2}>
                        <Label for="ageRestricted">
                          Commenting Team Member
                        </Label>
                        <FormGroup>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={teamList}
                            value={teamID}
                            onChange={(e) => setTeamID(e)}
                            isDisabled={isLoading}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <Button
                        color="light"
                        size="lg"
                        className="mr-2"
                        onClick={performCancel}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" size="lg" disabled={isDisabled}>
                        {isLoading && (
                          <Spinner size="sm" className="mr-2 align-middle" />
                        )}
                        Save
                      </Button>
                    </div>
                  </AvForm>
                </TabPane>
                <TabPane tabId="2">
                  <EditMemberActivity
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <EditMemberActiveExperiences
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                  <EditMemberCompletedExperiences
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                  <EditMemberDeletedExperiences
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
                <TabPane tabId="4">
                  <EditMemberPurchases
                    user_id={user_id}
                    sessionsAvailable={sessionsAvailable}
                    totalPurchased={totalPurchased}
                    totalUsed={totalUsed}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
                <TabPane tabId="5">
                  <EditMemberDevices
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
                <TabPane tabId="6">
                  <EditMemberHiddenExperiences
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                  <EditMemberBlockingMembers
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                  <EditMemberHiddenMembers
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
                <TabPane tabId="7">
                  <EditMemberFriends
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
                <TabPane tabId="8">
                  <EditMemberFollowing
                    user_id={user_id}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditMember;
