const CookieController = require("../services/CookieController.js");

export const teamMemberData = async (
  data_filter,
  start_date,
  end_date,
  team_member_id
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/teamstats/?team_member_id=" +
      encodeURIComponent(team_member_id) +
      "&data=" +
      encodeURIComponent(data_filter) +
      "&start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const recentUserData = async (start_date, end_date) => {
  // console.log(process.env.REACT_APP_API_DOMAIN + "admin/dashboard/recentuserstats/?start_date=" + encodeURIComponent(start_date) + "&end_date=" + encodeURIComponent(end_date));

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/recentuserstats/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const downloadsData = async (start_date, end_date) => {
  // console.log(process.env.REACT_APP_API_DOMAIN + "admin/dashboard/downloads/?start_date=" + encodeURIComponent(start_date) + "&end_date=" + encodeURIComponent(end_date));

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/downloads/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const signupsData = async (start_date, end_date) => {
  // console.log(process.env.REACT_APP_API_DOMAIN + "admin/dashboard/signups/?start_date=" + encodeURIComponent(start_date) + "&end_date=" + encodeURIComponent(end_date));

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/signups/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const revenueData = async (start_date, end_date) => {
  console.log(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/revenue/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date)
  );

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/revenue/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const averageSessionData = async (start_date, end_date) => {
  console.log(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/averagesession/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date)
  );

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/averagesession/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const retentionData = async () => {
  // console.log(process.env.REACT_APP_API_DOMAIN + "admin/dashboard/retention");

  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/dashboard/retention",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const retentionSteps = async () => {
  console.log(
    process.env.REACT_APP_API_DOMAIN + "admin/dashboard/retention/?is_graph=0"
  );

  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/dashboard/retention/?is_graph=0",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const monthlyUserData = async (start_date, end_date) => {
  console.log(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/monthlyuserstats/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date)
  );

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/monthlyuserstats/?start_date=" +
      encodeURIComponent(start_date) +
      "&end_date=" +
      encodeURIComponent(end_date),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const dashboardContent = async (filter, data_filter, showTeam) => {
  console.log(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/content/?filter=" +
      encodeURIComponent(filter) +
      "&data=" +
      encodeURIComponent(data_filter) +
      "&team=" +
      (showTeam === true ? 1 : 0)
  );

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/content/?filter=" +
      encodeURIComponent(filter) +
      "&data=" +
      encodeURIComponent(data_filter) +
      "&team=" +
      (showTeam === true ? 1 : 0),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberList = async (
  status_id,
  filter,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/members/?status_id=" +
      status_id +
      "&filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberContentList = async (
  user_id,
  type,
  page,
  pageSize,
  sort,
  direction
) => {
  console.log(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/content/?type=" +
      encodeURIComponent(type) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction)
  );

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/content/?type=" +
      encodeURIComponent(type) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberFriendList = async (
  user_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/friends/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberFollowingList = async (
  user_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/following/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberExperiencesList = async (
  user_id,
  statusID,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/beats/?status_id=" +
      statusID +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberHiddenExperiencesList = async (
  user_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/hiddenbeats/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberHiddenMembersList = async (
  user_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/hiddenmembers/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberBlockingMembersList = async (
  user_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/blockingmembers/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberUnhideExperience = async (user_id, beat_id) => {
  //console.log(process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id + "/hiddenbeat/?beat_id=" + encodeURIComponent(beat_id));

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/hiddenbeat/?beat_id=" +
      encodeURIComponent(beat_id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberUnfriend = async (user_id, member_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/friend/?member_id=" +
      encodeURIComponent(member_id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberUnblockMember = async (user_id, member_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/hiddenmember/?member_id=" +
      encodeURIComponent(member_id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberDeleteContent = async (user_id, item_id, type) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/content/?type=" +
      encodeURIComponent(type) +
      "&item_id=" +
      encodeURIComponent(item_id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberDeleteAllContent = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id + "/content",
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberPurchaseList = async (
  user_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/purchases/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberAddGift = async (user_id, credits) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id + "/purchases",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: '{ "credits": ' + credits + " }",
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberDeviceList = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id + "/devices",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const memberDeleteDevice = async (user_id, token) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/devices/?token=" +
      encodeURIComponent(token),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberBlockDevice = async (user_id, token) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/devices/?token=" +
      encodeURIComponent(token),
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: '{ "status_id": 3 }',
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const memberVerifyDevice = async (user_id, token) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/devices/?token=" +
      encodeURIComponent(token),
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: '{ "status_id": 1 }',
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const member = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addMember = async (member) => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "admin/members", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(member),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateMember = async (member) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + member.user_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(member),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteMember = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const blockMember = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id + "/block",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const activateMember = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/member/" + user_id + "/activate",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const blockSupportMember = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/blocksupport",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const activateSupportMember = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/activatesupport",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const resetMemberPassword = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/resetpassword",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const changeMemberPassword = async (user_id, password) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/changepassword",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: '{ "password": "' + password + '"}',
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const userList = async (
  active_flag,
  filter,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/users/?active_flag=" +
      active_flag +
      "&filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const user = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/user/" + user_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addUser = async (user) => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "admin/users/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(user),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateUser = async (user) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/user/" + user.user_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(user),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteUser = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/user/" + user_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const activateUser = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/user/" + user_id + "/activate",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const resetPassword = async (user_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/user/" +
      user_id +
      "/resetpassword",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const changePassword = async (user_id, password) => {
  const varBody = {
    password: password,
  };

  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/member/" +
      user_id +
      "/changepassword",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(varBody),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const roleList = async (page, pageSize, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/roles/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const role = async (role_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/role/" + role_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addRole = async (name, permissions) => {
  const varBody = {
    name: name,
    permissions: permissions,
  };

  return await fetch(process.env.REACT_APP_API_DOMAIN + "admin/roles", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(varBody),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const updateRole = async (role_id, name, permissions) => {
  const varBody = {
    name: name,
    permissions: permissions,
  };

  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/role/" + role_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(varBody),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const deleteRole = async (role_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/role/" + role_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const promoList = async (filter, page, pageSize, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/promos/?filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const promo = async (promo_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/promo/" + promo_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addPromo = async (promo) => {
  return await fetch(process.env.REACT_APP_OLD_API_DOMAIN + "admin/promos/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(promo),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updatePromo = async (promo) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/promo/" + promo.promo_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(promo),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deletePromo = async (promo_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/promo/" + promo_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const partnershipList = async (
  filter,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/partnerships/?filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const categoryList = async (
  active_flag,
  filter,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/categories/?active_flag=" +
      active_flag +
      "&filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const partnership = async (partnership_id) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/partnership/" + partnership_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const category = async (category_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/category/" + category_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};
export const addPartnership = async (partnership) => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "admin/partnership/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(partnership),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};
export const addCategory = async (category) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/categories/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(category),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updatePartnership = async (partnership) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/partnership/" +
      partnership.partnership_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(partnership),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const activeDeactivePartnership = async (partnership_id, active) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/partnership/" +
      partnership_id +
      (active ? "/active" : "/deactive"),
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: "",
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const updateCategory = async (category) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/category/" +
      category.category_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(category),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteCategory = async (category_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/category/" + category_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const activateCategory = async (category_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/category/" +
      category_id +
      "/activate",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const wordList = async (page, pageSize, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/words/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const word = async (word_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/word/" + word_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addWord = async (word) => {
  return await fetch(process.env.REACT_APP_OLD_API_DOMAIN + "admin/words", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(word),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateWord = async (word) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/word/" + word.word_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(word),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteWord = async (word_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/word/" + word_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const algorithm = async (algorithmID) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/algorithm/" + algorithmID,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const saveAlgorithm = async (algorithmID, algorithm) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/algorithm/" + algorithmID,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(algorithm),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const runAlgorithm = async (algorithmID, algorithm) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/algorithm/" + algorithmID,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(algorithm),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const experienceList = async (
  status_id,
  filter,
  page,
  pageSize,
  sort,
  direction
) => {
  console.log(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beats/?status_id=" +
      status_id +
      "&filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction)
  );

  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beats/?status_id=" +
      status_id +
      "&filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    /*.then(res => res.json()).then((result) => {
		return result;
	}, (error) => {
		console.log(JSON.stringify(error));
		return null;
    });*/
    .then((res) => res.text())
    .then(
      (result) => {
        //console.log("what??" + result);
        return JSON.parse(result);
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const experience = async (beat_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/beat/" + beat_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const experienceFollowerList = async (
  beat_id,
  filter,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      beat_id +
      "/followers/?filter=" +
      encodeURIComponent(filter) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const experienceHiddenList = async (
  beat_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      beat_id +
      "/hidden/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const experienceContentList = async (
  beat_id,
  type,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      beat_id +
      "/content/?type=" +
      encodeURIComponent(type) +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const experienceDeleteContent = async (
  beat_id,
  item_id,
  user_id,
  type
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      beat_id +
      "/content/?type=" +
      encodeURIComponent(type) +
      "&item_id=" +
      encodeURIComponent(item_id) +
      "&user_id=" +
      encodeURIComponent(user_id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const experienceUnhide = async (beat_id, user_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      beat_id +
      "/hidden/?user_id=" +
      encodeURIComponent(user_id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const removeFollower = async (experienceID, userID) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      experienceID +
      "/follower/" +
      userID,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const updateExperienceBadge = async (experienceID, badgeID) => {
  const varBody = {
    badge_id: badgeID,
  };

  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      experienceID +
      "/badge",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(varBody),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const updateExperienceFilter = async (experienceID, filterID) => {
  const varBody = {
    filter_id: filterID,
  };

  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      experienceID +
      "/filter",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(varBody),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const addExperience = async (beat) => {
  return await fetch(process.env.REACT_APP_OLD_API_DOMAIN + "admin/beats", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(beat),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateExperience = async (beat) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/beat/" + beat.beat_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(beat),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
		return null;
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const activateExperience = async (beat_id) => {
  console.log(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      encodeURIComponent(beat_id) +
      "/activate"
  );

  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      encodeURIComponent(beat_id) +
      "/activate",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
		return null;
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteExperience = async (beat_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/beat/" +
      encodeURIComponent(beat_id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const reportingList = async (page, pageSize, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/reports/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log("RESUTL:" + result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteReport = async (itemID, type) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/report/" +
      itemID +
      "/?type=" +
      encodeURIComponent(type),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const ignoreReport = async (itemID, type) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/report/" +
      itemID +
      "/?type=" +
      encodeURIComponent(type),
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const reportingDetailList = async (itemID, type, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/report/" +
      itemID +
      "/?type=" +
      encodeURIComponent(type) +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const post = async (post_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/post/" + post_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const comment = async (comment_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/comment/" + comment_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const importMessageFile = async (messages) => {
  return await fetch(process.env.REACT_APP_OLD_API_DOMAIN + "admin/import", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: messages,
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

/* QUOTES */
export const quoteList = async (page, pageSize, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/quotes/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const quote = async (quote_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/quote/" + quote_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addQuote = async (quote) => {
  return await fetch(process.env.REACT_APP_OLD_API_DOMAIN + "admin/quotes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
    },
    body: JSON.stringify(quote),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateQuote = async (quote) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/quote/" + quote.quote_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(quote),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteQuote = async (quote_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/quote/" + quote_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

/* HELP CATEGORIES */
export const helpCategoryList = async (page, pageSize, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/helpcategories/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const helpCategory = async (category_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/helpcategory/" + category_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addHelpCategory = async (category) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/helpcategories",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(category),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateHelpCategory = async (category) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/helpcategory/" +
      category.category_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(category),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteHelpCategory = async (category_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/helpcategory/" + category_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

/* HELP TOPICS */
export const helpTopicList = async (
  category_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/helpcategory/" +
      category_id +
      "/topics/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const helpTopic = async (topic_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/helptopic/" + topic_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addHelpTopic = async (topic) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/helpcategory/" +
      topic.category_id +
      "/topics",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(topic),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateHelpTopic = async (topic) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/helptopic/" + topic.topic_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(topic),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteHelpTopic = async (topic_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/helptopic/" + topic_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    /*.then(res => res.json()).then((result) => {
		return result;
	}, (error) => {
		console.log(error);
		return null;
    });*/
    .then((res) => res.text())
    .then(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

/* COUNTRIES */
export const countryList = async (page, pageSize, sort, direction) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/countries/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

/* TEAM MEMBERS */
export const teamMemberList = async (
  active_flag,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/teammembers/?active_flag=" +
      active_flag +
      "&page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const teamMember = async (team_member_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/teammember/" + team_member_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
    });*/
};

export const addTeamMember = async (member) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/teammembers",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(member),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const updateTeamMember = async (member) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/teammember/" +
      member.team_member_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify(member),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const deleteTeamMember = async (team_member_id) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN + "admin/teammember/" + team_member_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    /*.then(res => res.json()).then((result) => {
		return result;
	}, (error) => {
		console.log(error);
		return null;
    });*/
    .then((res) => res.text())
    .then(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

/* TEAM MEMBER USERS */
export const teamMemberUserList = async (
  team_member_id,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/teammember/" +
      team_member_id +
      "/users/?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&sort=" +
      encodeURIComponent(sort) +
      "&direction=" +
      encodeURIComponent(direction),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const customerSpendReport = async (
  start_date,
  end_date,
  page,
  pageSize,
  sort,
  direction,
  search
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/report/customerspend/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      direction +
      "&search=" +
      search,

    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const averageWaitingTime = async () => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN + "admin/dashboard/avg_waiting_time",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const monthlySessionsHeld = async (
  start_date,
  end_date,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/report/monthlysessionsheld/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      direction,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const monthlyFreeTrialSessionsHeld = async (
  start_date,
  end_date,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/report/monthlyfreetrialsessionsheld/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      direction,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const monthlyNewCustomer = async (
  start_date,
  end_date,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/report/monthlynewcustomer/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      direction,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const monthlyReturningCustomers = async (
  start_date,
  end_date,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/report/monthlyreturningcustomers/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      direction,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const monthlyCustomerSpend = async (
  start_date,
  end_date,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/report/monthlycustomerspend/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      direction,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
export const dashboardSessions = async (
  start_date,
  end_date,
  page,
  pageSize
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/dashboard/sessions/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const monthlyNewUser = async (
  start_date,
  end_date,
  page,
  pageSize,
  sort,
  direction
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/report/monthlynewuser/?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&p=" +
      page +
      "&s=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      direction,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const forceUpdateGet = async (appVersion) => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "admin/forceupdate", {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: CookieController.getCookie("basiccookie"),
      "App-Version": "admin-" + appVersion + "-1",
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const forceUpdateSave = async (
  enabled,
  ios_release,
  android_release
) => {
  return await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "admin/forceupdate?enabled=" +
      enabled +
      "&ios_release=" +
      ios_release +
      "&android_release=" +
      android_release,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: CookieController.getCookie("basiccookie"),
      },
      body: JSON.stringify({
        enabled,
        ios_release,
        android_release,
      }),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};
