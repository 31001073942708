import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import avatar from "../../assets/img/avatars/user.png";

import { useHistoryState } from "../../services/useHistoryState.js";

const dataService = require("../../services/dataServices.js");

const badgeOptions = [
  { value: "0", label: "None" },
  { value: "1", label: "Featured" },
  { value: "2", label: "Marketing" },
  { value: "3", label: "New Recommended" },
  { value: "4", label: "Recommended" },
  { value: "5", label: "Trending" },
];

const filterOptions = [
  { value: "0", label: "None" },
  { value: "2", label: "Available" },
  { value: "15", label: "Hide from Welcome" },
  { value: "50", label: "Hide from Feed and Stream" },
  { value: "100", label: "Hide from Platform" },
];

const EditMemberActiveExperiences = (props) => {
  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const tableActions1 = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    return (
      <Button color="warning" size="sm" onClick={deleteRow}>
        Delete
      </Button>
    );
  };

  const titleDisplay = (cell, row) => {
    var userAvatar = row.banner_url === "" ? null : row.banner_url;
    var img = userAvatar ?? avatar;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1 float-left"
          style={{ width: 20, height: 20 }}
          alt={row.name}
        />
        <Link to={"/content/experiences/edit/" + row.beat_id}>
          <div>{row.name}</div>
        </Link>
        <div className="small">
          <strong>ID:</strong>&nbsp;{row.beat_id}
        </div>
      </React.Fragment>
    );
  };

  const permissionDisplay = (cell, row) => {
    if (row.permission_type_id === 1) {
      if (row.anonymous_avatar_id === 0) {
        return <React.Fragment>Public</React.Fragment>;
      } else {
        return <React.Fragment>Anonymous</React.Fragment>;
      }
    } else {
      return <React.Fragment>Private</React.Fragment>;
    }
  };

  const badgeDisplay = (cell, row) => {
    var sortMatch = badgeOptions.find((r) => {
      return r.value === "" + row.badge_id;
    });

    return <React.Fragment>{sortMatch.label}</React.Fragment>;
  };

  const filterDisplay = (cell, row) => {
    var sortMatch = filterOptions.find((r) => {
      return r.value === "" + row.filter_id;
    });

    return <React.Fragment>{sortMatch.label}</React.Fragment>;
  };

  const [currentPage, setCurrentPage] = useHistoryState(
    "editMemberActiveExperience_currentPage",
    1
  );
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useHistoryState(
    "editMemberActiveExperience_sortField",
    "created_date"
  );
  const [sortOrder, setSortOrder] = useHistoryState(
    "editMemberActiveExperience_sortOrder",
    "desc"
  );
  const [sizePerPage, setSizePerPage] = useHistoryState(
    "editMemberActiveExperience_sizePerPage",
    5
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const tableColumns = [
    {
      dataField: "name",
      text: "Title",
      sort: true,
      formatter: titleDisplay,
      editable: false,
    },
    {
      dataField: "created_date",
      text: "Created on",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      dataField: "permission_type_id",
      text: "Privacy",
      sort: true,
      formatter: permissionDisplay,
      editable: false,
    },
    {
      dataField: "badge_id",
      text: "Badge",
      sort: true,
      formatter: badgeDisplay,
    },
    {
      dataField: "filter_id",
      text: "Filter",
      sort: true,
      formatter: filterDisplay,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions1,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    setShowLoading(true);
    setCurrentData([]);

    // ok, delete the row
    await dataService.deleteExperience(selectedRow.beat_id);

    // refresh the table
    setCurrentPage(1);
    await loadData(1, sizePerPage, sortField, sortOrder);
  }

  async function loadData(page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.memberExperiencesList(
      props.user_id,
      1,
      page,
      pageSize,
      sort,
      order
    );
    if (info.record_count !== undefined) {
      setTotalRecords(info.record_count);
      setShowLoading(false);
      setCurrentData(info.list);
    }
  }

  function pageChanged(type, newState) {
    if (firstLoad === true) {
      setFirstLoad(false);
      loadData(currentPage, sizePerPage, sortField, sortOrder);
    } else {
      if (type === "pagination") {
        if (newState.sizePerPage !== sizePerPage) {
          // normally, we will need to make a call out
          setCurrentPage(1);
          setSizePerPage(newState.sizePerPage);
          loadData(1, newState.sizePerPage, sortField, sortOrder);
        } else if (newState.page !== currentPage) {
          // normally, we will need to make a call out to get the passed page of data
          setCurrentPage(newState.page);
          loadData(newState.page, sizePerPage, sortField, sortOrder);
        }
      } else if (type === "sort") {
        // normally, we will need to make a call out

        if (newState.sortField !== sortField) {
          setSortField(newState.sortField);
        } else if (newState.sortOrder !== sortOrder) {
          setSortOrder(newState.sortOrder);
        }

        setCurrentPage(1);
        loadData(1, sizePerPage, newState.sortField, newState.sortOrder);
      }
    }
  }

  useEffect(() => {
    async function reloadData() {
      console.log("value changed!");

      setShowLoading(true);
      setCurrentData([]);

      var info = await dataService.memberExperiencesList(
        props.user_id,
        1,
        1,
        10,
        "event_date",
        "desc"
      );
      if (info.record_count !== undefined) {
        setTotalRecords(info.record_count);
        setShowLoading(false);
        setCurrentData(info.list);
      }
    }

    if (firstLoad === false) {
      reloadData();
    }
  }, [props.user_id, firstLoad]);

  return (
    <React.Fragment>
      <h4>Created Experiences</h4>
      <BootstrapTable
        keyField="beat_id"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"unhide"}
        isOpen={showDeleteModal}
        toggle={() => toggleDelete()}
      >
        <ModalHeader toggle={() => toggleDelete()}>
          Delete Experience{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to delete this experience?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDelete()}>
            Cancel
          </Button>{" "}
          <Button color="warning" onClick={() => performDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditMemberActiveExperiences;
