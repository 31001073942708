import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import DateTime from "react-datetime";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
const dataService = require("../../services/dataServices.js");

const MonthlyNewCustomer = (props) => {
  const [startDate, setStartDate] = useState(
    DateTime.moment().add(-1, "month")
  );
  const [endDate, setEndDate] = useState(DateTime.moment());
  const tableColumns = [
    {
      dataField: "month",
      text: "Month",
      sort: true,
    },
    {
      dataField: "first_time_customer",
      text: "First Time Customer",
      sort: true,
    },
    {
      dataField: "sessions_extended",
      text: "Sessions extended",
      sort: true,
    },
    {
      dataField: "2nd_session",
      text: "2nd Session",
      sort: true,
    },
  ];
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [sortField, setSortField] = useState("month");
  const [sortOrder, setSortOrder] = useState("desc");

  async function loadData(startDate, endDate, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.monthlyNewCustomer(
      startDate.format("YYYY-MM"),
      endDate.format("YYYY-MM"),
      0,
      0,
      "month",
      "desc"
    );

    await setCurrentData(sortData(info.data, sortField, sortOrder));
    setShowLoading(false);
  }

  useEffect(() => {
    loadData(startDate, endDate, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function sortChanged(type, newState) {
    if (type === "sort") {
      setSortField(newState.sortField);
      setSortOrder(newState.sortOrder);

      setCurrentData(
        sortData(
          currentData,
          newState.sortField === "month"
            ? "month_unformatted"
            : newState.sortField,
          newState.sortOrder
        )
      );
    }
  }

  function sortData(tmpData, sortField, sortOrder) {
    let sortMethodNumber = sortOrder === "asc" ? 1 : -1;
    tmpData = tmpData.sort((valA, valB) => {
      var val1 = valA[sortField];
      var val2 = valB[sortField];
      val1 =
        isNaN(val1) && val1.length > 1
          ? val1[0] === "$"
            ? Number(val1.substring(1))
            : val1.slice(-1) === "%"
            ? Number(val1.substring(0, val1.length - 1))
            : val1
          : val1;
      val2 =
        isNaN(val2) && val2.length > 1
          ? val2[0] === "$"
            ? Number(val2.substring(1))
            : val2.slice(-1) === "%"
            ? Number(val2.substring(0, val2.length - 1))
            : val2
          : val2;
      if (val1 === val2) return 0;
      return (val1 > val2 ? 1 : -1) * sortMethodNumber;
    });
    return tmpData;
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Monthly New Customers
          <CSVLink
            data={currentData}
            asyncOnClick={true}
            filename={"data.csv"}
            className="btn btn-md btn-primary float-right"
          >
            Export to CSV
          </CSVLink>
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Monthly New Customers</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg={4}>
                  Start Date
                  <DateTime
                    input={true}
                    value={startDate}
                    timeFormat={false}
                    inputProps={{
                      placeholder: "Start date",
                      disabled: showLoading,
                      className: "form-control form-control-md",
                    }}
                    closeOnSelect={true}
                    onChange={(e) => {
                      setStartDate(e);
                      loadData(e, endDate, sortField, sortOrder);
                    }}
                    isValidDate={(date) => {
                      return !(
                        date.isAfter(DateTime.moment()) || date.isAfter(endDate)
                      );
                    }}
                    dateFormat={"MM/YYYY"}
                  />
                </Col>
                <Col lg={4}>
                  End Date
                  <DateTime
                    input={true}
                    value={endDate}
                    timeFormat={false}
                    inputProps={{
                      placeholder: "End date",
                      disabled: showLoading,
                      className: "form-control form-control-md",
                    }}
                    closeOnSelect={true}
                    onChange={(e) => {
                      setEndDate(e);
                      loadData(startDate, e, sortField, sortOrder);
                    }}
                    isValidDate={(date) => {
                      return !(
                        date.isAfter(DateTime.moment()) ||
                        date.isBefore(startDate)
                      );
                    }}
                    dateFormat={"MM/YYYY"}
                  />
                </Col>
              </Row>
              <Row className={"mb-5"}>
                <Col lg={12} className={"mb-5 pb-5"}>
                  <BootstrapTable
                    keyField="key"
                    data={currentData}
                    columns={tableColumns}
                    sort={{ dataField: sortField, order: sortOrder }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper my-4 table-responsive"
                    classes="table_class table_layout_auto"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    onTableChange={sortChanged}
                    noDataIndication={() =>
                      showLoading ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MonthlyNewCustomer;
