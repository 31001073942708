import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
  Input,
  InputGroupAddon,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const dataService = require("../../services/dataServices.js");

const PartnershipList = (props) => {
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentData1, setCurrentData1] = useState([]);
  const [showLoading1, setShowLoading1] = useState(true);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [sortField1, setSortField1] = useState("sort_rank");
  const [sortOrder1, setSortOrder1] = useState("asc");
  const [sizePerPage1, setSizePerPage1] = useState(10);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [searchFilter1, setSearchFilter1] = useState("");

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);
  const navigate = useHistory();

  function hasWritePermission() {
    var result = false;

    if (currentUser.permissions) {
      const matchIndex = currentUser.permissions.findIndex(function (row) {
        return row === "categories_write";
      });
      if (matchIndex > -1) {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  function performAddPartnership() {
    navigate.push("/system-content/partnerships/add");
  }

  function performAddLabel() {
    navigate.push("/system-content/labels/add");
  }

  const tableActions1 = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }
    function activeRow() {
      setSelectedRow(row);
      setShowActivateModal(true);
    }
    function editRow(row) {
      if (row.type === 2) {
        performEditLabel(row.partnership_id);
      } else {
        performEditPartnership(row.partnership_id);
      }
    }

    if (hasWritePermission()) {
      return (
        <UncontrolledButtonDropdown
          key={row.partnership_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            {row.active && (
              <>
                <DropdownItem onClick={() => editRow(row)}>Edit</DropdownItem>
                <DropdownItem onClick={deleteRow}>Deactivate</DropdownItem>
              </>
            )}
            {!row.active && (
              <DropdownItem onClick={activeRow}>Activate</DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    } else {
      return (
        <UncontrolledButtonDropdown
          key={row.partnership_id}
          className="mr-1 mb-1"
          direction="left"
        >
          <DropdownToggle caret color="primary">
            <FontAwesomeIcon icon={faBars} className="ml-1" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={editRow}>View</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    }
  };

  function colorFormatter(cell, row) {
    return (
      <span>
        <strong style={{ backgroundColor: cell, color: "white" }}>
          {cell}
        </strong>
      </span>
    );
  }
  function typeFormatter(cell, row) {
    if (cell === 0 || cell === "0") {
      return "Psychologist";
    } else if (cell === 1 || cell === "1") {
      return "Revenue";
    } else if (cell === 2 || cell === "2") {
      return "Label";
    }

    return "";
  }

  const tableColumns1 = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "email",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      formatter: typeFormatter,
      sort: true,
    },
    {
      dataField: "sort_rank",
      text: "Display Sort",
      sort: true,
    },
    {
      dataField: "color",
      text: "Colour",
      formatter: colorFormatter,
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "active",
      text: "",
      sort: false,
      formatter: tableActions1,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  async function loadData1(filter, page, pageSize, sort, order) {
    setShowLoading1(true);
    setCurrentData1([]);

    var info = await dataService.partnershipList(
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setTotalRecords1(info.record_count);
    setShowLoading1(false);
    setCurrentData1(info.list);
  }

  function performKey1(e) {
    if (e.charCode === 13) {
      performSearch1();
    }
  }

  function performEditPartnership(partnership_id) {
    navigate.push("/system-content/partnerships/edit/" + partnership_id);
  }

  function performEditLabel(partnership_id) {
    navigate.push("/system-content/labels/edit/" + partnership_id);
  }

  function performSearch1() {
    loadData1(
      searchFilter1,
      currentPage1,
      sizePerPage1,
      sortField1,
      sortOrder1
    );
  }

  function pageChanged1(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage1) {
        // normally, we will need to make a call out
        setCurrentPage1(1);
        setSizePerPage1(newState.sizePerPage);
        loadData1(
          searchFilter1,
          1,
          newState.sizePerPage,
          sortField1,
          sortOrder1
        );
      } else if (newState.page !== currentPage1) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage1(newState.page);
        loadData1(
          searchFilter1,
          newState.page,
          sizePerPage1,
          sortField1,
          sortOrder1
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField1) {
        setSortField1(newState.sortField);
      }
      if (newState.sortOrder !== sortOrder1) {
        setSortOrder1(newState.sortOrder);
      }
      setCurrentPage1(1);

      loadData1(
        searchFilter1,
        1,
        sizePerPage1,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  const rowClasses = (row, rowIndex) => {
    if (!row.active) {
      return "text-black-50";
    }
    return "";
  };

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    await dataService.activeDeactivePartnership(
      selectedRow.partnership_id,
      false
    );
    selectedRow.active = false;
    setShowDeleteModal(false);
  }
  function toggleActivate() {
    setShowActivateModal(!showActivateModal);
  }

  async function performActivate() {
    await dataService.activeDeactivePartnership(
      selectedRow.partnership_id,
      true
    );
    selectedRow.active = true;
    setShowActivateModal(false);
  }
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Partnerships
          {hasWritePermission() && (
            <ButtonGroup className="float-right">
              <Button color="success" onClick={performAddPartnership}>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add New Partnership
              </Button>
              <Button color="success" onClick={performAddLabel}>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add New Label
              </Button>
            </ButtonGroup>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Partnerships</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader></CardHeader>
            <CardBody>
              <div className="mb-3">
                <InputGroup>
                  <Input
                    placeholder="Search..."
                    value={searchFilter1}
                    onChange={(e) => setSearchFilter1(e.target.value)}
                    onKeyPress={(e) => performKey1(e)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                  />
                  <InputGroupAddon addonType="append" color="primary">
                    <Button onClick={performSearch1}>
                      <FontAwesomeIcon icon={faSearch} className="mr-2" />
                      Search
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <BootstrapTable
                keyField="partnership_id"
                data={currentData1}
                columns={tableColumns1}
                rowClasses={rowClasses}
                sort={{ dataField: sortField1, order: sortOrder1 }}
                bootstrap4
                striped
                wrapperClasses="table_wrapper"
                classes="table_class"
                remote={{ pagination: true, filter: true, sort: true }}
                bordered={false}
                pagination={paginationFactory({
                  sizePerPage: sizePerPage1,
                  sizePerPageList: [5, 10, 25, 50],
                  page: currentPage1,
                  totalSize: totalRecords1,
                })}
                onTableChange={pageChanged1}
                noDataIndication={() =>
                  showLoading1 ? (
                    <div
                      style={{ height: 250 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div
                      style={{ height: 250 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span>There are no records</span>
                    </div>
                  )
                }
              />
            </CardBody>
          </Card>
          <Modal
            key={"delete"}
            isOpen={showDeleteModal}
            toggle={() => toggleDelete()}
          >
            <ModalHeader toggle={() => toggleDelete()}>
              Deactivate Label{selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to deactivate this label or partnership?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleDelete()}>
                Cancel
              </Button>{" "}
              <Button color="danger" onClick={() => performDelete()}>
                Deactivate
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            key={"activate"}
            isOpen={showActivateModal}
            toggle={() => toggleActivate()}
          >
            <ModalHeader toggle={() => toggleActivate()}>
              Activate Label{selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to activate this label or partnership?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleActivate()}>
                Cancel
              </Button>{" "}
              <Button color="success" onClick={() => performActivate()}>
                Activate
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default PartnershipList;
