import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import avatar from "../../assets/img/avatars/user.png";

import { useHistoryState } from "../../services/useHistoryState.js";

const dataService = require("../../services/dataServices.js");

const contentOptions = [
  { value: "Post", label: "Posts" },
  { value: "Support", label: "Supports" },
  { value: "Comment", label: "Comments" },
  { value: "Reaction", label: "Reactions" },
];

const EditMemberActivity = (props) => {
  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const beatDisplay = (cell, row) => {
    var userAvatar = row.beat.banner_url === "" ? null : row.beat.banner_url;
    var img = userAvatar ?? avatar;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1 float-left"
          style={{ width: 20, height: 20 }}
          alt={row.beat.name}
        />
        <Link to={"/content/experiences/edit/" + row.beat.beat_id}>
          <div>{row.beat.name}</div>
        </Link>
        <div className="small">
          <strong>ID:</strong>&nbsp;{row.beat.beat_id}
        </div>
        {"" + row.beat.user_id === props.user_id && (
          <div className="small">
            <strong>Member Experience</strong>
          </div>
        )}
      </React.Fragment>
    );
  };

  const postDisplay = (cell, row) => {
    function showPost(e) {
      e.preventDefault();

      setSelectedRow(row);
      togglePost();
    }

    var txt = row.post.text;
    if (txt.length > 140) {
      txt = txt.substring(0, 140) + " ... ";
    }

    if (row.post.post_id !== "") {
      if (row.post.attachments.length > 0) {
        return (
          <a href="/" onClick={(e) => showPost(e)}>
            <img
              src={row.post.attachments[0].url}
              style={{ height: 100 }}
              alt=""
            />
            <div>{txt}</div>
          </a>
        );
      } else {
        return (
          <a href="/" onClick={(e) => showPost(e)}>
            {txt}
          </a>
        );
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const commentDisplay = (cell, row) => {
    function showComment(e) {
      e.preventDefault();

      setSelectedRow(row);
      toggleComment();
    }

    if (row.comment.comment_id !== "") {
      var txt = row.comment.text;
      if (txt.length > 140) {
        txt = txt.substring(0, 140) + " ... ";
      }

      return (
        <a href="/" onClick={(e) => showComment(e)}>
          {txt}
        </a>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const actionDisplay = (cell, row) => {
    function deleteRow() {
      if (props.hasWritePermission === true) {
        setSelectedRow(row);
        setShowDeleteContentModal(true);
      }
    }

    return (
      <Button
        color="warning"
        size="sm"
        onClick={deleteRow}
        disabled={!props.hasWritePermission}
      >
        Delete
      </Button>
    );
  };

  const postTableColumns = [
    {
      dataField: "text",
      text: "Text / Image",
      sort: true,
      formatter: postDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "beat_id",
      text: "Beat",
      sort: true,
      formatter: beatDisplay,
    },
    {
      dataField: "created_date",
      text: "Created on",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const supportTableColumns = [
    {
      dataField: "text",
      text: "Type",
      sort: true,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "post_id",
      text: "Post",
      sort: true,
      formatter: postDisplay,
    },
    {
      isDummyField: true,
      dataField: "beat_id",
      text: "Beat",
      sort: true,
      formatter: beatDisplay,
    },
    {
      dataField: "created_date",
      text: "Created on",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const commentTableColumns = [
    {
      dataField: "text",
      text: "Comment",
      sort: true,
      formatter: commentDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "post_id",
      text: "Post",
      sort: true,
      formatter: postDisplay,
    },
    {
      isDummyField: true,
      dataField: "beat_id",
      text: "Beat",
      sort: true,
      formatter: beatDisplay,
    },
    {
      dataField: "created_date",
      text: "Created on",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const reactionTableColumns = [
    {
      dataField: "text",
      text: "Agree / Disagree",
      sort: true,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "comment_id",
      text: "Comment",
      sort: true,
      formatter: commentDisplay,
    },
    {
      isDummyField: true,
      dataField: "post_id",
      text: "Post",
      sort: true,
      formatter: postDisplay,
    },
    {
      isDummyField: true,
      dataField: "beat_id",
      text: "Beat",
      sort: true,
      formatter: beatDisplay,
    },
    {
      dataField: "created_date",
      text: "Created on",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: actionDisplay,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const [isDeleteAllLoading, setIsDeleteAllLoading] = useState(false);
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [showDeleteAllCompleteModal, setShowDeleteAllCompleteModal] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const [contentFilter, setContentFilter] = useHistoryState(
    "editMemberActivity_contentFilter",
    contentOptions[0]
  );

  const [currentPage, setCurrentPage] = useHistoryState(
    "editMemberActivity_currentPage",
    1
  );
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useHistoryState(
    "editMemberActivity_sortField",
    "created_date"
  );
  const [sortOrder, setSortOrder] = useHistoryState(
    "editMemberActivity_sortOrder",
    "desc"
  );
  const [sizePerPage, setSizePerPage] = useHistoryState(
    "editMemberActivity_sizePerPage",
    10
  );
  const [contentColumns, setContentColumns] = useState(postTableColumns);

  const [showPostModal, setShowPostModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  function togglePost() {
    setShowPostModal(!showPostModal);
  }

  function toggleComment() {
    setShowCommentModal(!showCommentModal);
  }

  function toggleDeleteContent() {
    setShowDeleteContentModal(!showDeleteContentModal);
  }

  function toggleDeleteAll() {
    setShowDeleteAllModal(!showDeleteAllModal);
  }

  function toggleDeleteAllComplete() {
    setShowDeleteAllCompleteModal(!showDeleteAllCompleteModal);
  }

  async function performDeleteContent() {
    setShowDeleteContentModal(false);
    setShowLoading(true);
    setCurrentData([]);

    // ok, delete the row
    await dataService.memberDeleteContent(
      props.user_id,
      selectedRow.item_id,
      selectedRow.type
    );

    // refresh the table
    setCurrentPage(1);
    await loadContentData(
      contentFilter.value,
      1,
      sizePerPage,
      sortField,
      sortOrder
    );
  }

  async function performDeleteAll() {
    setShowDeleteAllModal(false);
    setIsDeleteAllLoading(true);

    // perform the delete
    await dataService.memberDeleteAllContent(props.user_id);

    // done!
    setIsDeleteAllLoading(false);
    setShowDeleteAllCompleteModal(true);

    // refresh the table
    setCurrentPage(1);
    await loadContentData(
      contentFilter.value,
      1,
      sizePerPage,
      sortField,
      sortOrder
    );
  }

  function updateContentFilter(e) {
    setContentFilter(e);
    setCurrentPage(1);

    if (e.value === "Post") {
      setContentColumns(postTableColumns);
    } else if (e.value === "Support") {
      setContentColumns(supportTableColumns);
    } else if (e.value === "Comment") {
      setContentColumns(commentTableColumns);
    } else if (e.value === "Reaction") {
      setContentColumns(reactionTableColumns);
    }

    loadContentData(e.value, 1, sizePerPage, sortField, sortOrder);
  }

  async function loadContentData(filter, page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.memberContentList(
      props.user_id,
      filter,
      page,
      pageSize,
      sort,
      order
    );
    if (info.record_count !== undefined) {
      setTotalRecords(info.record_count);
      setShowLoading(false);
      setCurrentData(info.list);
    }
  }

  function pageChanged(type, newState) {
    if (firstLoad === true) {
      setFirstLoad(false);
      loadContentData(
        contentFilter.value,
        currentPage,
        sizePerPage,
        sortField,
        sortOrder
      );
    } else {
      if (type === "pagination") {
        if (newState.sizePerPage !== sizePerPage) {
          // normally, we will need to make a call out
          setCurrentPage(1);
          setSizePerPage(newState.sizePerPage);
          loadContentData(
            contentFilter.value,
            1,
            newState.sizePerPage,
            sortField,
            sortOrder
          );
        } else if (newState.page !== currentPage) {
          // normally, we will need to make a call out to get the passed page of data
          setCurrentPage(newState.page);
          loadContentData(
            contentFilter.value,
            newState.page,
            sizePerPage,
            sortField,
            sortOrder
          );
        }
      } else if (type === "sort") {
        // normally, we will need to make a call out

        if (newState.sortField !== sortField) {
          setSortField(newState.sortField);
        } else if (newState.sortOrder !== sortOrder) {
          setSortOrder(newState.sortOrder);
        }
        setCurrentPage(1);

        loadContentData(
          contentFilter.value,
          1,
          sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
  }

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col sm={9} className="align-self-center">
          <h4>Content Type</h4>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={contentOptions}
              value={contentFilter}
              onChange={(e) => updateContentFilter(e)}
            />
          </FormGroup>
        </Col>
        <Col sm={3} className="align-self-center text-right">
          <Button
            className="float-right"
            color="warning"
            disabled={!props.hasWritePermission || isDeleteAllLoading}
            onClick={toggleDeleteAll}
          >
            {isDeleteAllLoading && (
              <Spinner size="sm" className="mr-2 align-middle" />
            )}
            Delete All Content
          </Button>
        </Col>
      </Row>
      <BootstrapTable
        keyField="item_id"
        data={currentData}
        columns={contentColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"deleteContent"}
        isOpen={showDeleteContentModal}
        toggle={() => toggleDeleteContent()}
      >
        <ModalHeader toggle={() => toggleDeleteContent()}>
          Delete Content{selectedRow ? " - " + selectedRow.type : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">Are you sure you want to delete this content?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDeleteContent()}>
            Cancel
          </Button>{" "}
          <Button color="warning" onClick={() => performDeleteContent()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        key={"deleteAll"}
        isOpen={showDeleteAllModal}
        toggle={() => toggleDeleteAll()}
      >
        <ModalHeader toggle={() => toggleDeleteAll()}>
          Delete All Content
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to delete all content from this user?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDeleteAll()}>
            Cancel
          </Button>{" "}
          <Button color="warning" onClick={() => performDeleteAll()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        key={"deleteAllComplete"}
        isOpen={showDeleteAllCompleteModal}
        toggle={() => toggleDeleteAllComplete()}
      >
        <ModalHeader toggle={() => toggleDeleteAllComplete()}>
          Delete All Content - Complete
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            All content for this user has been successfully deleted.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => toggleDeleteAllComplete()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        key={"post"}
        size="lg"
        isOpen={showPostModal}
        toggle={() => togglePost()}
      >
        <ModalHeader toggle={() => togglePost()}>Post</ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            {selectedRow !== null && selectedRow.post !== null && (
              <AvForm>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="beat_name" className="float-left">
                        Beat
                      </Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="beat_name"
                        id="beat_name"
                        value={selectedRow.beat.name}
                        disabled={true}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="created_date" className="float-left">
                        Date Posted
                      </Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="created_date"
                        id="created_date"
                        value={new Date(
                          selectedRow.created_date
                        ).toLocaleString()}
                        disabled={true}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Label for="text" className="float-left">
                      Text
                    </Label>
                    <Input
                      type="textarea"
                      name="text"
                      value={selectedRow.post.text}
                      disabled={true}
                      rows="4"
                    />
                  </Col>
                </Row>
              </AvForm>
            )}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => togglePost()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        key={"comment"}
        size="lg"
        isOpen={showCommentModal}
        toggle={() => toggleComment()}
      >
        <ModalHeader toggle={() => toggleComment()}>Comment</ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            {selectedRow !== null && selectedRow.comment !== null && (
              <AvForm>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="beat_name" className="float-left">
                        Beat
                      </Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="beat_name"
                        id="beat_name"
                        value={selectedRow.beat.name}
                        disabled={true}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="created_date" className="float-left">
                        Date Posted
                      </Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="created_date"
                        id="created_date"
                        value={new Date(
                          selectedRow.created_date
                        ).toLocaleString()}
                        disabled={true}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Label for="text" className="float-left">
                      Text
                    </Label>
                    <Input
                      type="textarea"
                      name="text"
                      value={selectedRow.comment.text}
                      disabled={true}
                      rows="4"
                    />
                  </Col>
                </Row>
              </AvForm>
            )}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => toggleComment()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditMemberActivity;
