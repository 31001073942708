import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap";
import { RefreshCw } from "react-feather";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { CSVLink } from "react-csv";

import RetentionSteps from "./RetentionSteps";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const dataService = require("../../services/dataServices.js");

const RetentionStats = (props) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [csvData, setCSVData] = useState([]);

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "rgba(255,255,255,0.8)",
          },
          reverse: true,
          gridLines: {
            color: "rgba(255,255,255,0.0)",
            zeroLineColor: "rgba(255,255,255,1.0)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 50,
            fontColor: "rgba(255,255,255,0.8)",
          },
          display: true,
          gridLines: {
            color: "rgba(255,255,255,0.5)",
            zeroLineColor: "rgba(255,255,255,1.0)",
          },
        },
      ],
    },
  };

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      var dta = await dataService.retentionData();
      setData(dta);

      // parse and construct the CSV data
      var raw = [];
      if (dta.datasets && dta.datasets.length > 0) {
        for (var j = 0; j < dta.labels.length; j++) {
          var date_string = dta.labels[j];
          var count = dta.datasets[0].data[j];

          raw.push({ Day: date_string, Percentage: count });
        }
      }
      setCSVData(raw);

      setIsLoading(false);
    }

    loadData();
  }, []);

  async function getData() {
    setIsLoading(true);
    var dta = await dataService.retentionData();
    setData(dta);

    // parse and construct the CSV data
    var raw = [];
    if (dta.datasets && dta.datasets.length > 0) {
      for (var j = 0; j < dta.labels.length; j++) {
        var date_string = dta.labels[j];
        var count = dta.datasets[0].data[j];

        raw.push({ Day: date_string, Percentage: count });
      }
    }
    setCSVData(raw);

    setIsLoading(false);
  }

  async function reloadData(e) {
    e.preventDefault();
    await getData();
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          User Retention
          {isLoading && (
            <Button
              className="btn btn-md btn-primary float-right"
              disabled={true}
            >
              <Spinner size="sm" className="mr-2 align-middle" />
              Export to CSV
            </Button>
          )}
          {!isLoading && (
            <CSVLink
              data={csvData}
              asyncOnClick={true}
              filename={"data.csv"}
              className="btn btn-md btn-primary float-right"
            >
              Export to CSV
            </CSVLink>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>User Retention</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card className="flex-fill w-100 darkgray">
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                <Row>
                  <Col
                    xs={12}
                    md={11}
                    className="d-flex align-items-center text-truncate"
                  >
                    User Retention
                  </Col>
                  <Col md={1} className="d-none d-sm-block">
                    <div className="card-actions float-right">
                      <span className="cursor-pointer mr-1">
                        <RefreshCw onClick={(e) => reloadData(e)} />
                      </span>
                    </div>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody className="py-3">
              <div className="chart chart-sm">
                {isLoading && (
                  <div
                    style={{ height: 400 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Spinner size="sm" className="mr-2 align-middle" />
                  </div>
                )}
                {!isLoading && (
                  <Line data={data} options={options} height={400} />
                )}
              </div>
            </CardBody>
          </Card>
          <RetentionSteps />
        </Col>
      </Row>
    </Container>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(RetentionStats);
