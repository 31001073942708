import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStore } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  Label,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import EditHelpCategoryTopics from "./EditHelpCategoryTopics";

const dataService = require("../../services/dataServices.js");

const options = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
];

const EditHelpCategory = () => {
  let { category_id } = useParams();
  const navigate = useHistory();

  const [activeTab, setActiveTab] = useState("1");

  const [name, setName] = useState("");
  const [sortBy, setSortBy] = useState(options[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);

  const [hasWritePermission, setHasWritePermission] = useState(false);

  function toggle(tab) {
    setActiveTab(tab);
  }

  function performAdd() {
    navigate.push("/system-content/faq/add-topic/" + category_id);
  }

  async function constructObject() {
    var obj = {
      category_id: category_id,
      name: name,
      sort_by: parseInt(sortBy.value),
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);
    setIsDisabled(true);

    var obj = await constructObject();
    await dataService.updateHelpCategory(obj);

    showToastr();
  }

  function performCancel() {
    navigate.push("/system-content/faq/");
  }

  useEffect(() => {
    async function loadData() {
      // load the role
      var data = await dataService.helpCategory(category_id);

      setName(data.name);

      var sortMatch = options.find((row) => {
        return row.value === "" + data.sort_by;
      });
      if (sortMatch) {
        setSortBy(sortMatch);
      }

      var perm = hasWritePermission();
      setHasWritePermission(perm);

      setIsLoading(false);
      setIsDisabled(!perm);
    }

    function hasWritePermission() {
      var result = false;

      if (currentUser.permissions) {
        const matchIndex = currentUser.permissions.findIndex(function (row) {
          return row === "help_write";
        });
        if (matchIndex > -1) {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    }

    loadData();
  }, [category_id, currentUser]);

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        setIsLoading(false);
        setIsDisabled(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Edit Category",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Edit Category
          {hasWritePermission && (
            <Button
              color="success"
              className="float-right"
              onClick={performAdd}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New Topic
            </Button>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/system-content/faq">Help / FAQ</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Category</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Category Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Topics
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <AvForm onValidSubmit={save}>
                    <Row>
                      <Col sm={6}>
                        <AvGroup>
                          <Label for="name">Name</Label>
                          <AvField
                            bsSize="lg"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter name"
                            value={name}
                            disabled={isDisabled}
                            onChange={(e) => setName(e.target.value)}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a name.",
                              },
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={6}>
                        <Label for="sort_by">Sort Rank</Label>
                        <FormGroup>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={options}
                            value={sortBy}
                            onChange={(e) => setSortBy(e)}
                            isDisabled={isDisabled}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <Button
                        color="light"
                        size="lg"
                        className="mr-2"
                        onClick={performCancel}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" size="lg" disabled={isDisabled}>
                        {isLoading && (
                          <Spinner size="sm" className="mr-2 align-middle" />
                        )}
                        Save
                      </Button>
                    </div>
                  </AvForm>
                </TabPane>
                <TabPane tabId="2">
                  <EditHelpCategoryTopics
                    category_id={category_id}
                    hasWritePermission={hasWritePermission}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditHelpCategory;
