import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Label, Spinner } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { toastr } from "react-redux-toastr";

const authService = require("../../services/authService.js");
const CookieController = require("../../services/CookieController.js");

const ResetPassword = () => {
  const [email, setEmail] = useState(
    CookieController.getCookie("last_email") ?? ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useHistory();

  async function attemptReset(e) {
    e.preventDefault();

    if (email !== "") {
      setIsLoading(true);
      await authService.forgotPassword(email);
      setIsLoading(false);
      showToastr();
    }
  }

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        navigate.push("/auth/sign-in");
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Reset Password",
      "If the email you provided is in our system, the user will receive a new temporary password along with instructions.",
      options
    );
  }

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead">Enter your email to reset your password.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <AvForm onValidSubmit={attemptReset}>
              <AvGroup>
                <Label>Email</Label>
                <AvField
                  bsSize="lg"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter an email.",
                    },
                    email: {
                      value: true,
                      errorMessage: "Please enter a valid email.",
                    },
                  }}
                />
              </AvGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" disabled={isLoading}>
                  {isLoading && (
                    <Spinner size="sm" className="mr-2 align-middle" />
                  )}
                  Reset Password
                </Button>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
