import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
  Input,
  InputGroupAddon,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import avatar from "../../assets/img/avatars/user.png";
import { useHistoryState } from "../../services/useHistoryState.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBars } from "@fortawesome/free-solid-svg-icons";

const dataService = require("../../services/dataServices.js");

const MemberListActive = (props) => {
  const [currentPage, setCurrentPage] = useHistoryState(
    "deleted_currentPage",
    1
  );
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useHistoryState(
    "deleted_sortField",
    "created_date"
  );
  const [sortOrder, setSortOrder] = useHistoryState(
    "deleted_sortOrder",
    "desc"
  );
  const [sizePerPage, setSizePerPage] = useHistoryState(
    "deleted_sizePerPage",
    10
  );
  const [searchFilter, setSearchFilter] = useHistoryState(
    "deleted_setSearchFilter",
    ""
  );

  const [selectedRow, setSelectedRow] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const [showActivateModal, setShowActivateModal] = useState(false);

  const navigate = useHistory();

  const tableLink = (cell, row) => {
    var userAvatar = row.avatar_url === "" ? null : row.avatar_url;
    var img = userAvatar ?? avatar;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1"
          style={{ width: 30, height: 30 }}
          alt={row.name}
        />
        <Link to={"/members/edit/" + row.user_id}>{row.user_id}</Link>
      </React.Fragment>
    );
  };

  const tableActions = (cell, row) => {
    function activateRow() {
      setSelectedRow(row);
      setShowActivateModal(true);
    }

    function editRow() {
      performEdit(row.user_id);
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={editRow}>Edit</DropdownItem>
          <DropdownItem onClick={activateRow}>Undelete</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const tableColumns = [
    {
      dataField: "user_id",
      text: "#",
      sort: true,
      formatter: tableLink,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "display_name",
      text: "Display Name",
      sort: true,
    },
    {
      dataField: "last_activity_date",
      text: "Last Login",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      dataField: "created_date",
      text: "Joined",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  async function loadData(filter, page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.memberList(
      2,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setTotalRecords(info.record_count);
    setShowLoading(false);
    setCurrentData(info.list);
  }

  function performKey(e) {
    if (e.charCode === 13) {
      loadData(searchFilter, currentPage, sizePerPage, sortField, sortOrder);
    }
  }

  function performSearch() {
    loadData(searchFilter, currentPage, sizePerPage, sortField, sortOrder);
  }

  function performEdit(user_id) {
    navigate.push("/members/edit/" + user_id);
  }

  function pageChanged(type, newState) {
    if (firstLoad === true) {
      setFirstLoad(false);
      loadData(searchFilter, currentPage, sizePerPage, sortField, sortOrder);
    } else {
      if (type === "pagination") {
        if (newState.sizePerPage !== sizePerPage) {
          // normally, we will need to make a call out
          setCurrentPage(1);
          setSizePerPage(newState.sizePerPage);
          loadData(searchFilter, 1, newState.sizePerPage, sortField, sortOrder);
        } else if (newState.page !== currentPage) {
          // normally, we will need to make a call out to get the passed page of data
          setCurrentPage(newState.page);
          loadData(
            searchFilter,
            newState.page,
            sizePerPage,
            sortField,
            sortOrder
          );
        }
      } else if (type === "sort") {
        // normally, we will need to make a call out

        if (newState.sortField !== sortField) {
          setSortField(newState.sortField);
        } else if (newState.sortOrder !== sortOrder) {
          setSortOrder(newState.sortOrder);
        }

        setCurrentPage(1);
        loadData(
          searchFilter,
          1,
          sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
  }

  function toggleActivate() {
    setShowActivateModal(!showActivateModal);
  }

  async function performActivate() {
    await dataService.activateMember(selectedRow.user_id);
    setShowActivateModal(false);

    setCurrentPage(1);
    loadData(searchFilter, 1, sizePerPage, sortField, sortOrder);
  }

  return (
    <React.Fragment>
      <div className="mb-3">
        <InputGroup>
          <Input
            placeholder="Search..."
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            onKeyPress={(e) => performKey(e)}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          <InputGroupAddon addonType="append" color="primary">
            <Button onClick={performSearch}>
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
              Search
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <BootstrapTable
        keyField="user_id"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"activate"}
        isOpen={showActivateModal}
        toggle={() => toggleActivate()}
      >
        <ModalHeader toggle={() => toggleActivate()}>
          Undelete Member{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">Are you sure you want to undelete this member?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleActivate()}>
            Cancel
          </Button>{" "}
          <Button color="success" onClick={() => performActivate()}>
            Undelete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default MemberListActive;
