import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const dataService = require("../../services/dataServices.js");

const options = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
];

const AddHelpCategory = () => {
  const navigate = useHistory();
  const [name, setName] = useState("");
  const [sortBy, setSortBy] = useState(options[0]);
  const [isLoading, setIsLoading] = useState(false);

  async function constructObject() {
    var obj = {
      name: name,
      sort_by: parseInt(sortBy.value),
    };

    return obj;
  }

  async function save() {
    setIsLoading(true);
    var obj = await constructObject();
    var result = await dataService.addHelpCategory(obj);

    showToastr(result.result.category_id);
  }

  function performCancel() {
    navigate.goBack();
  }

  function showToastr(category_id) {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {
        navigate.push("/system-content/faq/edit/" + category_id);
        setIsLoading(false);
      },
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Add Category",
      "Your changes have been saved successfully.",
      options
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add Category</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/system-content/faq">Help / FAQ</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Category</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={save}>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label for="name">Name</Label>
                      <AvField
                        bsSize="lg"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        value={name}
                        disabled={isLoading}
                        onChange={(e) => setName(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a name.",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <Label for="sort_by">Sort Rank</Label>
                    <FormGroup>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={options}
                        value={sortBy}
                        onChange={(e) => setSortBy(e)}
                        isDisabled={isLoading}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    color="light"
                    size="lg"
                    className="mr-2"
                    onClick={performCancel}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading && (
                      <Spinner size="sm" className="mr-2 align-middle" />
                    )}
                    Save
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddHelpCategory;
