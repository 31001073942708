import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
  Input,
  InputGroupAddon,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import avatar from "../../assets/img/avatars/user.png";
import { useHistoryState } from "../../services/useHistoryState.js";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { toastr } from "react-redux-toastr";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBars } from "@fortawesome/free-solid-svg-icons";
import { icon } from "@fortawesome/fontawesome-svg-core";

const dataService = require("../../services/dataServices.js");

const MemberListActive = (props) => {
  const [newPassword, setNewPassword] = useState("");

  const [currentPage, setCurrentPage] = useHistoryState(
    "active_currentPage",
    1
  );
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useHistoryState(
    "active_sortField",
    "created_date"
  );
  const [sortOrder, setSortOrder] = useHistoryState("active_sortOrder", "desc");
  const [sizePerPage, setSizePerPage] = useHistoryState(
    "active_sizePerPage",
    10
  );
  const [searchFilter, setSearchFilter] = useHistoryState(
    "active_setSearchFilter",
    ""
  );

  const [selectedRow, setSelectedRow] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);

  const navigate = useHistory();

  const tableLink = (cell, row) => {
    var userAvatar = row.avatar_url === "" ? null : row.avatar_url;
    var img = userAvatar ?? avatar;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1"
          style={{ width: 30, height: 30 }}
          alt={row.name}
        />
        <Link to={"/members/edit/" + row.user_id}>{row.user_id}</Link>
      </React.Fragment>
    );
  };

  const AppDownloaded = (cell, row) => {
    var lyfsupportImg = <></>;
    var lyfImg = <></>;
    if (row.last_lyfSupport_activity_date > 0) {
      lyfsupportImg = (
        <img
          src="/img/LyfSupportApp.svg"
          style={{ width: 30 }}
          alt="LyfSupport App"
        />
      );
    }

    if (row.lyf_app_used) {
      lyfImg = (
        <img src="/img/LyfApp.svg" style={{ width: 30 }} alt="Lyf App" />
      );
    }
    return (
      <React.Fragment>
        {lyfImg} {lyfsupportImg}
      </React.Fragment>
    );
  };

  const JoinedWith = (cell, row) => {
    var icon = <></>;
    if (row.type_id === 0) {
      icon = <img src="/img/LyfApp.svg" style={{ width: 30 }} alt="Lyf App" />;
    } else {
      icon = (
        <img
          src="/img/LyfSupportApp.svg"
          style={{ width: 30 }}
          alt="LyfSupport App"
        />
      );
    }

    return <React.Fragment>{icon}</React.Fragment>;
  };

  const SignupBy = (cell, row) => {
    var provider = "";
    switch (row.provider) {
      case 0:
        provider = "Facebook";
        break;
      case 1:
        provider = "Apple";
        break;
      case 2:
        provider = "Google";
        break;
      case 3:
        provider = "Email Address";
        break;
      default:
        provider = "Email Address";
        break;
    }

    return <React.Fragment>{provider}</React.Fragment>;
  };

  const tableActions = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    function editRow() {
      performEdit(row.user_id);
    }

    function blockRow() {
      setSelectedRow(row);
      setShowBlockModal(true);
    }

    function resetRow() {
      setSelectedRow(row);
      setShowResetModal(true);
    }

    function changeRow() {
      setSelectedRow(row);
      setShowChangeModal(true);
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={editRow}>Edit</DropdownItem>
          <DropdownItem onClick={deleteRow}>Delete</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={blockRow}>Block</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={resetRow}>Send Password Email</DropdownItem>
          <DropdownItem onClick={changeRow}>Change Password</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const tableColumns = [
    {
      dataField: "user_id",
      text: "#",
      sort: true,
      formatter: tableLink,
    },
    {
      text: "App Downloaded",
      sort: false,
      formatter: AppDownloaded,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "display_name",
      text: "Display Name",
      sort: true,
    },
    {
      dataField: "last_activity_date",
      text: "Last Login",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      text: "Joined With",
      sort: false,
      formatter: JoinedWith,
    },
    {
      dataField: "created_date",
      text: "Joined",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      text: "Signup By",
      sort: true,
      formatter: SignupBy,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  async function loadData(filter, page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.memberList(
      1,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setTotalRecords(info.record_count);
    setShowLoading(false);
    setCurrentData(info.list);
  }

  function performKey(e) {
    if (e.charCode === 13) {
      loadData(searchFilter, currentPage, sizePerPage, sortField, sortOrder);
    }
  }

  function performSearch() {
    loadData(searchFilter, currentPage, sizePerPage, sortField, sortOrder);
  }

  function performEdit(user_id) {
    navigate.push("/members/edit/" + user_id);
  }

  function pageChanged(type, newState) {
    if (firstLoad === true) {
      setFirstLoad(false);
      loadData(searchFilter, currentPage, sizePerPage, sortField, sortOrder);
    } else {
      if (type === "pagination") {
        if (newState.sizePerPage !== sizePerPage) {
          // normally, we will need to make a call out
          setCurrentPage(1);
          setSizePerPage(newState.sizePerPage);
          loadData(searchFilter, 1, newState.sizePerPage, sortField, sortOrder);
        } else if (newState.page !== currentPage) {
          // normally, we will need to make a call out to get the passed page of data
          setCurrentPage(newState.page);
          loadData(
            searchFilter,
            newState.page,
            sizePerPage,
            sortField,
            sortOrder
          );
        }
      } else if (type === "sort") {
        // normally, we will need to make a call out

        if (newState.sortField !== sortField) {
          setSortField(newState.sortField);
        } else if (newState.sortOrder !== sortOrder) {
          setSortOrder(newState.sortOrder);
        }

        setCurrentPage(1);
        loadData(
          searchFilter,
          1,
          sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
  }

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    await dataService.deleteMember(selectedRow.user_id);
    setShowDeleteModal(false);

    setCurrentPage(1);
    loadData(searchFilter, 1, sizePerPage, sortField, sortOrder);
  }

  function toggleBlock() {
    setShowBlockModal(!showBlockModal);
  }

  async function performBlock() {
    await dataService.blockMember(selectedRow.user_id);
    setShowBlockModal(false);

    setCurrentPage(1);
    loadData(searchFilter, 1, sizePerPage, sortField, sortOrder);
  }

  function toggleReset() {
    setShowResetModal(!showResetModal);
  }

  async function performReset() {
    await dataService.resetMemberPassword(selectedRow.user_id);
    setShowResetModal(false);
    showToastr();
  }

  function toggleChange() {
    setNewPassword("");
    setShowChangeModal(!showChangeModal);
  }

  async function performChange() {
    await dataService.changeMemberPassword(selectedRow.user_id, newPassword);
    setShowChangeModal(false);
    showChangeToastr();
  }

  function showToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {},
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Reset Password",
      "The member's password was reset successfully.",
      options
    );
  }

  function showChangeToastr() {
    const options = {
      timeOut: 3000,
      showCloseButton: true,
      progressBar: false,
      position: "top-center",
      onHideComplete: () => {},
    };
    const toastrInstance = toastr.success;
    toastrInstance(
      "Change Password",
      "The member's password was changed successfully.",
      options
    );
  }

  return (
    <React.Fragment>
      <div className="mb-3">
        <InputGroup>
          <Input
            placeholder="Search..."
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            onKeyPress={(e) => performKey(e)}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          <InputGroupAddon addonType="append" color="primary">
            <Button onClick={performSearch}>
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
              Search
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <BootstrapTable
        keyField="user_id"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"delete"}
        isOpen={showDeleteModal}
        toggle={() => toggleDelete()}
      >
        <ModalHeader toggle={() => toggleDelete()}>
          Deactivate Member{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to deactivate this member?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDelete()}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => performDelete()}>
            Deactivate
          </Button>
        </ModalFooter>
      </Modal>
      <Modal key={"block"} isOpen={showBlockModal} toggle={() => toggleBlock()}>
        <ModalHeader toggle={() => toggleBlock()}>
          Block Member{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">Are you sure you want to block this member?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleBlock()}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => performBlock()}>
            Block
          </Button>
        </ModalFooter>
      </Modal>
      <Modal key={"reset"} isOpen={showResetModal} toggle={() => toggleReset()}>
        <ModalHeader toggle={() => toggleReset()}>
          Send Password Email{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to reset this member's password? They will
            receive an email with a new password and instructions.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleReset()}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={() => performReset()}>
            Reset Password
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        key={"change"}
        isOpen={showChangeModal}
        toggle={() => toggleChange()}
      >
        <ModalHeader toggle={() => toggleChange()}>
          Change Member Password{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Enter a new password for the user below. Be sure to let them know
            what their new password is.
          </p>
          <AvForm>
            <Row>
              <Col>
                <AvGroup>
                  <AvField
                    bsSize="lg"
                    type="text"
                    name="password"
                    id="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </AvGroup>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleChange()}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={() => performChange()}>
            Change Password
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default MemberListActive;
