import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import avatar from "../../assets/img/avatars/user.png";
import { useHistoryState } from "../../services/useHistoryState.js";

const dataService = require("../../services/dataServices.js");

const EditMemberHiddenMembers = (props) => {
  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const tableActions1 = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowUnblockModal(true);
    }

    return (
      <Button color="warning" size="sm" onClick={deleteRow}>
        Unblock
      </Button>
    );
  };

  const authorDisplay = (cell, row) => {
    var userAvatar =
      row.creator_avatar_url === "" ? null : row.creator_avatar_url;
    var img = userAvatar ?? avatar;
    var nm =
      row.creator_display_name === ""
        ? row.creator_name
        : row.creator_display_name;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1"
          style={{ width: 20, height: 20 }}
          alt={nm}
        />
        <Link to={"/members/edit/" + row.user_id}>{row.user_id}</Link>
      </React.Fragment>
    );
  };

  const [currentPage, setCurrentPage] = useHistoryState(
    "editMemberHiddenExperiences_currentPage_" + props.user_id,
    1
  );
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useHistoryState(
    "editMemberHiddenExperiences_sortField_" + props.user_id,
    "event_date"
  );
  const [sortOrder, setSortOrder] = useHistoryState(
    "editMemberHiddenExperiences_sortOrder_" + props.user_id,
    "desc"
  );
  const [sizePerPage, setSizePerPage] = useHistoryState(
    "editMemberHiddenExperiences_sizePerPage_" + props.user_id,
    5
  );
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const tableColumns = [
    {
      dataField: "user_id",
      text: "#",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      editable: false,
    },
    {
      dataField: "event_date",
      text: "Blocked on",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "display_name",
      text: "Display Name",
      sort: true,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions1,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  function toggleUnblock() {
    setShowUnblockModal(!showUnblockModal);
  }

  async function performUnblock() {
    setShowUnblockModal(false);
    setShowLoading(true);
    setCurrentData([]);

    // ok, delete the row
    await dataService.memberUnblockMember(props.user_id, selectedRow.user_id);

    // refresh the table
    setCurrentPage(1);
    await loadData(1, sizePerPage, sortField, sortOrder);
  }

  async function loadData(page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.memberHiddenMembersList(
      props.user_id,
      page,
      pageSize,
      sort,
      order
    );
    if (info.record_count !== undefined) {
      setTotalRecords(info.record_count);
      setShowLoading(false);
      setCurrentData(info.list);
    }
  }

  function pageChanged(type, newState) {
    if (firstLoad === true) {
      setFirstLoad(false);
      loadData(currentPage, sizePerPage, sortField, sortOrder);
    } else {
      if (type === "pagination") {
        if (newState.sizePerPage !== sizePerPage) {
          // normally, we will need to make a call out
          setCurrentPage(1);
          setSizePerPage(newState.sizePerPage);
          loadData(1, newState.sizePerPage, sortField, sortOrder);
        } else if (newState.page !== currentPage) {
          // normally, we will need to make a call out to get the passed page of data
          setCurrentPage(newState.page);
          loadData(newState.page, sizePerPage, sortField, sortOrder);
        }
      } else if (type === "sort") {
        // normally, we will need to make a call out

        if (newState.sortField !== sortField) {
          setSortField(newState.sortField);
        } else if (newState.sortOrder !== sortOrder) {
          setSortOrder(newState.sortOrder);
        }

        setCurrentPage(1);
        loadData(1, sizePerPage, newState.sortField, newState.sortOrder);
      }
    }
  }

  useEffect(() => {
    async function reloadData() {
      console.log("value changed!");

      setShowLoading(true);
      setCurrentData([]);

      var info = await dataService.memberHiddenMembersList(
        props.user_id,
        1,
        5,
        "event_date",
        "desc"
      );
      if (info.record_count !== undefined) {
        setTotalRecords(info.record_count);
        setShowLoading(false);
        setCurrentData(info.list);
      }
    }

    if (firstLoad === false) {
      reloadData();
    }
  }, [props.user_id, firstLoad]);

  return (
    <React.Fragment>
      <h4 className="mt-3">Blocked Members</h4>
      <BootstrapTable
        keyField="user_id"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"unblock"}
        isOpen={showUnblockModal}
        toggle={() => toggleUnblock()}
      >
        <ModalHeader toggle={() => toggleUnblock()}>
          Unblock Member{selectedRow ? " - " + selectedRow.name : ""}
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">Are you sure you want to unblock this member?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleUnblock()}>
            Cancel
          </Button>{" "}
          <Button color="warning" onClick={() => performUnblock()}>
            Unblock
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditMemberHiddenMembers;
