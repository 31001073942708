import React from "react";
import { useDispatch, useStore } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { deauthenticate } from "../redux/actions/authenticationActions";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const NavbarToggle = () => {
  const dispatch = useDispatch();

  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
};

const NavbarDropdowns = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const isOnRight = store.getState().sidebar.isOnRight;

  return (
    <Collapse navbar>
      <Nav className={!isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              onClick={() => {
                dispatch(deauthenticate());
              }}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                fixedWidth
                className="mr-2 align-middle"
              />
              Sign out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
};

const NavbarComponent = () => {
  const store = useStore();
  const isOnRight = store.getState().sidebar.isOnRight;

  return (
    <Navbar expand className="navbar-theme">
      {!isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
          <NavbarDropdowns />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavbarDropdowns />
          <NavbarToggle />
        </React.Fragment>
      )}
    </Navbar>
  );
};

export default NavbarComponent;
/*export default connect(store => ({
	sidebar: store.sidebar
}))(NavbarComponent);*/
