import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";

const dataService = require("../../services/dataServices.js");

const EditMemberDevices = (props) => {
  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const deviceActionDisplay = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteDeviceModal(true);
    }

    function verifyRow() {
      setSelectedRow(row);
      setShowVerifyDeviceModal(true);
    }

    function denyRow() {
      setSelectedRow(row);
      setShowDenyDeviceModal(true);
    }

    if (row.status_id === 2) {
      return (
        <React.Fragment>
          <Button color="success" size="sm" onClick={verifyRow}>
            Verify
          </Button>
          &nbsp;
          <Button color="warning" size="sm" onClick={denyRow}>
            Deny
          </Button>
        </React.Fragment>
      );
    } else {
      return (
        <span style={{ float: "right" }}>
          <Button color="warning" size="sm" onClick={deleteRow}>
            Delete
          </Button>
        </span>
      );
    }
  };

  const deviceTypeDisplay = (cell, row) => {
    if (row.type_id === 1) {
      return <React.Fragment>iOS</React.Fragment>;
    } else if (row.type_id === 2) {
      return <React.Fragment>Android</React.Fragment>;
    } else {
      return <React.Fragment>Web</React.Fragment>;
    }
  };

  const playerIDDisplay = (cell, row) => {
    return (
      <a
        target="_new"
        href={
          "https://app.onesignal.com/apps/e07d8b8f-66d1-445c-8122-47e26980c1ec/players?user_search%5Bby%5D=id&user_search%5Bterm%5D=" +
          row.player_id
        }
      >
        {row.player_id}
      </a>
    );
  };

  const statusDisplay = (cell, row) => {
    if (row.status_id === 1) {
      return <React.Fragment>Active</React.Fragment>;
    } else if (row.status_id === 2) {
      return <React.Fragment>Pending</React.Fragment>;
    } else {
      return <React.Fragment>Blocked</React.Fragment>;
    }
  };

  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);
  const [showDenyDeviceModal, setShowDenyDeviceModal] = useState(false);
  const [showVerifyDeviceModal, setShowVerifyDeviceModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const tableColumns = [
    {
      isDummyField: true,
      dataField: "type_id",
      text: "Type",
      sort: false,
      formatter: deviceTypeDisplay,
    },
    {
      dataField: "model",
      text: "Model",
      sort: false,
    },
    {
      dataField: "os_version",
      text: "OS Version",
      sort: false,
    },
    {
      dataField: "app_version",
      text: "App Version",
      sort: false,
    },
    {
      dataField: "player_id",
      text: "One Signal ID",
      sort: false,
      formatter: playerIDDisplay,
    },
    {
      dataField: "status_id",
      text: "Status",
      sort: false,
      formatter: statusDisplay,
    },
    {
      dataField: "created_date",
      text: "Date",
      sort: false,
      formatter: dateDisplay,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: deviceActionDisplay,
      headerStyle: () => {
        return { width: "125px" };
      },
    },
  ];

  function toggleDeleteDevice() {
    setShowDeleteDeviceModal(!showDeleteDeviceModal);
  }

  function toggleDenyDevice() {
    setShowDenyDeviceModal(!showDenyDeviceModal);
  }

  function toggleVerifyDevice() {
    setShowVerifyDeviceModal(!showVerifyDeviceModal);
  }

  async function performDeleteDevice() {
    // add das gift yo
    await dataService.memberDeleteDevice(
      props.user_id,
      selectedRow.device_token
    );

    // done!
    setShowDeleteDeviceModal(false);
    loadData();
  }

  async function performDenyDevice() {
    // add das gift yo
    await dataService.memberBlockDevice(
      props.user_id,
      selectedRow.device_token
    );

    // done!
    setShowDenyDeviceModal(false);
    loadData();
  }

  async function performVerifyDevice() {
    // add das gift yo
    await dataService.memberVerifyDevice(
      props.user_id,
      selectedRow.device_token
    );

    // done!
    setShowVerifyDeviceModal(false);
    loadData();
  }

  async function loadData() {
    setShowLoading(true);
    setCurrentData([]);
    var info = await dataService.memberDeviceList(props.user_id);
    if (info !== undefined) {
      setCurrentData(info);
    }
    setShowLoading(false);
  }

  useEffect(() => {
    async function loadData() {
      setShowLoading(true);
      setCurrentData([]);
      var info = await dataService.memberDeviceList(props.user_id);
      if (info !== undefined) {
        setCurrentData(info);
      }
      setShowLoading(false);
    }

    loadData();
  }, [props.user_id]);

  return (
    <React.Fragment>
      <BootstrapTable
        keyField="device_token"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: "created_date", order: "desc" }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: false }}
        bordered={false}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"deleteDevice"}
        isOpen={showDeleteDeviceModal}
        toggle={() => toggleDeleteDevice()}
      >
        <ModalHeader toggle={() => toggleDeleteDevice()}>
          Delete Device
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to delete this device? The user's device will
            reset and be created again when they next login.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDeleteDevice()}>
            Cancel
          </Button>{" "}
          <Button color="warning" onClick={() => performDeleteDevice()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        key={"denyDevice"}
        isOpen={showDenyDeviceModal}
        toggle={() => toggleDenyDevice()}
      >
        <ModalHeader toggle={() => toggleDenyDevice()}>Deny Device</ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to deny access for this device? Once done, the
            user will not be able to access the application with this device.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDenyDevice()}>
            Cancel
          </Button>{" "}
          <Button color="warning" onClick={() => performDenyDevice()}>
            Deny
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        key={"verifyDevice"}
        isOpen={showVerifyDeviceModal}
        toggle={() => toggleVerifyDevice()}
      >
        <ModalHeader toggle={() => toggleVerifyDevice()}>
          Verify Device
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to manually verify this device? Once done, the
            user will not be required to verify on their own.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleVerifyDevice()}>
            Cancel
          </Button>{" "}
          <Button color="success" onClick={() => performVerifyDevice()}>
            Verify
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditMemberDevices;
