import * as types from "../constants";

const initialState = {
  isAuthenticated: false,
  user: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.IS_AUTHENTICATED_TOGGLE:
      return {
        ...state,
        isAuthenticated: !state.isAuthenticated,
      };
    case types.IS_AUTHENTICATED_TRUE:
      return Object.assign({}, state, {
        isAuthenticated: true,
        user: action.user,
      });
    case types.IS_AUTHENTICATED_FALSE:
      return Object.assign({}, state, {
        isAuthenticated: false,
        user: null,
      });

    default:
      return state;
  }
}
