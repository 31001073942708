import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap";
import { RefreshCw } from "react-feather";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import DateTime from "react-datetime";
import { CSVLink } from "react-csv";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const dataService = require("../../services/dataServices.js");

const MonthlyUserStats = (props) => {
  const [data, setData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [csvData, setCSVData] = useState([]);

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "rgba(255,255,255,0.8)",
          },
          reverse: true,
          gridLines: {
            color: "rgba(255,255,255,0.0)",
            zeroLineColor: "rgba(255,255,255,1.0)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 50,
            fontColor: "rgba(255,255,255,0.8)",
          },
          display: true,
          gridLines: {
            color: "rgba(255,255,255,0.5)",
            zeroLineColor: "rgba(255,255,255,1.0)",
          },
        },
      ],
    },
  };

  useEffect(() => {
    async function loadData() {
      var today = DateTime.moment();
      var weekBegin = DateTime.moment().add(-6, "day");

      setStartDate(weekBegin);
      setEndDate(today);

      setIsLoading(true);

      var dta = await dataService.monthlyUserData(
        weekBegin.format("YYYY-MM-DD"),
        today.format("YYYY-MM-DD")
      );
      setData(dta);

      // parse and construct the CSV data
      var raw = [];
      if (dta.datasets && dta.datasets.length > 0) {
        for (var j = 0; j < dta.labels.length; j++) {
          var date_string = dta.labels[j];
          var count = dta.datasets[0].data[j];

          raw.push({ Date: date_string, Count: count });
        }
      }
      setCSVData(raw);

      setIsLoading(false);
    }

    loadData();
  }, []);

  async function updateStartDate(dt) {
    setStartDate(dt);
    await getData(dt.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
  }

  async function updateEndDate(dt) {
    setEndDate(dt);
    await getData(startDate.format("YYYY-MM-DD"), dt.format("YYYY-MM-DD"));
  }

  async function getData(sdate, edate) {
    setIsLoading(true);
    var dta = await dataService.monthlyUserData(sdate, edate);
    setData(dta);

    // parse and construct the CSV data
    var raw = [];
    if (dta.datasets && dta.datasets.length > 0) {
      for (var j = 0; j < dta.labels.length; j++) {
        var date_string = dta.labels[j];
        var count = dta.datasets[0].data[j];

        raw.push({ Date: date_string, Count: count });
      }
    }
    setCSVData(raw);

    setIsLoading(false);
  }

  async function reloadData(e) {
    e.preventDefault();
    await getData(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
  }

  function getTotal() {
    if (data && data.datasets && data.datasets.length > 0) {
      return data.datasets[0].data[data.datasets[0].data.length - 1]; //data.datasets[0].data.reduce((prev, next) => prev + next, 0);
    }

    return 0;
  }

  function getLastTotal() {
    if (data && data.datasets && data.datasets.length > 1) {
      return data.datasets[1].data[data.datasets[0].data.length - 1]; //data.datasets[1].data.reduce((prev, next) => prev + next, 0);
    }

    return 0;
  }

  function percentage() {
    var new_total = getTotal();
    var old_total = getLastTotal();

    if (old_total === 0) {
      return "+100%";
    } else {
      var delta = Math.floor((100 * (new_total - old_total)) / old_total);
      return delta > 0 ? "+" + delta + "%" : delta + "%";
    }
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Monthly User Stats
          {isLoading && (
            <Button
              className="btn btn-md btn-primary float-right"
              disabled={true}
            >
              <Spinner size="sm" className="mr-2 align-middle" />
              Export to CSV
            </Button>
          )}
          {!isLoading && (
            <CSVLink
              data={csvData}
              asyncOnClick={true}
              filename={"data.csv"}
              className="btn btn-md btn-primary float-right"
            >
              Export to CSV
            </CSVLink>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Monthly User Stats</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card className="flex-fill w-100 darkgray">
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                <Row>
                  <Col
                    xs={12}
                    md={3}
                    className="d-flex align-items-center text-truncate"
                  >
                    Monthly Active Users
                  </Col>
                  <Col xs={12} md={4} className="mb-2 mb-md-0">
                    <Row>
                      <Col
                        xs={4}
                        md={5}
                        className="d-flex align-items-center text-truncate"
                      >
                        Start Date
                      </Col>
                      <Col xs={8} md={7}>
                        <DateTime
                          input={true}
                          value={startDate}
                          timeFormat={false}
                          inputProps={{
                            placeholder: "Start date",
                            disabled: isLoading,
                            className: "form-control form-control-md",
                          }}
                          closeOnSelect={true}
                          onChange={(e) => updateStartDate(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className="mb-2 mb-md-0">
                    <Row>
                      <Col
                        xs={4}
                        md={5}
                        className="d-flex align-items-center text-truncate"
                      >
                        End Date
                      </Col>
                      <Col xs={8} md={7}>
                        <DateTime
                          input={true}
                          value={endDate}
                          timeFormat={false}
                          inputProps={{
                            placeholder: "End date",
                            disabled: isLoading,
                            className: "form-control form-control-md",
                          }}
                          closeOnSelect={true}
                          onChange={(e) => updateEndDate(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={1} className="d-none d-sm-block">
                    <div className="card-actions float-right">
                      <span className="cursor-pointer mr-1">
                        <RefreshCw onClick={(e) => reloadData(e)} />
                      </span>
                    </div>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody className="py-3">
              {!isLoading &&
                data &&
                data.datasets &&
                data.datasets.length > 0 && (
                  <Row className="data-banner-large">
                    <Col>
                      <h1 className="float-left">{getTotal()}</h1>
                      <h6 className="text-mediumgreen">{percentage()}</h6>
                    </Col>
                  </Row>
                )}
              <div className="chart chart-sm">
                {isLoading && (
                  <div
                    style={{ height: 400 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Spinner size="sm" className="mr-2 align-middle" />
                  </div>
                )}
                {!isLoading && (
                  <Line data={data} options={options} height={400} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(MonthlyUserStats);
