const CookieController = require("../services/CookieController.js");
var Base64 = require("js-base64").Base64;

export const startSessionForUser = async (email, password) => {
  const varBody = {
    email: email,
    password: password,
  };

  return await fetch(process.env.REACT_APP_API_DOMAIN + "admin/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(varBody),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        if (result.user_id !== null) {
          // save the header in a cookie
          const encodedAuthString = Base64.encode(email + ":" + password);
          const finalAuthHeader = "Admin " + encodedAuthString;
          CookieController.setCookie("basiccookie", finalAuthHeader, 1);
          // localStorage.setItem("user", JSON.stringify(result));

          return result;
        } else {
          return null;
        }
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};

export const forgotPassword = async (email) => {
  return await fetch(
    process.env.REACT_APP_OLD_API_DOMAIN +
      "admin/user/" +
      encodeURIComponent(email) +
      "/resetpassword",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  /*.then(res => res.text()).then((result) => {
		console.log(result);
	}, (error) => {
		console.log(error);
		return null;
	});*/
};
