import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import avatar from "../../assets/img/avatars/user.png";

const dataService = require("../../services/dataServices.js");

const EditTeamMemberUsers = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState("user_name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sizePerPage, setSizePerPage] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const tableActions = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    return (
      <Button color="warning" size="sm" onClick={deleteRow}>
        Remove
      </Button>
    );
  };

  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const tableLink1 = (cell, row) => {
    var userAvatar = row.avatar_url === "" ? null : row.avatar_url;
    var img = userAvatar ?? avatar;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1"
          style={{ width: 30, height: 30 }}
          alt={row.name}
        />
        <Link to={"/members/edit/" + row.user_id}>{row.user_id}</Link>
      </React.Fragment>
    );
  };

  const tableColumns = [
    {
      dataField: "user_id",
      text: "#",
      sort: true,
      formatter: tableLink1,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "display_name",
      text: "Display Name",
      sort: true,
    },
    {
      dataField: "last_activity_date",
      text: "Last Login",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      dataField: "created_date",
      text: "Joined",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions,
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    setShowLoading(true);
    setCurrentData([]);

    // ok, delete the row
    await dataService.deleteTeamMemberUser(
      props.team_member_id,
      selectedRow.user_id
    );

    // refresh the table
    setCurrentPage(1);
    await loadData(1, sizePerPage, sortField, sortOrder);
  }

  async function loadData(page, pageSize, sort, order) {
    setShowLoading(true);
    setCurrentData([]);

    var info = await dataService.teamMemberUserList(
      props.team_member_id,
      page,
      pageSize,
      sort,
      order
    );
    setTotalRecords(info.record_count);
    setShowLoading(false);
    setCurrentData(info.list);
  }

  function pageChanged(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage) {
        // normally, we will need to make a call out
        setCurrentPage(1);
        setSizePerPage(newState.sizePerPage);
        loadData(1, newState.sizePerPage, sortField, sortOrder);
      } else if (newState.page !== currentPage) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage(newState.page);
        loadData(newState.page, sizePerPage, sortField, sortOrder);
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField) {
        setSortField(newState.sortField);
      } else if (newState.sortOrder !== sortOrder) {
        setSortOrder(newState.sortOrder);
      }

      setCurrentPage(1);
      loadData(1, sizePerPage, newState.sortField, newState.sortOrder);
    }
  }

  useEffect(() => {
    async function loadData() {
      // reset
      setCurrentPage(1);
      setSizePerPage(10);
      setSortField("user_name");
      setSortOrder("asc");

      // show spinner
      setShowLoading(true);
      setCurrentData([]);

      // load data
      var info = await dataService.teamMemberUserList(
        props.team_member_id,
        1,
        10,
        "user_name",
        "asc"
      );
      setTotalRecords(info.record_count);
      setShowLoading(false);
      setCurrentData(info.list);
    }

    loadData();
  }, [props.team_member_id]);

  return (
    <React.Fragment>
      <BootstrapTable
        keyField="user_id"
        data={currentData}
        columns={tableColumns}
        sort={{ dataField: sortField, order: sortOrder }}
        bootstrap4
        striped
        wrapperClasses="table_wrapper"
        classes="table_class"
        remote={{ pagination: true, filter: true, sort: true }}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          sizePerPageList: [5, 10, 25, 50],
          page: currentPage,
          totalSize: totalRecords,
        })}
        onTableChange={pageChanged}
        noDataIndication={() =>
          showLoading ? (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div
              style={{ height: 250 }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>There are no records</span>
            </div>
          )
        }
      />
      <Modal
        key={"unhide"}
        isOpen={showDeleteModal}
        toggle={() => toggleDelete()}
      >
        <ModalHeader toggle={() => toggleDelete()}>Delete Topic</ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure you want to remove this user from the team member?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => toggleDelete()}>
            Cancel
          </Button>{" "}
          <Button color="warning" onClick={() => performDelete()}>
            Remove
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditTeamMemberUsers;
