import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
  Input,
  InputGroupAddon,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import classnames from "classnames";
import avatar from "../../assets/img/avatars/user.png";

const dataService = require("../../services/dataServices.js");

const ExperienceList = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentData1, setCurrentData1] = useState([]);
  const [showLoading1, setShowLoading1] = useState(true);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [sortField1, setSortField1] = useState("created_date");
  const [sortOrder1, setSortOrder1] = useState("desc");
  const [sizePerPage1, setSizePerPage1] = useState(10);

  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentData2, setCurrentData2] = useState([]);
  const [showLoading2, setShowLoading2] = useState(true);
  const [totalRecords2, setTotalRecords2] = useState(0);
  const [sortField2, setSortField2] = useState("created_date");
  const [sortOrder2, setSortOrder2] = useState("desc");
  const [sizePerPage2, setSizePerPage2] = useState(10);

  const [currentPage3, setCurrentPage3] = useState(1);
  const [currentData3, setCurrentData3] = useState([]);
  const [showLoading3, setShowLoading3] = useState(true);
  const [totalRecords3, setTotalRecords3] = useState(0);
  const [sortField3, setSortField3] = useState("created_date");
  const [sortOrder3, setSortOrder3] = useState("desc");
  const [sizePerPage3, setSizePerPage3] = useState(10);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showUndeleteModal, setShowUndeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [searchFilter1, setSearchFilter1] = useState("");
  const [searchFilter2, setSearchFilter2] = useState("");
  const [searchFilter3, setSearchFilter3] = useState("");

  const store = useStore();
  const currentUser =
    store.getState().authentication.user ??
    (localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null);
  const navigate = useHistory();

  const badgeOptions = [
    { value: "0", label: "None" },
    { value: "1", label: "Featured" },
    { value: "2", label: "Marketing" },
    { value: "3", label: "New Recommended" },
    { value: "4", label: "Recommended" },
    { value: "5", label: "Trending" },
  ];

  const filterOptions = [
    { value: "0", label: "None" },
    { value: "2", label: "Available" },
    { value: "15", label: "Hide from Welcome" },
    { value: "50", label: "Hide from Feed and Stream" },
    { value: "100", label: "Hide from Platform" },
  ];

  function hasWritePermission() {
    var result = false;

    if (currentUser.permissions) {
      const matchIndex = currentUser.permissions.findIndex(function (row) {
        return row === "experiences_write";
      });
      if (matchIndex > -1) {
        result = true;
      }
    } else {
      result = true;
    }

    return result;
  }

  function toggle(tab) {
    setActiveTab(tab);
  }

  function performAdd() {
    navigate.push("/content/experiences/add");
  }

  const tableActions1 = (cell, row) => {
    function deleteRow() {
      setSelectedRow(row);
      setShowDeleteModal(true);
    }

    function editRow() {
      performEdit(row.beat_id);
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={editRow}>Edit</DropdownItem>
          <DropdownItem onClick={deleteRow}>Delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const tableActions2 = (cell, row) => {
    function editRow() {
      performEdit(row.beat_id);
    }

    function activateRow() {
      setSelectedRow(row);
      setShowActivateModal(true);
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={editRow}>View</DropdownItem>
          <DropdownItem onClick={activateRow}>Re-activate</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const tableActions3 = (cell, row) => {
    function editRow() {
      performEdit(row.beat_id);
    }

    function undeleteRow() {
      setSelectedRow(row);
      setShowUndeleteModal(true);
    }

    return (
      <UncontrolledButtonDropdown
        key={row.user_id}
        className="mr-1 mb-1"
        direction="left"
      >
        <DropdownToggle caret color="primary">
          <FontAwesomeIcon icon={faBars} className="ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={editRow}>View</DropdownItem>
          <DropdownItem onClick={undeleteRow}>Un-delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const permissionDisplay = (cell, row) => {
    if (row.permission_type_id === 1) {
      if (row.anonymous_avatar_id === 0) {
        return <React.Fragment>Public</React.Fragment>;
      } else {
        return <React.Fragment>Anonymous</React.Fragment>;
      }
    } else {
      return <React.Fragment>Private</React.Fragment>;
    }
  };

  const badgeDisplay = (cell, row) => {
    var sortMatch = badgeOptions.find((r) => {
      return r.value === "" + row.badge_id;
    });

    return <React.Fragment>{sortMatch.label}</React.Fragment>;
  };

  const filterDisplay = (cell, row) => {
    var sortMatch = filterOptions.find((r) => {
      return r.value === "" + row.filter_id;
    });

    return <React.Fragment>{sortMatch.label}</React.Fragment>;
  };

  const authorDisplay = (cell, row) => {
    var userAvatar =
      row.creator_avatar_url === "" ? null : row.creator_avatar_url;
    var img = userAvatar ?? avatar;
    var nm =
      row.creator_display_name === ""
        ? row.creator_name
        : row.creator_display_name;

    return (
      <React.Fragment>
        <img
          src={img}
          className="img-fluid rounded-circle mr-1"
          style={{ width: 20, height: 20 }}
          alt={nm}
        />
        <Link to={"/members/edit/" + row.creator_id}>{nm}</Link>
        {row.creator_status_id === 2 && (
          <div className="small text-danger">
            <strong>Deleted</strong>
          </div>
        )}
        {row.creator_status_id === 3 && (
          <div className="small text-danger">
            <strong>Blocked</strong>
          </div>
        )}
      </React.Fragment>
    );
  };

  const dateDisplay = (cell, row) => {
    if (cell !== 0) {
      return <React.Fragment>{new Date(cell).toLocaleString()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const titleDisplay = (cell, row) => {
    return (
      <React.Fragment>
        <div>
          <strong>{row.name}</strong>
        </div>
        <div className="small">
          <strong>ID:</strong>&nbsp;{row.beat_id}
        </div>
      </React.Fragment>
    );
  };

  const tableColumns1 = [
    {
      dataField: "name",
      text: "Title",
      sort: true,
      formatter: titleDisplay,
      editable: false,
    },
    {
      dataField: "creator_name",
      text: "Owner",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "created_date",
      text: "Created",
      sort: true,
      formatter: dateDisplay,
      editable: false,
    },
    {
      dataField: "permission_type_id",
      text: "Privacy",
      sort: true,
      formatter: permissionDisplay,
      editable: false,
    },
    {
      dataField: "badge_id",
      text: "Badge",
      sort: true,
      editor: { type: Type.SELECT, options: badgeOptions },
      formatter: badgeDisplay,
    },
    {
      dataField: "filter_id",
      text: "Filter",
      sort: true,
      editor: { type: Type.SELECT, options: filterOptions },
      formatter: filterDisplay,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions1,
      headerStyle: () => {
        return { width: "70px" };
      },
      editable: false,
    },
  ];

  const tableColumns2 = [
    {
      dataField: "name",
      text: "Title",
      formatter: titleDisplay,
      sort: true,
    },
    {
      dataField: "creator_name",
      text: "Owner",
      sort: true,
      formatter: authorDisplay,
    },
    {
      dataField: "created_date",
      text: "Created",
      sort: true,
      formatter: dateDisplay,
    },
    {
      dataField: "permission_type_id",
      text: "Privacy",
      sort: true,
      formatter: permissionDisplay,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions2,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  const tableColumns3 = [
    {
      dataField: "name",
      text: "Title",
      formatter: titleDisplay,
      sort: true,
    },
    {
      dataField: "creator_name",
      text: "Owner",
      sort: true,
      formatter: authorDisplay,
      editable: false,
    },
    {
      dataField: "created_date",
      text: "Created",
      sort: true,
      formatter: dateDisplay,
    },
    {
      dataField: "permission_type_id",
      text: "Privacy",
      sort: true,
      formatter: permissionDisplay,
    },
    {
      isDummyField: true,
      dataField: "xxx",
      text: "",
      sort: false,
      formatter: tableActions3,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
  ];

  async function loadData1(filter, page, pageSize, sort, order) {
    setShowLoading1(true);
    setCurrentData1([]);
    setTotalRecords1(0);

    var info = await dataService.experienceList(
      1,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setShowLoading1(false);

    if (info) {
      if (info.record_count) {
        setTotalRecords1(info.record_count);
        setCurrentData1(info.list);
      }
    }
  }

  async function loadData2(filter, page, pageSize, sort, order) {
    setShowLoading2(true);
    setCurrentData2([]);

    var info = await dataService.experienceList(
      2,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setShowLoading2(false);

    if (info) {
      if (info.record_count) {
        setTotalRecords2(info.record_count);
        setCurrentData2(info.list);
      }
    }
  }

  async function loadData3(filter, page, pageSize, sort, order) {
    setShowLoading3(true);
    setCurrentData3([]);

    var info = await dataService.experienceList(
      3,
      filter,
      page,
      pageSize,
      sort,
      order
    );

    setShowLoading3(false);

    if (info) {
      if (info.record_count) {
        setTotalRecords3(info.record_count);
        setCurrentData3(info.list);
      }
    }
  }

  function performKey1(e) {
    if (e.charCode === 13) {
      loadData1(
        searchFilter1,
        currentPage1,
        sizePerPage1,
        sortField1,
        sortOrder1
      );
    }
  }

  function performKey2(e) {
    if (e.charCode === 13) {
      loadData2(
        searchFilter2,
        currentPage2,
        sizePerPage2,
        sortField2,
        sortOrder2
      );
    }
  }

  function performKey3(e) {
    if (e.charCode === 13) {
      loadData3(
        searchFilter3,
        currentPage3,
        sizePerPage3,
        sortField3,
        sortOrder3
      );
    }
  }

  function toggleDelete() {
    setShowDeleteModal(!showDeleteModal);
  }

  async function performDelete() {
    await dataService.deleteExperience(selectedRow.beat_id);
    setShowDeleteModal(false);
    refreshGrids();
  }

  function toggleActivate() {
    setShowActivateModal(!showActivateModal);
  }

  async function performActivate() {
    setShowActivateModal(false);

    // ok, undelete the row
    await dataService.activateExperience(selectedRow.beat_id);

    // refresh the tables
    await loadData1(
      searchFilter1,
      currentPage1,
      sizePerPage1,
      sortField1,
      sortOrder1
    );
    await loadData2(
      searchFilter2,
      currentPage2,
      sizePerPage2,
      sortField2,
      sortOrder2
    );
  }

  function toggleUndelete() {
    setShowUndeleteModal(!showUndeleteModal);
  }

  async function performUndelete() {
    setShowUndeleteModal(false);

    // ok, undelete the row
    await dataService.activateExperience(selectedRow.beat_id);

    // refresh the tables
    await loadData1(
      searchFilter1,
      currentPage1,
      sizePerPage1,
      sortField1,
      sortOrder1
    );
    await loadData3(
      searchFilter3,
      currentPage3,
      sizePerPage3,
      sortField3,
      sortOrder3
    );
  }

  function performEdit(beat_id) {
    navigate.push("/content/experiences/edit/" + beat_id);
  }

  function performSearch1() {
    loadData1(
      searchFilter1,
      currentPage1,
      sizePerPage1,
      sortField1,
      sortOrder1
    );
  }

  function performSearch2() {
    loadData2(
      searchFilter2,
      currentPage2,
      sizePerPage2,
      sortField2,
      sortOrder2
    );
  }

  function performSearch3() {
    loadData3(
      searchFilter3,
      currentPage3,
      sizePerPage3,
      sortField3,
      sortOrder3
    );
  }

  function pageChanged1(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage1) {
        // normally, we will need to make a call out
        setCurrentPage1(1);
        setSizePerPage1(newState.sizePerPage);
        loadData1(
          searchFilter1,
          1,
          newState.sizePerPage,
          sortField1,
          sortOrder1
        );
      } else if (newState.page !== currentPage1) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage1(newState.page);
        loadData1(
          searchFilter1,
          newState.page,
          sizePerPage1,
          sortField1,
          sortOrder1
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField1) {
        setSortField1(newState.sortField);
      } else if (newState.sortOrder !== sortOrder1) {
        setSortOrder1(newState.sortOrder);
      }
      setCurrentPage1(1);

      loadData1(
        searchFilter1,
        1,
        sizePerPage1,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  function pageChanged2(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage2) {
        // normally, we will need to make a call out
        setCurrentPage2(1);
        setSizePerPage2(newState.sizePerPage);
        loadData2(
          searchFilter2,
          1,
          newState.sizePerPage,
          sortField2,
          sortOrder2
        );
      } else if (newState.page !== currentPage2) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage2(newState.page);
        loadData2(
          searchFilter2,
          newState.page,
          sizePerPage2,
          sortField2,
          sortOrder2
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField2) {
        setSortField2(newState.sortField);
      } else if (newState.sortOrder !== sortOrder2) {
        setSortOrder2(newState.sortOrder);
      }
      setCurrentPage2(1);

      loadData2(
        searchFilter2,
        1,
        sizePerPage2,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  function pageChanged3(type, newState) {
    if (type === "pagination") {
      if (newState.sizePerPage !== sizePerPage3) {
        // normally, we will need to make a call out
        setCurrentPage3(1);
        setSizePerPage3(newState.sizePerPage);
        loadData3(
          searchFilter3,
          1,
          newState.sizePerPage,
          sortField3,
          sortOrder3
        );
      } else if (newState.page !== currentPage3) {
        // normally, we will need to make a call out to get the passed page of data
        setCurrentPage3(newState.page);
        loadData3(
          searchFilter3,
          newState.page,
          sizePerPage3,
          sortField3,
          sortOrder3
        );
      }
    } else if (type === "sort") {
      // normally, we will need to make a call out

      if (newState.sortField !== sortField3) {
        setSortField3(newState.sortField);
      } else if (newState.sortOrder !== sortOrder3) {
        setSortOrder3(newState.sortOrder);
      }
      setCurrentPage3(1);

      loadData3(
        searchFilter3,
        1,
        sizePerPage3,
        newState.sortField,
        newState.sortOrder
      );
    }
  }

  function refreshGrids() {
    setCurrentPage1(1);
    setCurrentPage2(1);
    setCurrentPage3(1);
    loadData1(
      searchFilter1,
      currentPage1,
      sizePerPage1,
      sortField1,
      sortOrder1
    );
    loadData2(
      searchFilter2,
      currentPage2,
      sizePerPage2,
      sortField2,
      sortOrder2
    );
    loadData3(
      searchFilter3,
      currentPage3,
      sizePerPage3,
      sortField3,
      sortOrder3
    );
  }

  async function saveCell(oldValue, newValue, row, column) {
    if (column.dataField === "badge_id") {
      await dataService.updateExperienceBadge(row.beat_id, newValue);
    } else if (column.dataField === "filter_id") {
      await dataService.updateExperienceFilter(row.beat_id, newValue);
    }
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          Experiences
          {hasWritePermission() && (
            <Button
              color="success"
              className="float-right"
              onClick={performAdd}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New
            </Button>
          )}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Experiences</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <Nav pills className="card-header-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Active Experiences
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Completed Experiences
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Deleted Experiences
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="mb-3">
                    <InputGroup>
                      <Input
                        placeholder="Search..."
                        value={searchFilter1}
                        onChange={(e) => setSearchFilter1(e.target.value)}
                        onKeyPress={(e) => performKey1(e)}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                      <InputGroupAddon addonType="append" color="primary">
                        <Button onClick={performSearch1}>
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    keyField="beat_id"
                    data={currentData1}
                    columns={tableColumns1}
                    sort={{ dataField: sortField1, order: sortOrder1 }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper"
                    classes="table_class"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: sizePerPage1,
                      sizePerPageList: [5, 10, 25, 50],
                      page: currentPage1,
                      totalSize: totalRecords1,
                    })}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      afterSaveCell: (oldValue, newValue, row, column) => {
                        saveCell(oldValue, newValue, row, column);
                      },
                    })}
                    onTableChange={pageChanged1}
                    noDataIndication={() =>
                      showLoading1 ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
                <TabPane tabId="2">
                  <div className="mb-3">
                    <InputGroup>
                      <Input
                        placeholder="Search..."
                        value={searchFilter2}
                        onChange={(e) => setSearchFilter2(e.target.value)}
                        onKeyPress={(e) => performKey2(e)}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                      <InputGroupAddon addonType="append" color="primary">
                        <Button onClick={performSearch2}>
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    keyField="beat_id"
                    data={currentData2}
                    columns={tableColumns2}
                    sort={{ dataField: sortField2, order: sortOrder2 }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper"
                    classes="table_class"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: sizePerPage2,
                      sizePerPageList: [5, 10, 25, 50],
                      page: currentPage2,
                      totalSize: totalRecords2,
                    })}
                    onTableChange={pageChanged2}
                    noDataIndication={() =>
                      showLoading2 ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
                <TabPane tabId="3">
                  <div className="mb-3">
                    <InputGroup>
                      <Input
                        placeholder="Search..."
                        value={searchFilter3}
                        onChange={(e) => setSearchFilter3(e.target.value)}
                        onKeyPress={(e) => performKey3(e)}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                      <InputGroupAddon addonType="append" color="primary">
                        <Button onClick={performSearch3}>
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    keyField="beat_id"
                    data={currentData3}
                    columns={tableColumns3}
                    sort={{ dataField: sortField3, order: sortOrder3 }}
                    bootstrap4
                    striped
                    wrapperClasses="table_wrapper"
                    classes="table_class"
                    remote={{ pagination: true, filter: true, sort: true }}
                    bordered={false}
                    pagination={paginationFactory({
                      sizePerPage: sizePerPage3,
                      sizePerPageList: [5, 10, 25, 50],
                      page: currentPage3,
                      totalSize: totalRecords3,
                    })}
                    onTableChange={pageChanged3}
                    noDataIndication={() =>
                      showLoading3 ? (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          style={{ height: 250 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span>There are no records</span>
                        </div>
                      )
                    }
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
          <Modal
            key={"delete"}
            isOpen={showDeleteModal}
            toggle={() => toggleDelete()}
          >
            <ModalHeader toggle={() => toggleDelete()}>
              Delete Experience{selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to delete this experience?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleDelete()}>
                Cancel
              </Button>{" "}
              <Button color="danger" onClick={() => performDelete()}>
                Deactivate
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            key={"activate"}
            isOpen={showActivateModal}
            toggle={() => toggleActivate()}
          >
            <ModalHeader toggle={() => toggleActivate()}>
              Re-activate Experience
              {selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to re-activate this experience?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleActivate()}>
                Cancel
              </Button>{" "}
              <Button color="warning" onClick={() => performActivate()}>
                Re-activate
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            key={"undelete"}
            isOpen={showUndeleteModal}
            toggle={() => toggleUndelete()}
          >
            <ModalHeader toggle={() => toggleUndelete()}>
              Un-delete Experience{selectedRow ? " - " + selectedRow.name : ""}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                Are you sure you want to un-delete this experience?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={() => toggleUndelete()}>
                Cancel
              </Button>{" "}
              <Button color="warning" onClick={() => performUndelete()}>
                Un-delete
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default ExperienceList;
